import axios from 'axios';
import {URL} from '../../../Utilities/Constants';
import {login, loginSuccess, loginFail, sendEmail, sendEmailSuccess, sendEmailFail} from './LoginAction';
import apis from '../../../apiCalls/apis';

import {
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGGED_IN_DETAILS,
    LOGGED_IN_DETAILS_SUCCESS,
    LOGGED_IN_DETAILS_FAIL,
} from './LoginConstants';
import { SELECT_CLIENT } from '../Client/constants';

/**
 * api call to login
 * @param {*} data page details
 */
export function userLogin(data) {
    return (dispatch) => {
        dispatch(login());
        axios({
            method: 'post',
            url: `${URL}/login`,
            data: data,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(loginSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(loginFail(data.data.errorData.errorReason));
                } else {
                    dispatch(loginFail('Something went wrong'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(loginFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(loginFail(err.message || 'Something went wrong'));
                }
            });
    };
}

export const userLoginV2 = (data) => async (dispatch) => {
    dispatch({type: LOGIN});
    try {
        const response = await apis.loginV2(data);
        if (response.status === 200) {
            dispatch({ type: LOGIN_SUCCESS, payload: response });
            let clientId = response.data.data.clientIds[0]
            if(!clientId ){
                clientId = Object.keys(response.data.data.permissions)[0]
            }
            dispatch( {
                type: SELECT_CLIENT,
                payload:clientId,
            })
        } else {
            dispatch({type: LOGIN_FAIL, payload: {msg: response?.data?.msg || 'Something went wrong'}});
        }
    } catch (e) {
        if (e.response) {
            dispatch({type: LOGIN_FAIL, payload: {msg: e?.response?.data?.msg || 'Something went wrong'}});
        } else {
            dispatch({type: LOGIN_FAIL, payload: {msg: e?.message || 'Unknown Error Occured'}});
        }
    }
};

export const loggedInDetails = (data, token,client) => async (dispatch) => {
    dispatch({ type: LOGGED_IN_DETAILS });
    try {
        const response = await apis.loggedInDetails(data, token,client);
        if (response.status === 200) {
            dispatch({type: LOGGED_IN_DETAILS_SUCCESS, payload: response.data});
        } else {
            dispatch({type: LOGGED_IN_DETAILS_FAIL, payload: {msg: response?.data?.msg || 'Something went wrong'}});
        }
    } catch (e) {
        if (e.response) {
            dispatch({type: LOGGED_IN_DETAILS_FAIL, payload: {msg: e?.response?.data?.msg || 'Something went wrong'}});
        } else {
            dispatch({type: LOGGED_IN_DETAILS_FAIL, payload: {msg: e?.message || 'Unknown Error Occured'}});
        }
    }
};

/**
 *api call to send Email
 * @param {string} email user email
 */
export function sendEmailRequest(email) {
    return (dispatch) => {
        dispatch(sendEmail());
        axios({
            method: 'post',
            url: `${URL}/adminUser/requestPasswordReset`,
            data: {email: email},
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(sendEmailSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(sendEmailFail(data.data.errorData.errorReason));
                } else {
                    dispatch(sendEmailFail('Something went wrong'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(sendEmailFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(sendEmailFail(err.message || 'Something went wrong'));
                }
            });
    };
}
