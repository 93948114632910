// import moment from 'moment';
import moment from 'moment-timezone';

//format time
export function timeFormat(time, format = 'hh:mm A [\n] DD-MM-YYYY') {
    const timezone = localStorage.getItem('timeZone');
    return moment(time).tz(timezone).format(format);
}

// export function timeFormat(time) {
//     return moment(time).format('hh:mm A [\n] DD-MM-YYYY');
// }

// time in miliseconds
export function dateInMilliseconds(date) {
    return moment(date).valueOf();
}

//check if value is a valid positive number (both decimal and integer)
export function isValidNumber(num) {
    return /^\d+(\.\d+)?$/.test(num);
}

//check if value is a valid number (pos/neg decimal/integer)
export function isValidNumberWithSign(num) {
    return /^-?\d+(\.\d{1,2})?$/.test(num);
}

//check if value is whole number
export function isNumber(num) {
    return /^\d+$/.test(num);
}

// check if value is string
export function isValidString(str) {
    return /^[ A-Za-z]+$/.test(str);
}

// check if value entered is time
export function isvalidTime(time) {
    return /^([0-5][0-9]):([0-5][0-9])?$/.test(time);
}

// email validation
export function isValidEmail(email) {
    return /^[a-zA-Z0-9._-]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(email);
}

// phone number validation
export function isPhoneNumber(num) {
    return /^\d{10}$/.test(num);
}

// phone number validation
export function isNumberOnly(num) {
    return /^[0-9]*$/.test(num);
}

// all capitals
export function isCapital(str) {
    return /[a-z]/.test(str);
}

// validation to check if special characters are present
export function hasSpecialChar(str) {
    return /[_\W0-9]/.test(str);
}

// cotains numbers and Capital letters
export function isAlphaNumeric(str) {
    return /^[A-Z0-9]+$/.test(str);
}

// cotains numbers and Capital letters
export function isNoSpaceStringOnStarting(str) {
    return /^(?!\s)[a-zA-Z0-9_\s-]*$/.test(str);
}

export function isValidURL(url) {
    try {
        let verifyURL = new URL(url);
        return true;
    } catch (_) {
        return false;
    }
}
