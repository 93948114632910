/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import '../../../Utilities/style.css';
import '../Login/login.css';
import {logout} from '../Login/LoginAction';
import {next, prev} from '../../../Utilities/HelperFunctions';
import {timeFormat} from '../../../Utilities/Helpers';
import TableLayout from '../../Layouts/TableLayout/TableLayout';
import Table from '../../../Components/Table/Table';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import FilterInput from '../../../Components/FilterInput';
import Button from '../../../Components/Button/Button';
import {NEXT, TICKET_CONTEST_STATUS, TICKET_STATUS, TICKET_STATUS_DATE_WISE} from '../../../Utilities/Constants';
import {usePrevious} from '../../../Utilities/hooks';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {styled} from '@material-ui/core';
import DateRangeSelect from '../../../Components/DateRangeSelect';
import {getTicketsList} from './AxioCalls';
import {debounce} from 'lodash';
import Dropdown from '../../../Components/Dropdown/Dropdown';
import moment from 'moment';

const Tickets = (props) => {
    const {history} = props;
    const dispatch = useDispatch();
    const login = useSelector((state) => state.login);
    const options = [
        {label: 'Date Wise', value: 'date-wise'},
        {label: 'Match Wise', value: 'match-wise'},
        {label: 'Ticket Wise', value: 'ticket-wise'},
    ];
    const tickets = useSelector((state) => state.tickets);
    const prevTickets = usePrevious(tickets);
    const data = tickets?.TicketsList?.data?.data || [];
    const [selectedOption, setSelectedOption] = useState('date-wise');
    const [addDelay, setAddDelay] = useState(false);
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);

    const tableData = useMemo(() => {
        if (data?.length > 0) {
            if (selectedOption !== 'ticket-wise') {
                const totals = data.reduce(
                    (acc, curr) => {
                        acc.TicketCount += curr?.TicketCount;
                        acc.MatchCount += curr?.MatchCount;
                        acc.PlayerCount += curr?.PlayerCount;
                        acc.BetAmount += curr?.BetAmount;
                        acc.WLAmount += curr?.WLAmount;
                        acc.Revenue += curr?.Revenue;
                        return acc;
                    },
                    {
                        TicketCount: 0,
                        MatchCount: 0,
                        PlayerCount: 0,
                        BetAmount: 0,
                        WLAmount: 0,
                        Revenue: 0,
                    },
                );

                totals.WLAmount = parseFloat(totals.WLAmount.toFixed(2));
                totals.Revenue = parseFloat(totals.Revenue.toFixed(2));
                // Appending totals row
                return [
                    ...data,
                    {
                        ...totals,
                        WorkingDate: 'Total', // Special marker for the totals row
                    },
                ];
            }
            return data;
        } else {
            return [];
        }
    }, [data, selectedOption]);

    const client = useSelector((state) => state.client);
    const {
        TicketsList: {data: {pageData = {}, data: ticketListData = []} = {}},
    } = tickets;

    let [state, setState] = useState({});
    const {errorMessage, loading} = state;
    const isLoading = loading || false;

    const initialPageOptions = {
        page: 1,
        pageSize: 15,
        viewType: selectedOption,
    };
    const [pageOptions, setPageOptions] = useState(initialPageOptions);
    const disableNext = pageOptions.page >= pageData.lastPage;
    useEffect(() => {
        if (!login?.userDetails?.data) {
            history.push('/');
            dispatch(logout());
        }
    }, []);

    useEffect(() => {
        if (tickets.TicketsListRequest && !prevTickets.TicketsListRequest) {
            handleStateChange({loading: true});
        } else if (tickets.TicketsListSuccess && !prevTickets.TicketsListSuccess) {
            handleStateChange({loading: false});
        } else if (tickets.TicketsListFail && !prevTickets.TicketsListFail) {
            const errMessage =
                tickets?.errorMessage && tickets?.errorMessage.includes('Validation Error:')
                    ? tickets?.errorMessage.split('Validation Error:')[1].trim()
                    : tickets?.errorMessage;
            handleStateChange({loading: false, errorMessage: errMessage});
        }
    }, [tickets]);
    const onNextORPrev = (action) => {
        const updatedFilters = action === NEXT ? next(pageOptions, pageData) : prev(pageOptions);
        if (updatedFilters) setPageOptions({...updatedFilters});
    };
    const onReset = () => {
        setPageOptions(initialPageOptions);
    };
    const handleFilter = (key, value, isImmediate) => {
        const selectedTimeZone = localStorage.getItem('timeZone');

        setAddDelay(true);
        if (key === 'Date') {
            let updatedStartDate, updatedEndDate;
            // if (selectedTimeZone === 'America/Nome') {
            //     updatedStartDate = moment(value.start).set({hour: 14, minute: 30, second: 0}).valueOf();
            //     updatedEndDate = moment.tz(value.end, 'America/Nome').set({hour: 23, minute: 59, second: 59}).valueOf();
            // } else if (selectedTimeZone === 'Asia/Bangkok') {
            //     updatedStartDate = moment
            //         .tz(value.start, 'Asia/Bangkok')
            //         .set({hour: 0, minute: 0, second: 0})
            //         .valueOf();
            //     updatedEndDate = moment
            //         .tz(value.end, 'Asia/Bangkok')
            //         .subtract(1, 'day')
            //         .set({hour: 23, minute: 59, second: 59})
            //         .valueOf(); //correct
            // } else {
            //     updatedStartDate = moment(value.start).valueOf();
            //     updatedEndDate = moment(value.end).set({hour: 23, minute: 59, second: 59}).valueOf();
            // }
            // setStart(updatedStartDate);
            // setEnd(updatedEndDate);
            setPageOptions({
                ...pageOptions,
                startDate: value.start,
                endDate: value.end,
                selectedTimeZone,
                page: 1,
            });
        } else {
            const updatedOptions = {...pageOptions, page: 1};
            if (value !== '') {
                updatedOptions[key] = value;
            } else {
                delete updatedOptions[key];
            }

            setPageOptions(updatedOptions);
        }
    };

    const debouncedApiCall = useCallback(
        debounce((updatedPageOptions) => {
            dispatch(getTicketsList(updatedPageOptions, login.userDetails.data.accessToken, client.selectedClient));
        }, 500),
        [dispatch, login, client],
    );

    useEffect(() => {
        const updatedPageOptions = {...pageOptions};

        if (
            updatedPageOptions.startDate !== undefined &&
            updatedPageOptions.endDate !== undefined &&
            selectedOption !== 'ticket-wise'
        ) {
            if (
                updatedPageOptions.startDate !== null &&
                updatedPageOptions.endDate !== null &&
                start !== null &&
                end !== null
            ) {
                updatedPageOptions.startDate = start;
                updatedPageOptions.endDate = end;
            }
        }
        if (addDelay) {
            debouncedApiCall(updatedPageOptions);
            setAddDelay(false);
        } else {
            dispatch(getTicketsList(updatedPageOptions, login.userDetails.data.accessToken, client.selectedClient));
        }
    }, [pageOptions]);

    const handleStateChange = (stateObj = {}) => {
        setState((prevState) => ({...prevState, ...stateObj}));
    };

    const handleOptionChange = (event, newOption) => {
        if (newOption !== null) {
            setSelectedOption(newOption);
            setPageOptions({...initialPageOptions, viewType: newOption, page: 1});
        }
    };

    const groupStyle = {
        display: 'flex',
        justifyContent: 'flex-start',
        gap: '10px',
    };

    const baseButtonStyle = {
        borderRadius: '50px',
        height: 'auto',
        width: '120px',
        padding: '10px 0',
        border: '2px solid #ffc107',
        fontSize: '14px',
        color: '#fff',
        backgroundColor: 'transparent',
        textTransform: 'none',
        fontWeight: '500',
        transition: 'all 0.3s ease',
    };

    const activeButtonStyle = {
        backgroundColor: '#ffc107',
        color: '#000',
    };

    const CustomToggleButton = styled(ToggleButton)(({theme}) => ({
        borderRadius: '50px !important',
        height: 'auto !important',
        padding: '10px 0',
        fontSize: '14px',
        fontWeight: '500',
        textTransform: 'none',
        transition: 'all 0.3s ease',
        border: '1px solid #ffc107 !important',
        backgroundColor: 'transparent !important',
        color: '#fff !important',
        '&.Mui-selected': {
            backgroundColor: '#ffc107 !important',
            color: '#000 !important',
            border: '1px solid #ffc107 !important',
        },
        // "&:hover": {
        //     backgroundColor: '#ffe57f !important',
        // },
    }));
    const renderStyledCell = (value, row, header) => {
        const isTotalRow = row?._original?.WorkingDate === 'Total';
        const style = isTotalRow ? {fontWeight: 'bold', color: 'gold'} : {};
        const className = header === 'Date' ? 'number date-space' : '';

        return (
            <span style={style} className={className}>
                {value || '-'}
            </span>
        );
    };

    const renderColumns = () => {
        const items = [];
        if (selectedOption === 'match-wise') {
            items.splice(
                0,
                0,
                {
                    Header: 'Date',
                    // width: 150,
                    accessor: 'WorkingDate',
                    Cell: (props) => {
                        const isDateValid = props?.value && !isNaN(new Date(props.value).getTime());
                        const selectedTimeZone = localStorage.getItem('timeZone');
                        const formattedDate = isDateValid
                            ? moment.tz(props.value, selectedTimeZone).format('DD-MM-YYYY') // Use sent timezone
                            : props.value === 'Total'
                              ? 'Total'
                              : '-';
                        return renderStyledCell(formattedDate, props.row, 'Date');
                    },
                },
                {
                    Header: 'Match ID',
                    accessor: 'MatchID',
                    width: 200,
                    Cell: (props) => renderStyledCell(props?.value, props.row),
                },
                {
                    Header: 'Match Name',
                    accessor: 'MatchName',
                    width: 300,
                    Cell: (props) => <span>{props?.value || '-'}</span>,
                },

                {
                    Header: 'Ticket Count',
                    accessor: 'TicketCount',
                    // width: 200,
                    Cell: (props) => renderStyledCell(props?.value, props.row),
                },
                {
                    Header: 'Player Count',
                    accessor: 'PlayerCount',
                    // width: 200,
                    Cell: (props) => renderStyledCell(props?.value, props.row),
                },
                {
                    Header: 'Participation Amount',
                    accessor: 'BetAmount',
                    // width: 150,
                    Cell: (props) => renderStyledCell(props?.value, props.row),
                },
                {
                    Header: 'Win Amount',
                    accessor: 'WLAmount',
                    // width: 150,
                    Cell: (props) => renderStyledCell(props?.value, props.row),
                },
                {
                    Header: 'Revenue',
                    accessor: 'Revenue',
                    width: 150,
                    Cell: (props) => renderStyledCell(props?.value, props.row),
                },
            );
        } else if (selectedOption === 'date-wise') {
            items.splice(
                0,
                0,
                {
                    Header: 'Date',
                    // width: 150,
                    accessor: 'WorkingDate',
                    Cell: (props) => {
                        const isDateValid = props?.value && !isNaN(new Date(props.value).getTime());
                        return renderStyledCell(
                            isDateValid
                                ? moment(props.value).format('DD-MM-YYYY')
                                : props.value === 'Total'
                                  ? 'Total'
                                  : '-',
                            props.row,
                            'Date',
                        );
                    },
                },
                {
                    Header: 'Ticket Count',
                    accessor: 'TicketCount',
                    // width: 200,
                    Cell: (props) => renderStyledCell(props?.value, props.row),
                },
                {
                    Header: 'Match Count',
                    accessor: 'MatchCount',
                    // width: 150,
                    Cell: (props) => renderStyledCell(props?.value, props.row),
                },
                {
                    Header: 'Player Count',
                    accessor: 'PlayerCount',
                    // width: 200,
                    Cell: (props) => renderStyledCell(props?.value, props.row),
                },
                {
                    Header: 'Participation Amount',
                    accessor: 'BetAmount',
                    // width: 150,
                    Cell: (props) => renderStyledCell(props?.value, props.row),
                },

                {
                    Header: 'Win Amount',
                    accessor: 'WLAmount',
                    // width: 150,
                    Cell: (props) => renderStyledCell(props?.value, props.row),
                },

                {
                    Header: 'Revenue',
                    accessor: 'Revenue',
                    width: 150,
                    Cell: (props) => renderStyledCell(props?.value, props.row),
                },
                // {
                //     Header: 'Settled Ticket',
                //     accessor: 'SettledCount',
                //     width: 150,
                //     Cell: (props) => renderStyledCell(props?.value, props.row),
                // },
                // {
                //     Header: 'Failed Ticket',
                //     accessor: 'FailedCount',
                //     width: 150,
                //     Cell: (props) => renderStyledCell(props?.value, props.row),
                // },
            );
        } else {
            items.splice(
                0,
                0,
                {
                    Header: 'Date',
                    accessor: 'createdAt',

                    width: 100,

                    Cell: (props) => (
                        <span className="number date-space">{props?.value ? timeFormat(props?.value) : '-'}</span>
                    ),
                },
                {
                    Header: 'Ticket ID',
                    accessor: '_id',
                    width: 200,
                    Cell: (props) => <span>{props?.value || '-'}</span>,
                },
                {
                    Header: 'User ID',
                    accessor: 'userId',
                    width: 200,
                    Cell: (props) => <span>{props?.value || '-'}</span>,
                },
                {
                    Header: 'Client UserName',
                    accessor: 'merchantUserId',
                    width: 150,
                    Cell: (props) => <span>{props?.value || '-'}</span>,
                },
                {
                    Header: 'Contest ID',
                    accessor: 'contestId',
                    width: 200,
                    Cell: (props) => <span>{props?.value || '-'}</span>,
                },
                {
                    Header: 'Participation OrderId',
                    accessor: 'participationOrderRefId',
                    width: 200,
                    Cell: (props) => <span>{props?.value || '-'}</span>,
                },
                {
                    Header: 'Participation TxnId',
                    accessor: 'participationTxnRefId',
                    width: 200,
                    Cell: (props) => <span>{props?.value || '-'}</span>,
                },
                {
                    Header: 'Refund OrderId',
                    accessor: 'refundOrderRefId',
                    width: 200,
                    Cell: (props) => <span>{props?.value || '-'}</span>,
                },
                {
                    Header: 'Refund TxnId',
                    accessor: 'refundTxnRefId',
                    width: 200,
                    Cell: (props) => <span>{props?.value || '-'}</span>,
                },
                {
                    Header: 'Winning OrderId',
                    accessor: 'winningOrderRefId',
                    width: 200,
                    Cell: (props) => <span>{props?.value || '-'}</span>,
                },
                {
                    Header: 'Winning TxnId',
                    accessor: 'winningTxnRefId',
                    width: 200,
                    Cell: (props) => <span>{props?.value || '-'}</span>,
                },
                {
                    Header: 'Team Id',
                    accessor: 'teamId',
                    width: 200,
                    Cell: (props) => <span>{props?.value || '-'}</span>,
                },
                {
                    Header: 'Currency',
                    accessor: 'currencyCode',
                    // width: 100,
                    Cell: (props) => <span>{props?.value || '-'}</span>,
                },
                {
                    Header: 'Participation time',
                    accessor: 'participationTime',
                    // width: 200,
                    Cell: (props) => (
                        <span className="number date-space">{props?.value ? timeFormat(props?.value) : '-'}</span>
                    ),
                },
                {
                    Header: 'Contest Status',
                    accessor: 'contestStatus',
                    width: 150,
                    Cell: (props) => <span>{props?.value || '-'}</span>,
                },
                {
                    Header: 'Match Id',
                    accessor: 'matchId',
                    width: 200,
                    Cell: (props) => <span>{props?.value || '-'}</span>,
                },
                {
                    Header: 'Match Name',
                    accessor: 'matchName',
                    width: 300,
                    Cell: (props) => <span>{props?.value || '-'}</span>,
                },
                {
                    Header: 'Match Time',
                    accessor: 'matchTime',
                    width: 100,
                    Cell: (props) => (
                        <span className="number date-space">{props?.value ? timeFormat(props?.value) : '-'}</span>
                    ),
                },
                {
                    Header: 'Ticket Status',
                    accessor: 'ticketStatus',
                    // width: 100,
                    Cell: (props) => <span>{props?.value || '-'}</span>,
                },
                {
                    Header: 'Participation Amount',
                    accessor: 'participationAmount',
                    // width: 150,
                    Cell: (props) => <span>{props?.value || '-'}</span>,
                },
                {
                    Header: 'Win Amount',
                    accessor: 'winAmount',
                    // width: 150,
                    Cell: (props) => <span>{props?.value || '-'}</span>,
                },
                {
                    Header: 'Refund Amount',
                    accessor: 'refundAmount',
                    // width: 150,
                    Cell: (props) => <span>{props?.value || '-'}</span>,
                },
            );
        }
        return items;
    };

    const onDatewiseTicketStatusChange = (value) => {
        if (value === 'ALL') {
            handleFilter('ticketStatus', '', true);
        } else {
            handleFilter('ticketStatus', value, true);
        }
    };

    return (
        <TableLayout location="tickets" history={history} hideSearchBar>
            {!!isLoading && <LoadingModal open={!!isLoading} />}
            {!!errorMessage && (
                <Snackbar
                    open={!!errorMessage}
                    message={errorMessage}
                    onClose={() => handleStateChange({errorMessage: ''})}
                />
            )}
            <div className="my-10">
                <ToggleButtonGroup
                    value={selectedOption}
                    exclusive
                    onChange={handleOptionChange}
                    aria-label="Options"
                    style={groupStyle}
                >
                    {options?.map((option) => (
                        <CustomToggleButton
                            key={option.value}
                            value={option.value}
                            aria-label={option.value}
                            selected={selectedOption === option.value}
                            style={{
                                ...baseButtonStyle,
                                ...(selectedOption === option.value ? activeButtonStyle : {}),
                            }}
                        >
                            {option.label}
                        </CustomToggleButton>
                    ))}
                </ToggleButtonGroup>
            </div>
            <div className="filter-container my-10">
                {selectedOption === 'ticket-wise' && (
                    <FilterInput
                        placeholder="Ticket Id"
                        value={pageOptions.ticketId || ''}
                        onChange={(value) => handleFilter('ticketId', value, false)}
                    />
                )}
                {selectedOption === 'ticket-wise' && (
                    <FilterInput
                        placeholder="User Id"
                        value={pageOptions.userId || ''}
                        onChange={(value) => handleFilter('userId', value, false)}
                    />
                )}
                {selectedOption === 'ticket-wise' && (
                    <Dropdown
                        label="Contest Status"
                        menuItems={TICKET_CONTEST_STATUS}
                        value={pageOptions?.contestStatus || ''}
                        placeholder="Transaction Type"
                        onChange={(value) => handleFilter('contestStatus', value, true)}
                    />
                )}
                {selectedOption === 'ticket-wise' && (
                    <Dropdown
                        label="Ticket Status"
                        menuItems={TICKET_STATUS}
                        value={pageOptions?.ticketStatus || ''}
                        placeholder="Transaction Type"
                        onChange={(value) => handleFilter('ticketStatus', value, true)}
                    />
                )}
                {selectedOption === 'date-wise' && (
                    <Dropdown
                        label="Ticket Status"
                        menuItems={TICKET_STATUS_DATE_WISE}
                        value={pageOptions?.ticketStatus || 'ALL'}
                        placeholder="Transaction Type"
                        onChange={onDatewiseTicketStatusChange}
                    />
                )}
                {selectedOption === 'ticket-wise' && (
                    <FilterInput
                        placeholder="User Name"
                        value={pageOptions?.username}
                        onChange={(value) => handleFilter('username', value, false)}
                    />
                )}
                {selectedOption === 'ticket-wise' && (
                    <FilterInput
                        placeholder="Contest Id"
                        value={pageOptions?.contestId}
                        onChange={(value) => handleFilter('contestId', value, false)}
                    />
                )}
                {selectedOption === 'ticket-wise' && (
                    <FilterInput
                        placeholder="Transaction/Order Id"
                        value={pageOptions?.trackerId}
                        onChange={(value) => handleFilter('trackerId', value, false)}
                    />
                )}
                {selectedOption !== 'date-wise' && (
                    <FilterInput
                        placeholder="Match Id"
                        value={pageOptions?.matchId}
                        onChange={(value) => handleFilter('matchId', value, false)}
                    />
                )}
                {selectedOption === 'ticket-wise' && (
                    <FilterInput
                        placeholder="Team Id"
                        value={pageOptions?.teamId}
                        onChange={(value) => handleFilter('teamId', value, false)}
                    />
                )}
                <DateRangeSelect
                    label="Date Range"
                    startDate={pageOptions.startDate}
                    endDate={pageOptions.endDate}
                    isClearable={true}
                    onChange={(value) => handleFilter('Date', value, false)}
                />

                <Button buttonText="Reset" onClick={onReset} />
            </div>

            <Table
                data={tableData}
                columns={renderColumns()}
                page={pageOptions.page}
                onPrevClick={onNextORPrev}
                onNextClick={() => onNextORPrev(NEXT)}
                disableNext={disableNext}
            ></Table>
        </TableLayout>
    );
};

export default React.memo(Tickets);
