import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import MuiSelectField from '../../Components/MuiSelectField/MuiSelectField';
import {selectClient} from '../../View/Modules/Client/action';
import {logout} from '../../View/Modules/Login/LoginAction';
import {
    AccountCircle,
    ArrowDropDown,
    Assignment,
    CalendarToday,
    Gamepad,
    PeopleAlt,
    SportsEsports,
} from '@material-ui/icons';
import classNames from 'classnames';
import styles from './PrimaryNavbar.module.scss';
import {TIME_ZONE_OPTIONS} from '../../Utilities/Constants';

const PrimaryNavbar = (props) => {
    const {history, onClickUploadImage} = props;
    const dispatch = useDispatch();
    const login = useSelector((state) => state.login);
    const clientId = useSelector((state) => state.client.selectedClient);
    const [isDropdownVisible, setIsDropdownVisible] = useState({
        users: false,
        fantasy: false,
        tournament: false,
        calender: false,
        others: false,
        admin: false,
    });

    const {
        superAdmin,
        admin,
        userReadOnly,
        userKycReadOnly,
        withdrawalReadOnly,
        referralReadOnly,
        discountReadOnly,
        notificationWrite,
        tdsReadOnly,
        rewardUserPermission,
        bannerReadOnly,
        verifyMatchReadOnly,
        playerReadOnly,
        squadReadOnly,
        matchReadOnly,
        contestReadOnly,
        teamReadOnly,
        fantasyScoringReadOnly,
        boosterReadOnly,
        articleWrite,
        dynamicPrizePoolReadOnly,
        toGameReadOnly,
        toMatchModeReadOnly,
        navbarCalenderDropdown,
        otherUploadImageReadOnly,
        switchConfigReadOnly,
        commonMatchesReadOnly,
        otherSkeletonReadOnly,
        upcomingMatches,
        userTicket
    } = login?.permissions;

    const userDropdownVisible =
        userReadOnly ||
        userKycReadOnly ||
        withdrawalReadOnly ||
        referralReadOnly ||
        discountReadOnly ||
        notificationWrite ||
        tdsReadOnly ||
        rewardUserPermission ||
        bannerReadOnly ||
        userTicket;

    const tournamentDropdownVisible = toGameReadOnly || toMatchModeReadOnly;
    const handleLogout = () => {
        dispatch(logout());
        history.push('/');
    };

    const handleChangeClient = (event) => {
        dispatch(selectClient(event.target.value));
        setTimeout(() => {
            window.location.reload();
        }, 0);
    };
    const handleChangeTimeZone = (value) => {
        localStorage.setItem('timeZone', value);
        window.location.reload();
    };
    useEffect(() => {
        if (localStorage.getItem('timeZone') === null) {
            localStorage.setItem('timeZone', login?.userDetails?.data?.timezone);
            setTimeout(() => {
                window.location.reload();
            }, 0);
        } else {
        }
    }, [login]);

    let clientIdOptions = []

    if((login?.userDetails?.data?.clientIds||[]).length>0){
        clientIdOptions = login?.userDetails?.data?.clientIds
    }else{  
        clientIdOptions = Object.keys(login?.userDetails?.data?.permissions) || []
    }

    return (
        <header
            className={classNames(
                styles.header,
                clientId === 'client_fanclash'
                    ? styles.fcHeader
                    : clientId === 'client_jade'
                      ? styles.jadeHeader
                      : clientId === 'client_unicorn'
                        ? styles.unicornHeader
                        : clientId === 'client_pie'
                          ? styles.pieHeader
                          : null,
            )}
        >
            {/* navbar */}
            <nav className={styles.navbar}>
                <div className={styles.clientWrapper}>
                    {clientId === 'client_fanclash' && (
                        <div role="link" className={styles.fcLogo}>
                            <img src="/assets/images/fanclash-cc-logo.png" alt="fanclash cc logo" />
                        </div>
                    )}
                    {clientId === 'client_jade' && (
                        <div role="link" className={styles.jadeLogo}>
                            <img src="/assets/images/jade-cc-logo.png" alt="jade cc logo" />
                        </div>
                    )}
                    {clientId === 'client_unicorn' && (
                        <div role="link" className={styles.unicornLogo}>
                            <img src="/assets/images/unicorn-cc-logo.png" alt="unicorn cc logo" />
                        </div>
                    )}
                    {clientId === 'client_pie' && (
                        <div role="link" className={styles.unicornLogo}>
                            <img src="/assets/images/unicorn-cc-logo.png" alt="unicorn cc logo" />
                        </div>
                    )}
                    {login?.userDetails?.data?.email !== 'demo@fanclash.in' && (
                        <MuiSelectField
                            className={styles.clientSelectField}
                            label="Client"
                            name="clientId"
                            onChange={(event) => handleChangeClient(event)}
                            options={clientIdOptions.map((client) => {
                                const [prefix, name] = client.split('_');
                                const capitalized = name.charAt(0).toUpperCase() + name.slice(1);

                                return {
                                    value: client,
                                    text: capitalized,
                                };
                            })}
                            value={clientId}
                            size="small"
                        />
                    )}
                    <MuiSelectField
                        className={styles.clientSelectField}
                        label="Time Zone"
                        name="TimeZone"
                        onChange={(event) => handleChangeTimeZone(event.target.value)}
                        options={TIME_ZONE_OPTIONS}
                        value={localStorage.getItem('timeZone')}
                        size="small"
                    />
                </div>
                <ul className={styles.navLinks}>
                    {!!userDropdownVisible && (
                        <li className={styles.dropdownWrapper}>
                            <button
                                className={styles.dropdownButton}
                                onClick={() => setIsDropdownVisible({users: !isDropdownVisible.users})}
                            >
                                <PeopleAlt className={styles.startButtonIcon} />
                                <p>Users</p>
                                <ArrowDropDown className={styles.endButtonIcon} />
                            </button>
                            <div
                                className={classNames(
                                    styles.dropdownContent,
                                    !!isDropdownVisible.users ? styles.dropdownContentActive : null,
                                )}
                            >
                                {userReadOnly && (
                                    <div
                                        role="button"
                                        className={styles.contentItem}
                                        onClick={() => history.push('/users')}
                                    >
                                        User List
                                    </div>
                                )}
                                {userKycReadOnly && (
                                    <div
                                        role="button"
                                        className={styles.contentItem}
                                        onClick={() => history.push('/user-kyc')}
                                    >
                                        User KYC
                                    </div>
                                )}
                                 {userTicket && (
                                    <div
                                        role="button"
                                        className={styles.contentItem}
                                        onClick={() => history.push('/tickets')}
                                    >
                                        Tickets
                                    </div>
                                )}
                                {withdrawalReadOnly && (
                                    <div
                                        role="button"
                                        className={styles.contentItem}
                                        onClick={() => history.push('/withdrawals')}
                                    >
                                        Withdrawals
                                    </div>
                                )}
                                {referralReadOnly && (
                                    <div
                                        role="button"
                                        className={styles.contentItem}
                                        onClick={() => history.push('/referralCodes')}
                                    >
                                        Referral Codes
                                    </div>
                                )}
                                {discountReadOnly && (
                                    <div
                                        role="button"
                                        className={styles.contentItem}
                                        onClick={() => history.push('/discountCodes')}
                                    >
                                        Discount Codes
                                    </div>
                                )}
                                {notificationWrite && (
                                    <div
                                        role="button"
                                        className={styles.contentItem}
                                        onClick={() => history.push('/sendNotification')}
                                    >
                                        Notifications
                                    </div>
                                )}
                                {tdsReadOnly && (
                                    <div
                                        role="button"
                                        className={styles.contentItem}
                                        onClick={() => history.push('/tds-certificates')}
                                    >
                                        TDS Certificates
                                    </div>
                                )}
                                {rewardUserPermission && (
                                    <div
                                        role="button"
                                        className={styles.contentItem}
                                        onClick={() => history.push('/reward-user')}
                                    >
                                        Reward User
                                    </div>
                                )}
                                {bannerReadOnly && (
                                    <div
                                        role="button"
                                        className={styles.contentItem}
                                        onClick={() => history.push('/banners')}
                                    >
                                        Banner
                                    </div>
                                )}
                            </div>
                        </li>
                    )}
                    <li className={styles.dropdownWrapper}>
                        <button
                            className={styles.dropdownButton}
                            onClick={() => setIsDropdownVisible({fantasy: !isDropdownVisible.fantasy})}
                        >
                            <Gamepad className={styles.startButtonIcon} />
                            <p>Fantasy</p>
                            <ArrowDropDown className={styles.endButtonIcon} />
                        </button>
                        <div
                            className={classNames(
                                styles.dropdownContent,
                                !!isDropdownVisible.fantasy ? styles.dropdownContentActive : null,
                            )}
                        >
                            {commonMatchesReadOnly && (
                                <div
                                    role="button"
                                    className={styles.contentItem}
                                    onClick={() => history.push('/matches/common')}
                                >
                                    Common Matches
                                </div>
                            )}
                            {matchReadOnly && (
                                <div
                                    role="button"
                                    className={styles.contentItem}
                                    onClick={() => history.push('/matches')}
                                >
                                    Matches
                                </div>
                            )}
                            {upcomingMatches && (
                                <div
                                    role="button"
                                    className={styles.contentItem}
                                    onClick={() => history.push('/matches/upcoming')}
                                >
                                    Upcoming Matches
                                </div>
                            )}
                            {switchConfigReadOnly && (
                                <div
                                    role="button"
                                    className={styles.contentItem}
                                    onClick={() => history.push('/switch-configurations')}
                                >
                                    Switch Config
                                </div>
                            )}
                            {verifyMatchReadOnly && (
                                <div
                                    role="button"
                                    className={styles.contentItem}
                                    onClick={() => history.push('/verifyMatch')}
                                >
                                    Verify Match
                                </div>
                            )}
                            {contestReadOnly && (
                                <div
                                    role="button"
                                    className={styles.contentItem}
                                    onClick={() => history.push('/contests')}
                                >
                                    Contests
                                </div>
                            )}
                            {contestReadOnly && (
                                <div
                                    role="button"
                                    className={styles.contentItem}
                                    onClick={() => history.push('/contestTypes')}
                                >
                                    Contest Types
                                </div>
                            )}
                            {teamReadOnly && (
                                <div
                                    role="button"
                                    className={styles.contentItem}
                                    onClick={() => history.push('/teams')}
                                >
                                    Teams
                                </div>
                            )}
                            {squadReadOnly && (
                                <div
                                    role="button"
                                    className={styles.contentItem}
                                    onClick={() => history.push('/squad')}
                                >
                                    Squads
                                </div>
                            )}
                            {playerReadOnly && (
                                <div
                                    role="button"
                                    className={styles.contentItem}
                                    onClick={() => history.push('/players')}
                                >
                                    Players
                                </div>
                            )}
                            {dynamicPrizePoolReadOnly && (
                                <div
                                    role="button"
                                    className={styles.contentItem}
                                    onClick={() => history.push('/dynamic-prize-pool')}
                                >
                                    Dynamic Prize Pool
                                </div>
                            )}
                            {boosterReadOnly && (
                                <div
                                    role="button"
                                    className={styles.contentItem}
                                    onClick={() => history.push('/booster/configs')}
                                >
                                    Booster Config
                                </div>
                            )}
                            {fantasyScoringReadOnly && (
                                <div
                                    role="button"
                                    className={styles.contentItem}
                                    onClick={() => history.push('/fantasyScoringModels')}
                                >
                                    Fantasy Scoring
                                </div>
                            )}
                        </div>
                    </li>
                    {!!tournamentDropdownVisible && (
                        <li className={styles.dropdownWrapper}>
                            <button
                                className={styles.dropdownButton}
                                onClick={() => setIsDropdownVisible({tournament: !isDropdownVisible.tournament})}
                            >
                                <SportsEsports className={styles.startButtonIcon} />
                                <p>Tournament</p>
                                <ArrowDropDown className={styles.endButtonIcon} />
                            </button>
                            <div
                                className={classNames(
                                    styles.dropdownContent,
                                    !!isDropdownVisible.tournament ? styles.dropdownContentActive : null,
                                )}
                            >
                                <div
                                    role="button"
                                    className={styles.contentItem}
                                    onClick={() => history.push('/toGames')}
                                >
                                    Games
                                </div>
                                <div
                                    role="button"
                                    className={styles.contentItem}
                                    onClick={() => history.push('/toMatchModes')}
                                >
                                    Match Mode
                                </div>
                                <div
                                    role="button"
                                    className={styles.contentItem}
                                    onClick={() => history.push('/toMatchAttribute')}
                                >
                                    Match Attribute
                                </div>
                            </div>
                        </li>
                    )}
                    {!!navbarCalenderDropdown && (
                        <li className={styles.dropdownWrapper}>
                            <button
                                className={styles.dropdownButton}
                                onClick={() => setIsDropdownVisible({calender: !isDropdownVisible.calender})}
                            >
                                <CalendarToday className={styles.startButtonIcon} />
                                <p>Calender</p>
                                <ArrowDropDown className={styles.endButtonIcon} />
                            </button>
                            <div
                                className={classNames(
                                    styles.dropdownContent,
                                    !!isDropdownVisible.calender ? styles.dropdownContentActive : null,
                                )}
                            >
                                <div
                                    role="button"
                                    className={styles.contentItem}
                                    onClick={() => history.push('/calendar/tournament')}
                                >
                                    Tournament
                                </div>
                                <div
                                    role="button"
                                    className={styles.contentItem}
                                    onClick={() => history.push('/calendar/series')}
                                >
                                    Series
                                </div>
                            </div>
                        </li>
                    )}
                    {(otherUploadImageReadOnly || articleWrite || otherSkeletonReadOnly) && (
                        <li className={styles.dropdownWrapper}>
                            <button
                                className={styles.dropdownButton}
                                onClick={() => setIsDropdownVisible({others: !isDropdownVisible.others})}
                            >
                                <Assignment className={styles.startButtonIcon} />
                                <p>Others</p>
                                <ArrowDropDown className={styles.endButtonIcon} />
                            </button>
                            <div
                                className={classNames(
                                    styles.dropdownContent,
                                    !!isDropdownVisible.others ? styles.dropdownContentActive : null,
                                )}
                            >
                                {otherUploadImageReadOnly && (
                                    <div role="button" className={styles.contentItem} onClick={onClickUploadImage}>
                                        Upload Image
                                    </div>
                                )}
                                {articleWrite && (
                                    <div
                                        role="button"
                                        className={styles.contentItem}
                                        onClick={() => history.push('/inshorts')}
                                    >
                                        Articles
                                    </div>
                                )}
                                {otherSkeletonReadOnly && (
                                    <div
                                        role="button"
                                        className={styles.contentItem}
                                        onClick={() => history.push('/skeleton')}
                                    >
                                        Skeleton
                                    </div>
                                )}
                            </div>
                        </li>
                    )}
                    <li className={styles.dropdownWrapper}>
                        <button
                            className={styles.dropdownButton}
                            onClick={() => setIsDropdownVisible({admin: !isDropdownVisible.admin})}
                        >
                            <AccountCircle className={styles.startButtonIcon} />
                            <p>{`${login?.userDetails?.data?.fullName || 'User'}`}</p>
                            <ArrowDropDown className={styles.endButtonIcon} />
                        </button>
                        <div
                            className={classNames(
                                styles.dropdownContent,
                                !!isDropdownVisible.admin ? styles.dropdownContentActive : null,
                            )}
                        >
                            {(superAdmin || admin) && (
                                <div
                                    role="button"
                                    className={styles.contentItem}
                                    onClick={() => history.push('/admins')}
                                >
                                    Manage Access
                                </div>
                            )}
                            <div role="button" className={styles.contentItem} onClick={handleLogout}>
                                Logout
                            </div>
                        </div>
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export default PrimaryNavbar;
