import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import {connect} from 'react-redux';
import Tabs from '../../../Components/Tabs/Tabs';
import Modal from '../../../Components/Modal/Modal';
import Card from '../../../Components/Card/Card';
import Close from '../../../Images/close_yellow.png';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Input from '../../../Components/Input/Input';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import Button from '../../../Components/Button/Button';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Lock from '../../../Images/locked.png';
import {getContestType, getContestTypeList} from '../ContestType/AxioCalls';
import AutoCompleteField from '../../../Components/AutoCompleteField/AutoCompleteField';
import {createContest, updateContest, deleteContest, getContest} from './AxioCalls';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import {getMatch} from '../Matches/AxioCalls';
import {editMatch} from '../Matches/action';
import DropDown from '../../../Components/Dropdown/Dropdown';
import DropDownMulti from '../../../Components/Dropdown/DropdownMulti';
import {timeFormat} from '../../../Utilities/Helpers';
import {isNumber, isValidNumber} from '../../../Utilities/Helpers';
import {getAppUserList} from '../Users/AxioCalls';
import {
    APP_USER_TYPE,
    USER_TYPES_OPTIONS,
    PRIZE_POOL_TYPE,
    PRIZE_POOL_TYPE_OPTIONS,
    COMMON_STATUS_CONST,
    MAX_SUBSTITUION_ALLOWED_OPTIONS,
    SUBSTITUTION_FEE_OPTIONS,
    SUBSTITUTION_FEE_TYPES_OPTIONS,
} from '../../../Utilities/Constants';
import ConfirmationModal2 from '../../../Components/ConfirmationModal';
import {getDynamicPrizePools} from '../DynamicPrizePool/action';
import {debounce} from '../../../Utilities/HelperFunctions';
import MuiAutocompleteField from '../../../Components/MuiAutocompleteField/MuiAutoCompleteField';

class AddContest extends React.Component {
    constructor(props) {
        super(props);
        let location = this.props.history.location.pathname.includes('edit');
        this.state = {
            participantsOptions: [
                {value: true, text: 'Yes'},
                {value: false, text: 'No'},
            ],
            tagsOptions: [
                {label: 'Novice', value: 'novice'},
                {label: 'Practice', value: 'practice'},
                {label: 'Challenger', value: 'challenger'},
            ],
            tags: [],
            menuItems: [],
            disabled: false,
            status: null,
            squadName: null,
            edit: location,
            getData: false,
            contestType: null,
            pageSize: 10,
            page: 1,
            open: false,
            ctName: null,
            loading: false,
            fcDiscount: null,
            cashType: null,
            entryFees: null,
            teamSlots: null,
            numberOfContestsConfig: 1,
            minParticipants: null,
            minNoTeam: null,
            maxTeams: null,
            bigWins: null,
            noOfSmallWinBuckets: null,
            smallWinBuckets: {},
            rules: null,
            handlingCharge: null,
            contestTypeId: null,
            ranks: null,
            valueType: null,
            expSlotFillPercent: null,
            minPoolAmount: null, //min prize pool for % based winnings
            matchId: this.props.match.contestMatch,
            teams: [],
            users: [],
            winners: [],
            matchStatus: false,
            fcOption: [
                {value: 'COINPERCENT', text: 'Coin Percent'},
                {value: 'COINVALUE', text: 'Coin Value'},
            ],
            cashValueOption: [
                {value: 'CASHPERCENT', text: 'Cash Percent'},
                {value: 'CASHVALUE', text: 'Cash Value'},
                {value: 'IGCVALUE', text: 'IGC Value'},
            ],
            freeOptions: [{value: 'COINVALUE', text: 'Coin Value'}],
        };
    }

    /**
     * function to save entered data in state
     * @param {string} field field names from the form
     * @param {*} value value in the field
     */
    handleInputChange(field, value) {
        const {menuItems} = this.state;
        if (field.includes('rank')) {
            let ranks = this.state.ranks;
            ranks[field] = value;
            this.setState({
                ranks: ranks,
                errorMessage: '',
                [field + 'Error']: false,
            });
        }

        if (field.includes('tags')) {
            let mewTags = value && value.length ? value.map((t) => t.value) : [];
            this.setState({
                tags: mewTags,
            });
            return;
        }

        if (field.includes('bucketAmount') || field.includes('numOfSlots')) {
            let smallWinBuckets = this.state.smallWinBuckets;
            let bucketNum = field.split('_')[1];
            let key = 'bucket' + bucketNum;
            if (!smallWinBuckets) {
                smallWinBuckets = {};
            }
            if (!smallWinBuckets[key]) {
                smallWinBuckets[key] = {};
            }
            if (field.includes('bucketAmount')) smallWinBuckets[key].bucketAmount = value;
            else if (field.includes('numOfSlots')) {
                smallWinBuckets[key].numOfSlots = value;
            } else {
                this.setState({
                    errorMessage: 'Unknown field ' + field,
                    [field + 'Error']: true,
                });
                return;
            }
            this.setState({
                smallWinBuckets: smallWinBuckets,
                errorMessage: '',
                [field + 'Error']: false,
            });
        }

        if (field.includes('contest')) {
            if (value !== null) {
                this.setState({[field]: value, errorMessage: ''});
            } else {
                let value = this.state[field];
                let contests = menuItems;
                let index = contests.findIndex((x) => x.value === value.value);
                if (index === -1) {
                    contests.push({title: value.title, value: value});
                }
                this.setState({[field]: null, menuItems: contests, getData: false, errorMessage: ''});
            }
        }

        if (field.includes('valueType')) {
            if (value === 'CASHPERCENT' || value === 'COINPERCENT') {
                this.setState({
                    [field]: value,
                    errorMessage: '',
                    [field + 'Error']: false,
                    minPoolAmount: this.getMinPoolAmount(
                        this.state.expSlotFillPercent,
                        this.state.entryFees,
                        this.state.teamSlots,
                    ),
                });
            } else {
                this.setState({
                    [field]: value,
                    errorMessage: '',
                    [field + 'Error']: false,
                    minPoolAmount: null,
                });
            }
        } else if (
            field.includes('teamSlots') &&
            (this.state.valueType === 'CASHPERCENT' || this.state.valueType === 'COINPERCENT')
        ) {
            this.setState({
                [field]: value,
                errorMessage: '',
                [field + 'Error']: false,
                minPoolAmount: this.getMinPoolAmount(this.state.expSlotFillPercent, this.state.entryFees, value),
            });
        } else if (
            field.includes('expSlotFillPercent') &&
            (this.state.valueType === 'CASHPERCENT' || this.state.valueType === 'COINPERCENT')
        ) {
            this.setState({
                [field]: value,
                errorMessage: '',
                [field + 'Error']: false,
                minPoolAmount: this.getMinPoolAmount(value, this.state.entryFees, this.state.teamSlots),
            });
        } else if (
            field.includes('entryFees') &&
            (this.state.valueType === 'CASHPERCENT' || this.state.valueType === 'COINPERCENT')
        ) {
            this.setState({
                [field]: value,
                errorMessage: '',
                [field + 'Error']: false,
                minPoolAmount: this.getMinPoolAmount(this.state.expSlotFillPercent, value, this.state.teamSlots),
            });
        } else {
            this.setState({
                [field]: value,
                errorMessage: '',
                ctError: false,
                fcDiscountError: false,
                entryFeesError: false,
                teamSlotsError: false,
                minNoTeamError: false,
                maxTeamsError: false,
                bigWinsError: false,
                handlingChargeError: false,
                splitRemainingError: false,
                noOfWinsError: false,
                smallWinsError: false,
            });
        }
    }

    getMinPoolAmount(expSlotFillPercent, entryFeePerTeam, teamSlots) {
        return ((parseInt(expSlotFillPercent) * parseInt(entryFeePerTeam) * parseInt(teamSlots)) / 100).toFixed(0);
    }

    /**
     * delete the selected contests
     */
    onDeleteContest() {
        const {login, client} = this.props;
        let data = {
            contestId: this.state.contestId,
        };
        this.props.deleteContest(data, login.userDetails.data.accessToken, client.selectedClient);
        this.setState({open: false, loading: true});
    }

    /**
     * dynamically display prizes based on number of big win slots
     * @param {*} prizes prizes for
     */
    displayPrizes(prizes) {
        const {valueType, ranks, disabled} = this.state;
        let inputs = [];
        for (let i = 1; i <= prizes; i++) {
            let unit = '';
            if (i === 1) {
                unit = i + 'st';
            } else if (i === 2) {
                unit = i + 'nd';
            } else if (i === 3) {
                unit = i + 'rd';
            } else {
                unit = i + 'th';
            }
            let val = 'rank' + i;

            let error = val + 'Error';
            inputs.push(
                <div className="grid-item margin-top-20">
                    <Input
                        shrink={true}
                        label={unit}
                        type="text"
                        name={val}
                        suffix={valueType === 'CASHPERCENT' || valueType === 'COINPERCENT' ? '%' : undefined}
                        value={ranks[val]}
                        onChange={this.handleInputChange.bind(this, val)}
                        placeholder="Enter"
                        error={this.state[error]}
                        disabled={disabled}
                    />
                </div>,
            );
        }
        return inputs;
    }

    /**
     * dynamically display small win buckets based on number of small win buckets
     * @param {*} numberOfSmallWinBuckets
     */
    displaySmallWinPrizes(numberOfSmallWinBuckets) {
        const {valueType, smallWinBuckets, disabled} = this.state;
        let inputs = [];
        for (let i = 1; i <= numberOfSmallWinBuckets; i++) {
            let unit = '';
            if (i === 1) {
                unit = i + 'st';
            } else if (i === 2) {
                unit = i + 'nd';
            } else if (i === 3) {
                unit = i + 'rd';
            } else {
                unit = i + 'th';
            }
            let bucketAmount = 'bucketAmount_' + i;
            let numOfSlots = 'numOfSlots_' + i;
            let errorAmount = bucketAmount + 'Error';
            let errorSlots = numOfSlots + 'Error';

            let key = 'bucket' + i;
            inputs.push(
                <div className="card-grid-container no padding">
                    <div className="grid-item margin-top-20">
                        <Input
                            shrink={true}
                            label={unit + ' bucket - Amount'}
                            type="text"
                            name={bucketAmount}
                            suffix={valueType === 'CASHPERCENT' || valueType === 'COINPERCENT' ? '%' : undefined}
                            value={smallWinBuckets && smallWinBuckets[key] ? smallWinBuckets[key].bucketAmount : null}
                            onChange={this.handleInputChange.bind(this, bucketAmount)}
                            error={this.state[errorAmount]}
                            disabled={disabled}
                        />
                    </div>
                    <div className="grid-item margin-top-20">
                        <Input
                            shrink={true}
                            label={unit + ' bucket - NumberOfSlots'}
                            type="text"
                            name={numOfSlots}
                            value={smallWinBuckets && smallWinBuckets[key] ? smallWinBuckets[key].numOfSlots : null}
                            onChange={this.handleInputChange.bind(this, numOfSlots)}
                            error={this.state[errorSlots]}
                            placeholder="Enter"
                            disabled={disabled}
                        />
                    </div>
                </div>,
            );
        }
        return inputs;
    }

    /**
     * event handler for rules
     * @param {string} field name for the field
     * @param {string} e value in the field
     */
    handleRulesChange(field, e) {
        this.setState({
            rules: e.target.value,
            errorMessage: '',
            ctError: false,
            fcDiscountError: false,
            entryFeesError: false,
            teamSlotsError: false,
            minNoTeamError: false,
            maxTeamsError: false,
            bigWinsError: false,
            smallWinsError: false,
            handlingChargeError: false,
            splitRemainingError: false,
            noOfWinsError: false,
            numberOfContestsConfigError: false,
        });
    }

    componentDidMount() {
        const {login, contest, history, client} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            const {pageSize, page, edit, matchId} = this.state;
            let contestType = {
                page: page,
                pageSize: pageSize,
                all: true,
            };
            if (!edit) {
                this.props.getMatch(matchId, login.userDetails.data.accessToken, client.selectedClient);
                this.props.getContestTypeList(contestType, login.userDetails.data.accessToken, client.selectedClient);
            } else if (edit) {
                this.props.getContest(
                    contest.editContest.id,
                    login.userDetails.data.accessToken,
                    client.selectedClient,
                );
                this.props.getMatch(
                    contest.editContest.match,
                    login.userDetails.data.accessToken,
                    client.selectedClient,
                );
            }
        }
    }

    /**
     * display team details
     */
    displayTeams() {
        const {teams} = this.state;
        if (teams.length === 0) {
            return <div>No data to display</div>;
        } else {
            return teams.map((team) => {
                return (
                    <tr>
                        <td>{team.userName}</td>
                        <td>{team.team}</td>
                        <td>{timeFormat(team.createdAt)}</td>
                        <td>{team.fantasyPoints}</td>
                        <td>{team.ranking}</td>
                    </tr>
                );
            });
        }
    }

    /**
     * display user details
     */
    dispUsers() {
        const {users} = this.state;

        if (users.length === 0) {
            return <div>No data to display</div>;
        } else {
            return users.map((user) => {
                return (
                    <tr>
                        <td>{user.userName}</td>
                        <td>{user.teams}</td>
                        <td>{user.totalEntryFee}</td>
                    </tr>
                );
            });
        }
    }

    /**
     * display winner details
     */
    dispWinners() {
        const {users} = this.state;

        if (users.length === 0) {
            return <div>No data to display</div>;
        } else {
            return users.map((user) => {
                if (user.winningTeams > 0) {
                    return (
                        <tr>
                            <td>{user.userName}</td>
                            <td>{user.teams}</td>
                            <td>{user.winningTeams}</td>
                            <td>{user.totalEntryFee}</td>
                            <td>{user.winnings}</td>
                        </tr>
                    );
                }
            });
        }
    }

    componentDidUpdate(prevProps) {
        const {contestType, contest, match, history, user, dynamicPrizePools} = this.props;

        if (contestType.contestTypeListSuccess && !prevProps.contestType.contestTypeListSuccess) {
            this.setState({
                loading: false,
                menuItems: contestType.contestTypeList.data.map((contest) => {
                    return {title: contest.name, value: contest.id};
                }),
            });
        } else if (contestType.contestTypeListFail && !prevProps.contestType.contestTypeListFail) {
            this.setState({
                errorMessage: contestType.errorMessage,
                loading: false,
            });
        }

        if (
            contest.addContestSuccess &&
            !prevProps.contest.addContestSuccess &&
            !!contest?.addContest?.data?.confirmationRequired
        ) {
            this.setState({loading: false, contestCreateConfirm: true});
        }

        if (
            contest.addContestSuccess &&
            !prevProps.contest.addContestSuccess &&
            !contest?.addContest?.data?.confirmationRequired
        ) {
            history.push('/editMatch/' + this.state.matchId);
        } else if (contest.addContestFail && !prevProps.contest.addContestFail) {
            this.setState({
                errorMessage: contest.errorMessage,
                loading: false,
            });
        }

        if (contest.updateContestSuccess && !prevProps.contest.updateContestSuccess) {
            history.push('/contests');
        } else if (contest.updateContestFail && !prevProps.contest.updateContestFail) {
            this.setState({
                errorMessage: contest.errorMessage,
                loading: false,
            });
        }
        if (match.getMatchSuccess && !prevProps.match.getMatchSuccess) {
            this.setState({
                matchStatus: match.getMatch.data.status === 'COMPLETED',
            });
            this.props.editMatch(match.getMatch.data);
        }
        if (contest.deleteContestSuccess && !prevProps.contest.deleteContestSuccess) {
            this.setState({
                errorMessage: contest.deleteContest.msg,
                contest: [],
                loading: false,
            });
            if (contest.deleteContest.msg === 'Contest Deleted') {
                history.push('/contests');
            }
        } else if (contest.deleteContestFail && !prevProps.contest.deleteContestFail) {
            this.setState({
                errorMessage: contest.errorMessage,
                loading: false,
            });
        } else if (match.getMatchFail && !prevProps.match.getMatchFail) {
            this.setState({
                errorMessage: match.errorMessage,
                loading: false,
            });
        }
        if (contest.getContestSuccess && !prevProps.contest.getContestSuccess) {
            const {
                name,
                fcCoinDiscountPercent,
                type,
                entryFeePerTeam,
                totalSlots,
                minParticipationRequired,
                minNumberOfTeams,
                maxTeamsPerUser,
                numberOfBigWinSlots,
                winningDistribution,
                match,
                totalSlotsEntered,
                contestRules,
                contestType,
                id,
                teams,
                users,
                winners,
                valueType,
                status,
                numberOfSmallWinBuckets,
                expSlotFillPercent,
                minPoolAmount,
                tags,
                minFanGemsDiscPercent,
                maxFanGemsDiscPercent,
                category,
                estarDetails,
                eligibleUsers,
                prizePoolData = {},
                substitutionData = {},
            } = contest.getContest.data;

            let appUserOptions = [];
            if (category && category.name === 'fantasyWithEstar' && estarDetails) {
                appUserOptions = [{title: estarDetails.name, value: estarDetails}];
            }

            let ranks = winningDistribution.winners;
            this.setState({
                tags: tags ? tags : [],
                ctName: name,
                fcDiscount: fcCoinDiscountPercent,
                contestType: {title: name, id: contestType},
                cashType: type,
                entryFees: entryFeePerTeam,
                teamSlots: totalSlots,
                minParticipants: minParticipationRequired,
                minNoTeam: minNumberOfTeams,
                maxTeams: maxTeamsPerUser,
                bigWins: numberOfBigWinSlots,
                noOfSmallWinBuckets: numberOfSmallWinBuckets,
                rules: contestRules,
                contestId: id,
                splitRemaining: winningDistribution.splitRemaining,
                noOfWins: winningDistribution.smallWinSlots,
                handlingCharge: winningDistribution.handling,
                contestTypeId: contestType,
                smallWinBuckets: winningDistribution.smallWinBuckets,
                ranks,
                teams,
                matchId: match,
                valueType,
                expSlotFillPercent,
                minPoolAmount,
                users,
                winners,
                loading: false,
                slotsLeft: totalSlotsEntered,
                disabled: status !== 'AVAILABLE',
                minFanGemsDiscPercent,
                maxFanGemsDiscPercent,
                appUserOptions,
                estarDetails: appUserOptions[0],
                category,
                eligibleUsers: eligibleUsers || undefined,
                prizePoolType: prizePoolData?.type || '',
                dynamicPrizePool: !!prizePoolData?.templateId ? {title: '', value: prizePoolData?.templateId} : null,
                totalSubsAllowed: substitutionData?.totalSubsAllowed,
                subsFeeType: substitutionData?.subsFeeType,
                subsFee: substitutionData?.subsFee,
            });

            if (!!prizePoolData?.templateId) {
                this.getPrizePoolTemplates({templateId: prizePoolData?.templateId});
            }
        } else if (contest.getContestFail && !prevProps.contest.getContestFail) {
            this.setState({
                errorMessage: contest.errorMessage,
                loading: false,
            });
        }

        if (contestType.getContestSuccess && !prevProps.contestType.getContestSuccess) {
            const {
                name,
                fcCoinDiscountPercent,
                type,
                entryFeePerTeam,
                totalSlots,
                minParticipationRequired,
                minNumberOfTeams,
                maxTeamsPerUser,
                numberOfBigWinSlots,
                winningDistribution,
                contestRules,
                valueType,
                numberOfSmallWinBuckets,
                expSlotFillPercent,
                category,
                minFanGemsDiscPercent,
                maxFanGemsDiscPercent,
                eligibleUsers,
                prizePoolData = {},
                substitutionData = {},
            } = contestType.getContest.data;

            let ranks = winningDistribution.winners;
            this.setState({
                ctName: name,
                contestType: {title: name, value: contestType.getContest.data.contestType},
                fcDiscount: fcCoinDiscountPercent,
                cashType: type,
                entryFees: entryFeePerTeam,
                teamSlots: totalSlots,
                minParticipants: minParticipationRequired,
                minNoTeam: minNumberOfTeams,
                maxTeams: maxTeamsPerUser,
                bigWins: numberOfBigWinSlots,
                noOfSmallWinBuckets: numberOfSmallWinBuckets,
                rules: contestRules,
                handlingCharge: winningDistribution.handling,
                splitRemaining: winningDistribution.splitRemaining,
                noOfWins: winningDistribution.smallWinSlots,
                contestTypeId: contestType.getContest.data.contestType,
                smallWinBuckets: winningDistribution.smallWinBuckets,
                ranks,
                valueType,
                expSlotFillPercent,
                loading: false,
                minPoolAmount: this.getMinPoolAmount(entryFeePerTeam, totalSlots, expSlotFillPercent),
                category,
                minFanGemsDiscPercent,
                maxFanGemsDiscPercent,
                eligibleUsers: eligibleUsers || undefined,
                prizePoolType: prizePoolData?.type || '',
                dynamicPrizePool: !!prizePoolData?.templateId ? {title: '', value: prizePoolData?.templateId} : null,
                totalSubsAllowed: substitutionData?.totalSubsAllowed,
                subsFeeType: substitutionData?.subsFeeType,
                subsFee: substitutionData?.subsFee,
            });
            if (!!prizePoolData?.templateId) {
                this.getPrizePoolTemplates({templateId: prizePoolData?.templateId});
            }
            this.handleAppUserApi();
        } else if (contestType.getContestFail && !prevProps.contestType.getContestFail) {
            this.setState({errorMessage: contestType.errorMessage, loading: false});
        }

        if (user.isFetchingAppUsers && !prevProps.user.isFetchingAppUsers) {
            this.setState({loading: true});
        }

        if (user.isFetchingAppUsersSuccess && !prevProps.user.isFetchingAppUsersSuccess) {
            this.handleAppUserData();
        }

        if (user.isFetchingAppUsersFail && !prevProps.user.isFetchingAppUsersFail) {
            this.setState({errorMessage: user.errorMessage.errorReason, loading: false});
        }

        if (dynamicPrizePools.isFetchingDynamicPrizePools && !prevProps.dynamicPrizePools.isFetchingDynamicPrizePools) {
            this.setState({loading: true});
        }
        if (
            dynamicPrizePools.isFetchingDynamicPrizePoolsSuccess &&
            !prevProps.dynamicPrizePools.isFetchingDynamicPrizePoolsSuccess
        ) {
            this.handleDynamicPrizePoolData();
        }
        if (
            dynamicPrizePools.isFetchingDynamicPrizePoolsFail &&
            !prevProps.dynamicPrizePools.isFetchingDynamicPrizePoolsFail
        ) {
            this.setState({errorMessage: dynamicPrizePools?.message?.msg, loading: false});
        }
    }

    handleDynamicPrizePoolData = () => {
        const {
            dynamicPrizePools: {
                list: {data = []},
            },
        } = this.props;
        let {dynamicPrizePool} = this.state;

        const opn = data?.map(({id, templateName, prizeDistribution = []}) => ({
            title: templateName,
            value: id,
            prizeDistribution,
        }));

        if (dynamicPrizePool?.value && !dynamicPrizePool?.title) {
            dynamicPrizePool = opn.filter((o) => o.value === dynamicPrizePool?.value)?.[0];
        }

        this.setState({loading: false, dynamicPrizePoolOptions: opn, dynamicPrizePool});
    };

    handleAppUserApi = () => {
        console.log('HANDLE_APP_USER');
        const {contestType, getAppUserList, login, client} = this.props;
        const {category} = contestType.getContest.data;
        if (category && category.name === 'fantasyWithEstar') {
            console.log('INNER_HANDLE');
            getAppUserList({userType: APP_USER_TYPE.ESTAR}, login.userDetails.data.accessToken, client.selectedClient);
        }
    };

    handleAppUserData = () => {
        const {
            user: {
                appUsersList: {data = []},
            },
        } = this.props;
        const appUserOptions = data.map((u) => ({
            title: u.estarDetails.name,
            value: {
                appUserId: u.id,
                image: u.estarDetails.image,
                name: u.estarDetails.name,
            },
        }));
        this.setState({loading: false, appUserOptions});
    };

    /**
     * selecting a contest type
     */
    getContestTypeDetails() {
        const {contestType, menuItems} = this.state;
        const {login, client} = this.props;
        if (contestType === null) {
            this.setState({errorMessage: 'Please Select a contest Type'});
        } else {
            let value = this.state.contestType;
            let contests = menuItems;
            let index = contests.findIndex((x) => x.value === value.value);
            if (index !== -1) {
                contests.splice(index, 1);
            }
            this.setState({menuItems: contests, getData: true});
            this.props.getContestType(contestType.value, login.userDetails.data.accessToken, client.selectedClient);
        }
    }

    /**
     * API to save the contest
     */
    onSave() {
        const {
            cashType,
            fcDiscount,
            ctName,
            entryFees,
            teamSlots,
            minNoTeam,
            numberOfContestsConfig,
            rules,
            matchId,
            valueType,
            ranks,
            minParticipants,
            maxTeams,
            bigWins,
            handlingCharge,
            splitRemaining,
            noOfWins,
            edit,
            noOfSmallWinBuckets,
            smallWinBuckets,
            expSlotFillPercent,
            minPoolAmount,
            category,
            tags,
            minFanGemsDiscPercent,
            maxFanGemsDiscPercent,
            estarDetails,
            contestCreateConfirm,
            eligibleUsers,
            prizePoolType,
            dynamicPrizePool,
            totalSubsAllowed,
            subsFeeType,
            subsFee,
        } = this.state;
        const {login, contest, contestType, client} = this.props;

        if (ctName === null || ctName === '' || ctName === undefined) {
            this.setState({
                ctError: true,
                errorMessage: 'Enter valid Contest Type Name',
            });
        } else if (entryFees === null || !isNumber(entryFees)) {
            this.setState({
                entryFeesError: true,
                errorMessage: 'Enter valid Entry Fees',
            });
        } else if (teamSlots === null || !isNumber(teamSlots)) {
            this.setState({
                teamSlotsError: true,
                errorMessage: 'Enter valid team slots',
            });
        } else if (minNoTeam === null || !isNumber(minNoTeam)) {
            this.setState({
                minNoTeamError: true,
                errorMessage: 'Enter valid Number of Teams',
            });
        } else if (minParticipants === null || minParticipants === undefined) {
            this.setState({
                minParticipantsError: true,
                errorMessage: 'Select minimun number of participants',
            });
        } else if (maxTeams === null || !isNumber(maxTeams)) {
            this.setState({
                maxTeamsError: true,
                errorMessage: 'Enter valid number of max teams',
            });
        } else if (numberOfContestsConfig === null || !isNumber(numberOfContestsConfig)) {
            this.setState({
                numberOfContestsConfigError: true,
                errorMessage: 'Enter valid number of number Of Contests Config',
            });
        } else if (bigWins === null || !isNumber(bigWins)) {
            this.setState({
                bigWinsError: true,
                errorMessage: 'Enter valid number of big wins',
            });
        } else if (noOfSmallWinBuckets === null || !isNumber(noOfSmallWinBuckets)) {
            this.setState({
                smallWinsError: true,
                errorMessage: 'Enter valid number of small win buckets',
            });
        } else if (
            (handlingCharge === null || !isNumber(handlingCharge)) &&
            cashType !== 'free' &&
            cashType !== 'fcCoins'
        ) {
            this.setState({
                handlingChargeError: true,
                errorMessage: 'Enter valid handling charge',
            });
        } else if (
            (valueType === 'CASHPERCENT' || valueType === 'COINPERCENT') &&
            teamSlots >= 2 &&
            (minPoolAmount === null || !isNumber(minPoolAmount))
        ) {
            this.setState({
                handlingChargeError: true,
                errorMessage: 'Enter valid minPool',
            });
        } else if (teamSlots === null || !isNumber(teamSlots)) {
            /*else if ((splitRemaining === null || !isNumber(splitRemaining)) && cashType !== "free") {
            this.setState({
                splitRemainingError: true,
                errorMessage: "Enter valid split",
            });
        }
        else if ((noOfWins === null || !isNumber(noOfWins)) && cashType !== "free") {
            this.setState({
                noOfWinsError: true,
                errorMessage: "Enter valid No of wins",
            });
        }*/
            this.setState({errorMessage: 'Team slots should be number'});
        } else if (numberOfContestsConfig === null || !isNumber(numberOfContestsConfig)) {
            this.setState({errorMessage: 'Number Of Contests for this Config should be number'});
        } else if (category && category.name === 'fantasyWithEstar' && !estarDetails) {
            this.setState({errorMessage: 'Please Select Estar User'});
        } else if (!prizePoolType) {
            this.setState({errorMessage: 'Select valid prize pool type'});
        } else if (prizePoolType === PRIZE_POOL_TYPE.DYNAMIC && !dynamicPrizePool?.value) {
            this.setState({errorMessage: 'Select valid prize pool template'});
        } else {
            let winner = {};
            let rankTotal = 0;
            let total;
            for (let i = 1; i <= bigWins; i++) {
                if (
                    ranks !== undefined &&
                    ranks['rank' + i] !== undefined &&
                    ranks['rank' + i] !== null &&
                    isValidNumber(ranks['rank' + i])
                ) {
                    //  ranks['rank' + i] = Math.round(parseFloat(ranks['rank' + i] * 100)) / 100;
                    rankTotal += ranks['rank' + i];
                    winner['rank' + i] = ranks['rank' + i];
                } else {
                    this.setState({
                        ['rank' + i + 'Error']: true,
                        errorMessage: 'Enter valid amount for ranks',
                    });
                    return false;
                }
            }

            let smallWinBucketList = {};
            let smallWinTotal = 0;
            for (let i = 1; i <= noOfSmallWinBuckets; i++) {
                let key = 'bucket' + i;
                if (smallWinBuckets && smallWinBuckets[key]) {
                    smallWinBucketList[key] = {};
                    if (isNumber(smallWinBuckets[key].numOfSlots)) {
                        smallWinBucketList[key].numOfSlots = parseInt(smallWinBuckets[key].numOfSlots);
                    } else {
                        this.setState({
                            ['numOfSlots_' + i + 'Error']: true,
                            errorMessage: 'Enter valid number of slots for smallWins bucket' + i,
                        });
                        return false;
                    }
                    if (isValidNumber(smallWinBuckets[key].bucketAmount)) {
                        smallWinBucketList[key].bucketAmount = smallWinBuckets[key].bucketAmount;
                        smallWinTotal += smallWinBuckets[key].bucketAmount;
                    } else {
                        this.setState({
                            ['bucketAmount_' + i + 'Error']: true,
                            errorMessage: 'Enter valid bucket Amount for smallWins bucket' + i,
                        });
                        return false;
                    }
                } else {
                    this.setState({
                        ['noOfSmallWinBuckets' + 'Error']: true,
                        errorMessage: 'Enter valid small win bucket information ' + i,
                    });
                    return false;
                }
            }

            let charges = cashType === 'free' || cashType === 'fcCoins' ? 0 : handlingCharge;

            let winDistribution = {
                handling: charges,
                winners: winner,
                smallWinBuckets: smallWinBucketList,
            };
            if (edit) {
                //backward compatibility
                winDistribution.splitRemaining = splitRemaining;
                winDistribution.smallWinSlots = noOfWins;
            }
            total = parseInt(charges) + rankTotal + smallWinTotal;
            total += splitRemaining ? parseInt(splitRemaining) : 0;

            let minPoolCoins, minPoolAmountFinal, expSlotFillPercentFinal;
            if (valueType === 'COINPERCENT') {
                minPoolCoins = minPoolAmount;
                minPoolAmountFinal = undefined;
                expSlotFillPercentFinal = expSlotFillPercent;
            } else if (valueType === 'CASHPERCENT') {
                minPoolAmountFinal = minPoolAmount;
                expSlotFillPercentFinal = expSlotFillPercent;
            } else {
                minPoolAmountFinal = undefined;
                expSlotFillPercentFinal = undefined;
            }

            let newContest = {
                name: ctName,
                fcCoinDiscountPercent: fcDiscount,
                type: cashType,
                entryFeePerTeam: entryFees,
                totalSlots: teamSlots,
                numberOfContests: parseInt(numberOfContestsConfig),
                minParticipationRequired: minParticipants,
                minNumberOfTeams: minNoTeam,
                maxTeamsPerUser: maxTeams,
                numberOfBigWinSlots: bigWins,
                numberOfSmallWinBuckets: noOfSmallWinBuckets,
                winningDistribution: JSON.stringify(winDistribution),
                //contestRules: rules,
                match: matchId,
                valueType: valueType,
                contestId: contest.editContest.id,
                minPoolAmount: minPoolAmountFinal,
                minPoolCoins,
                expSlotFillPercent: expSlotFillPercentFinal,
                category,
                tags,
                eligibleUsers: eligibleUsers || undefined,
                prizePoolData: {type: prizePoolType},
                substitutionData: {totalSubsAllowed, subsFeeType, subsFee},
            };

            if (prizePoolType === PRIZE_POOL_TYPE.DYNAMIC && !!dynamicPrizePool?.value) {
                newContest.prizePoolData.templateId = dynamicPrizePool?.value;
            }

            if (cashType === 'cash+gems') {
                newContest = {...newContest, minFanGemsDiscPercent, maxFanGemsDiscPercent};
            }

            if (category && category.name === 'fantasyWithEstar') {
                newContest.estarDetails = estarDetails.value;
            }

            const onlyPercentValueAllow = ['CASHVALUE', 'IGCVALUE', 'COINVALUE'];
            if (
                total > 100 &&
                cashType !== 'free' &&
                !onlyPercentValueAllow.includes(valueType) &&
                prizePoolType !== PRIZE_POOL_TYPE.DYNAMIC
            ) {
                this.setState({
                    errorMessage: 'Please make sure prize pool % does not exceed 100',
                });
                return false;
            } else if (edit) {
                this.setState({loading: true});
                newContest.contestType = contest.editContest.contestType;
                this.props.updateContest(newContest, login.userDetails.data.accessToken, client.selectedClient);
            } else {
                this.setState({loading: true});
                newContest.contestId = undefined;
                newContest.contestType = contestType.getContest.data.id;
                newContest.isConfirm = !!contestCreateConfirm;
                this.props.createContest(newContest, login.userDetails.data.accessToken, client.selectedClient);
            }
        }
    }

    getPrizePoolTemplates = debounce(({templateName, templateId}) => {
        const {login, client} = this.props;
        this.props.getDynamicPrizePools(
            {
                templateName,
                templateId,
                status: COMMON_STATUS_CONST.ACTIVE,
            },
            login.userDetails.data.accessToken,
            client.selectedClient,
        );
    });

    handleOnChange = (stateObj = {}) => {
        if (stateObj.prizePoolType === PRIZE_POOL_TYPE.DYNAMIC) {
            stateObj = {...stateObj, valueType: 'CASHPERCENT'};
        }
        this.setState(stateObj);
    };

    handleOnChangeSwitchFees = (_switchFees, _value, _index) => {
        const newSwitchFees = _switchFees.slice();
        newSwitchFees.splice(_index, 1, parseInt(_value));
        this.handleOnChange({subsFee: newSwitchFees});
    };

    handleOnChangeDpp = (template = {}) => {
        const {edit} = this.state;
        const winningDistribution =
            template?.prizeDistribution?.[(template?.prizeDistribution?.length || 0) - 1]?.winningDistribution || {};
        this.setState({
            ...(!!edit ? {ranks: winningDistribution?.winners || {}} : winningDistribution?.winners || {}),
            smallWinBuckets: winningDistribution?.smallWinBuckets || {},
            handlingCharge: winningDistribution?.handling,
            noOfSmallWinBuckets: Object.keys(winningDistribution?.smallWinBuckets || {})?.length,
            bigWins: Object.keys(winningDistribution?.winners || {})?.length,
            dynamicPrizePool: template,
        });
    };

    /**
     * UI for tab A
     */
    renderTabA() {
        const {
            menuItems,
            disabled,
            matchId,
            cashType,
            contestId,
            contestType,
            ctName,
            freeOptions,
            minNoTeam,
            minParticipants,
            participantsOptions,
            slotsLeft,
            teams,
            users,
            maxTeams,
            bigWins,
            handlingCharge,
            edit,
            getData,
            valueType,
            fcOption,
            cashValueOption,
            fcDiscount,
            splitRemaining,
            noOfWins,
            entryFees,
            teamSlots,
            numberOfContestsConfig,
            rules,
            noOfSmallWinBuckets,
            expSlotFillPercent,
            minPoolAmount,
            tagsOptions,
            tags,
            minFanGemsDiscPercent,
            maxFanGemsDiscPercent,
            appUserOptions,
            estarDetails,
            category,
            eligibleUsers,
            prizePoolType,
            dynamicPrizePool,
            dynamicPrizePoolOptions = [],
            totalSubsAllowed,
            subsFeeType,
            subsFee = [],
        } = this.state;
        const {addContestTypeSwitchTypeContest, addContestTypeTotalSwitchAllowed, addContestTypeSwitchFreeTypes} =
            this.props.login?.permissions || {};
        return (
            <div>
                <div className="card-grid-container nopadding ">
                    {/* row 1 */}
                    <div className="grid-item margin-top-20 ">
                        <Input
                            shrink={true}
                            label="Match ID"
                            type="text"
                            name="matchId"
                            value={matchId}
                            onChange={this.handleInputChange.bind(this, 'matchId')}
                            placeholder="Enter"
                            disabled={true}
                        />
                    </div>
                    <div className="grid-item margin-top-20">
                        {edit ? (
                            <Input
                                shrink={true}
                                label="Contest ID"
                                type="text"
                                name="contestId"
                                value={contestId}
                                onChange={this.handleInputChange.bind(this, 'contestId')}
                                placeholder="Enter"
                                disabled={true}
                            />
                        ) : null}
                    </div>
                </div>
                {edit || getData ? (
                    <React.Fragment>
                        <div className="card-grid-container nopadding">
                            <div className="grid-item margin-top-20">
                                <AutoCompleteField
                                    label="Contest Type "
                                    onChange={this.handleInputChange.bind(this, 'contestType')}
                                    options={menuItems}
                                    value={contestType}
                                    disabled={edit}
                                    inputChange={() => {}}
                                />
                            </div>
                            {category && category.name === 'fantasyWithEstar' && (
                                <div className="grid-item margin-top-20">
                                    <AutoCompleteField
                                        label="Select Estar User"
                                        onChange={this.handleInputChange.bind(this, 'estarDetails')}
                                        options={appUserOptions || []}
                                        value={estarDetails || null}
                                        disabled={edit}
                                        inputChange={() => {}}
                                    />
                                </div>
                            )}
                            <div className="grid-item margin-top-20">
                                <DropDown
                                    className="mb-15"
                                    label="Prize Pool Type"
                                    menuItems={PRIZE_POOL_TYPE_OPTIONS}
                                    disabled
                                    name="prizePoolType"
                                    value={prizePoolType || ''}
                                    placeholder="Select"
                                    onChange={(value) => this.handleOnChange({prizePoolType: value})}
                                />
                            </div>
                            {prizePoolType === PRIZE_POOL_TYPE.DYNAMIC && (
                                <div className="grid-item margin-top-20">
                                    <AutoCompleteField
                                        label="Prize Pool Template"
                                        disabled
                                        onChange={(opn) => this.handleOnChangeDpp(opn)}
                                        options={dynamicPrizePoolOptions}
                                        value={dynamicPrizePool || ''}
                                    />
                                </div>
                            )}
                            <div className="grid-item margin-top-20">
                                <Input
                                    shrink={true}
                                    label="Contest Title"
                                    type="text"
                                    name="ctName"
                                    value={ctName}
                                    onChange={this.handleInputChange.bind(this, 'ctName')}
                                    placeholder="Enter"
                                    disabled={disabled}
                                />
                            </div>
                            {!!eligibleUsers && (
                                <div className="grid-item margin-top-20">
                                    <DropDown
                                        menuItems={USER_TYPES_OPTIONS}
                                        label="User Type"
                                        value={eligibleUsers || ''}
                                        placeholder="Select user type"
                                        disabled={true}
                                        onChange={this.handleInputChange.bind(this, 'eligibleUsers')}
                                    />
                                </div>
                            )}
                            <div className="grid-item margin-top-20">
                                {cashType === 'cash+fc' && (
                                    <Input
                                        label="% FC Coin Discount"
                                        type="text"
                                        name="fcDiscount"
                                        value={fcDiscount}
                                        onChange={this.handleInputChange.bind(this, 'fcDiscount')}
                                        placeholder="Enter % FC Coin Discount"
                                        disabled={disabled}
                                    />
                                )}
                            </div>
                            {cashType === 'cash+gems' && (
                                <div className="grid-item margin-top-20">
                                    <Input
                                        label="% Max Fan Gems Discount"
                                        type="text"
                                        name="maxFanGemsDiscPercent"
                                        value={maxFanGemsDiscPercent}
                                        onChange={this.handleInputChange.bind(this, 'maxFanGemsDiscPercent')}
                                        placeholder="Enter % Max Fan Gems Discount"
                                        disabled={disabled}
                                    />
                                </div>
                            )}
                            {cashType === 'cash+gems' && (
                                <div className="grid-item margin-top-20">
                                    <Input
                                        label="% Min Fan Gems Discount"
                                        type="text"
                                        name="maxFanGemsDiscPercent"
                                        value={minFanGemsDiscPercent}
                                        onChange={this.handleInputChange.bind(this, 'minFanGemsDiscPercent')}
                                        placeholder="Enter % Min Fan Gems Discount"
                                        disabled={disabled}
                                    />
                                </div>
                            )}
                            <div></div>
                            {/* row 3 */}
                            <div className="grid-item margin-top-20">
                                {cashType === 'cash' || cashType === 'cash+fc' || cashType === 'cash+gems' ? (
                                    <DropDown
                                        menuItems={cashValueOption}
                                        label="Value type"
                                        value={valueType}
                                        placeholder="select"
                                        disabled={disabled || prizePoolType === PRIZE_POOL_TYPE.DYNAMIC}
                                        onChange={this.handleInputChange.bind(this, 'valueType')}
                                    />
                                ) : (
                                    <DropDown
                                        menuItems={cashType === 'free' ? freeOptions : fcOption}
                                        label="Value type"
                                        value={valueType}
                                        placeholder="select"
                                        disabled={disabled || prizePoolType === PRIZE_POOL_TYPE.DYNAMIC}
                                        onChange={this.handleInputChange.bind(this, 'valueType')}
                                    />
                                )}
                            </div>

                            <div className="grid-item margin-top-20">
                                <DropDownMulti
                                    placeholder="Tags"
                                    options={tagsOptions}
                                    value={tags}
                                    onChange={this.handleInputChange.bind(this, 'tags')}
                                />
                            </div>

                            <div className="grid-item margin-top-20">
                                <Input
                                    shrink={true}
                                    label="Entry Fee / Team"
                                    type="text"
                                    name="entryFee"
                                    value={entryFees}
                                    onChange={this.handleInputChange.bind(this, 'entryFees')}
                                    placeholder="Enter"
                                    disabled={disabled}
                                />
                            </div>
                            {/* row 4 */}
                            <div className="grid-item margin-top-20">
                                <DropDown
                                    menuItems={participantsOptions}
                                    label="Minimum Participation Required"
                                    value={minParticipants}
                                    placeholder="select"
                                    disabled={disabled}
                                    onChange={this.handleInputChange.bind(this, 'minParticipants')}
                                />
                            </div>
                            <div className="grid-item margin-top-20">
                                <Input
                                    label="Minimum # of Teams"
                                    type="text"
                                    name="minNoTeam"
                                    value={minNoTeam}
                                    onChange={this.handleInputChange.bind(this, 'minNoTeam')}
                                    placeholder="Enter Minimum # of Teams"
                                    disabled={!minParticipants || disabled}
                                />
                            </div>

                            {/* row 4 */}
                            <div className="grid-item margin-top-20">
                                <Input
                                    shrink={true}
                                    label="Total Team Slots"
                                    type="text"
                                    name="teamSlots"
                                    value={teamSlots}
                                    onChange={this.handleInputChange.bind(this, 'teamSlots')}
                                    placeholder="Enter"
                                    disabled={disabled || prizePoolType === PRIZE_POOL_TYPE.DYNAMIC}
                                />
                            </div>
                            <div className="grid-item margin-top-20">
                                <Input
                                    shrink={true}
                                    label="Number Of Contests to be made for this config"
                                    type="number"
                                    name="numberOfContestsConfig"
                                    value={numberOfContestsConfig}
                                    onChange={this.handleInputChange.bind(this, 'numberOfContestsConfig')}
                                    placeholder="Enter"
                                    disabled={disabled}
                                />
                            </div>
                            <div className="grid-item margin-top-20">
                                {valueType === 'CASHPERCENT' || valueType === 'COINPERCENT' ? (
                                    <Input
                                        shrink={true}
                                        label="Expected slot fill %"
                                        type="text"
                                        name="expSlotFillPercent"
                                        value={expSlotFillPercent}
                                        onChange={this.handleInputChange.bind(this, 'expSlotFillPercent')}
                                        placeholder="Enter"
                                        disabled={disabled}
                                    />
                                ) : null}
                            </div>
                            <div className="grid-item margin-top-20">
                                {valueType === 'CASHPERCENT' || valueType === 'COINPERCENT' ? (
                                    <Input
                                        shrink={true}
                                        label="minPoolAmount"
                                        type="text"
                                        name="minPoolAmount"
                                        value={minPoolAmount}
                                        onChange={this.handleInputChange.bind(this, 'minPoolAmount')}
                                        placeholder="Enter"
                                        disabled={disabled}
                                    />
                                ) : null}
                            </div>
                            <div className="grid-item margin-top-20 disp-flex">
                                {edit ? (
                                    <React.Fragment>
                                        <div>
                                            <div className="basic-headers">Slots</div>
                                            <div className="basiic-data">{slotsLeft}</div>
                                        </div>

                                        <div>
                                            <div className="basic-headers">Teams</div>
                                            <div className="basiic-data">{teams.length}</div>
                                        </div>
                                        <div>
                                            <div className="basic-headers">Users</div>
                                            <div className="basiic-data">{users.length}</div>
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    ''
                                )}
                            </div>
                            {/* row5 */}
                            <div className="grid-item margin-top-20">
                                <Input
                                    shrink={true}
                                    label="Max Teams / User"
                                    type="text"
                                    name="maxTeams"
                                    value={maxTeams}
                                    onChange={this.handleInputChange.bind(this, 'maxTeams')}
                                    placeholder="Enter"
                                    disabled={disabled}
                                />
                            </div>
                            <div className="grid-item margin-top-20">
                                <Input
                                    shrink={true}
                                    label="# of Big Win Slots"
                                    type="text"
                                    name="bigWins"
                                    value={bigWins}
                                    onChange={this.handleInputChange.bind(this, 'bigWins')}
                                    placeholder="Enter"
                                    disabled={disabled || prizePoolType === PRIZE_POOL_TYPE.DYNAMIC}
                                />
                                <Input
                                    shrink={true}
                                    label="Number of Small Win Buckets"
                                    type="text"
                                    name="noOfSmallWinBuckets"
                                    value={noOfSmallWinBuckets}
                                    onChange={this.handleInputChange.bind(this, 'noOfSmallWinBuckets')}
                                    placeholder="Enter"
                                    disabled={disabled || prizePoolType === PRIZE_POOL_TYPE.DYNAMIC}
                                />
                            </div>
                            {/* row 6 */}
                            <div className="grid-item basiic-data margin-top-20">Prize Pool as Winnings</div>
                            <div className="grid-item margin-top-20" />

                            {/* row 7 */}
                            <div className="grid-item margin-top-20">
                                {cashType === 'free' || cashType === 'fcCoins' ? null : (
                                    <Input
                                        label="Handling Charges"
                                        type="text"
                                        name="handlingCharge"
                                        value={handlingCharge}
                                        onChange={this.handleInputChange.bind(this, 'handlingCharge')}
                                        placeholder="Enter Handling Charges"
                                        disabled={disabled || prizePoolType === PRIZE_POOL_TYPE.DYNAMIC}
                                        suffix={
                                            valueType === 'CASHPERCENT' || valueType === 'COINPERCENT' ? '%' : undefined
                                        }
                                    />
                                )}
                            </div>
                            <div className="grid-item margin-top-20" />
                        </div>

                        {/* switch row */}
                        {!!addContestTypeSwitchTypeContest && (
                            <div className="grid-item grid-col-1-snap-2 margin-top-30">Switch Type Contest</div>
                        )}
                        {!!addContestTypeTotalSwitchAllowed && (
                            <div className="margin-top-10">
                                <Input
                                    label="Max Switch Allowed"
                                    type="number"
                                    name="totalSubsAllowed"
                                    value={totalSubsAllowed || ''}
                                    onChange={(value) => this.handleOnChange({totalSubsAllowed: parseInt(value)})}
                                />
                            </div>
                        )}
                        {!!addContestTypeSwitchFreeTypes && (
                            <div className="margin-top-20">
                                <DropDown
                                    menuItems={SUBSTITUTION_FEE_TYPES_OPTIONS}
                                    label="Switch Fee Types"
                                    value={subsFeeType || ''}
                                    onChange={(value) => this.handleOnChange({subsFeeType: value})}
                                />
                            </div>
                        )}
                        {!!totalSubsAllowed &&
                            Array(parseInt(totalSubsAllowed))
                                .fill(null)
                                .map((_, index) => (
                                    <div className="margin-top-10" key={`filed-${index + 1}`}>
                                        <DropDown
                                            menuItems={SUBSTITUTION_FEE_OPTIONS}
                                            label={`Switch Fees ${index + 1}`}
                                            value={subsFee[index]}
                                            onChange={(value) => this.handleOnChangeSwitchFees(subsFee, value, index)}
                                        />
                                    </div>
                                ))}

                        {/* row 8 */}
                        {prizePoolType !== PRIZE_POOL_TYPE.DYNAMIC && (
                            <div className="grid-item basiic-data margin-top-20">Big Winnings</div>
                        )}
                        {prizePoolType !== PRIZE_POOL_TYPE.DYNAMIC && (
                            <div className="card-grid-container nopadding ">{this.displayPrizes(bigWins)}</div>
                        )}
                        {prizePoolType !== PRIZE_POOL_TYPE.DYNAMIC && (
                            <div className="grid-item basiic-data margin-top-20">Small Winnings</div>
                        )}
                        {prizePoolType !== PRIZE_POOL_TYPE.DYNAMIC && (
                            <div>{this.displaySmallWinPrizes(noOfSmallWinBuckets)}</div>
                        )}
                        {/* row 11 */}
                        {cashType === 'free' ? null : splitRemaining > 0 || noOfWins > 0 ? (
                            <div className="card-grid-container nopadding ">
                                <div className="grid-item margin-top-20">
                                    <Input
                                        shrink={true}
                                        label="Split Remaining"
                                        type="text"
                                        name="splitRemaining"
                                        value={splitRemaining}
                                        onChange={this.handleInputChange.bind(this, 'splitRemaining')}
                                        placeholder="Enter"
                                        suffix={
                                            valueType === 'CASHPERCENT' || valueType === 'COINPERCENT' ? '%' : undefined
                                        }
                                        disabled={disabled}
                                    />
                                </div>
                                <div className="grid-item margin-top-20">
                                    <Input
                                        shrink={true}
                                        label="#of small win slots"
                                        type="text"
                                        name="noOfWins"
                                        value={noOfWins}
                                        onChange={this.handleInputChange.bind(this, 'noOfWins')}
                                        placeholder="Enter"
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                        ) : null}
                        {category && category.name === 'fantasyWithEstar' && estarDetails && (
                            <div className="card-fullgrid-container margin-top-20">
                                <label>
                                    {estarDetails.value.name} -{estarDetails.value.appUserId}
                                </label>
                                <img
                                    src={estarDetails.value.image}
                                    alt={estarDetails.value.name}
                                    width="120px"
                                    height="120px"
                                />
                            </div>
                        )}
                        {/* <div className="card-fullgrid-container margin-top-20">
                            <label for="rules">Contest Rules</label>
                            <TextareaAutosize
                                name="rules"
                                className="margin-top-20"
                                aria-label="maximum height"
                                value={rules}
                                placeholder="Contest Rules"
                                onChange={this.handleRulesChange.bind(this, 'rules')}
                                disabled={disabled}
                            />
                        </div> */}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="card-grid-container nopadding ">
                            <div className="grid-item margin-top-20">
                                <AutoCompleteField
                                    label="Contest Type "
                                    onChange={this.handleInputChange.bind(this, 'contestType')}
                                    options={menuItems}
                                    value={contestType}
                                    disabled={edit}
                                    inputChange={() => {}}
                                />
                            </div>
                        </div>
                        <div className="margin-top-20">
                            <Button buttonText="Next" onClick={this.getContestTypeDetails.bind(this)} />
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    }

    /**
     * UI for tab B
     */
    renderTabB() {
        const {teams} = this.state;
        return (
            <div>
                <div className="card-fullgrid-container nopadding margin-top-20">
                    {teams.length > 0 ? (
                        <table>
                            <thead>
                                <tr>
                                    <th>Username</th>
                                    <th>Team ID</th>
                                    <th>Paid on</th>
                                    <th>Fantasy Points</th>
                                    <th>Rank</th>
                                </tr>
                            </thead>
                            <tbody>{this.displayTeams()}</tbody>
                        </table>
                    ) : (
                        ' No Data to Display'
                    )}
                </div>
            </div>
        );
    }

    /**
     * UI for tab C
     */
    renderTabC() {
        const {users} = this.state;

        return (
            <div className="card-fullgrid-container">
                <div className="margin-top-20">
                    {users.length > 0 ? (
                        <table>
                            <thead>
                                <tr>
                                    <th className="basic-headers">Username</th>
                                    <th className="basic-headers">#teams</th>
                                    <th className="basic-headers">Entry Fee</th>
                                </tr>
                            </thead>
                            <tbody>{this.dispUsers()}</tbody>
                        </table>
                    ) : (
                        ' No Data to Display'
                    )}
                </div>
            </div>
        );
    }

    /**
     * UI for tab D
     */
    renderTabD() {
        const {users} = this.state;

        return (
            <div className="card-fullgrid-container">
                <div className="margin-top-20">
                    {users.length > 0 ? (
                        <table>
                            <thead>
                                <tr>
                                    <th>Username</th>
                                    <th>#teams</th>
                                    <th># Winning Teams</th>
                                    <th>Entry Fee</th>
                                    <th>Winnings</th>
                                </tr>
                            </thead>
                            <tbody>{this.dispWinners()}</tbody>
                        </table>
                    ) : (
                        'No Data to Display'
                    )}
                </div>
            </div>
        );
    }

    render() {
        const {contest, history, login} = this.props;
        const {edit, errorMessage, disabled, loading, matchStatus, matchId, open, contestCreateConfirm} = this.state;
        const {contestWrite} = login?.permissions || {};
        let id = {};
        let title;

        if (edit) {
            const {match, status} = contest.editContest;
            id.match = match;
            id.contest = contest.editContest.id;
            if (status === 'AVAILABLE') {
                title = 'Edit Contest ';
            } else {
                title = 'Contest Details ';
            }
        } else {
            id.match = matchId;
            title = 'Add Contest ';
        }

        return (
            <DetailLayout
                location="addContest"
                history={history}
                centerTitle={title}
                onDeleteClick={!!contestWrite ? () => this.setState({open: true}) : undefined}
                onSave={!!contestWrite ? this.onSave.bind(this) : undefined}
                obPublish={this.onPublish}
                editPage={edit}
                id={id}
                hideAll={disabled}
            >
                {!!errorMessage && (
                    <Snackbar
                        open={!!errorMessage}
                        message={errorMessage}
                        onClose={() => this.setState({errorMessage: ''})}
                    />
                )}
                {!!loading && <LoadingModal open={loading} />}

                {!!contestCreateConfirm && (
                    <ConfirmationModal2
                        open={!!contestCreateConfirm}
                        handleClose={() => this.setState({contestCreateConfirm: false})}
                        confirmationText="Same configuration contest already available. Please confirm to continue if you want to proceed"
                        handleOnConfirm={this.onSave.bind(this)}
                    />
                )}

                <Modal open={open}>
                    <div className="container modal-top-margin">
                        <Card>
                            <div className="disp-flex">
                                <div className="modal-left-header">Delete Contest</div>
                                <div onClick={() => this.setState({open: false})}>
                                    <img src={Close} alt="" className="cursor" />
                                </div>
                            </div>
                            <div className="align-center equalcontainer-grids">
                                <div />
                                <div>
                                    <div className="align-center margin-top-30">
                                        <Button buttonText="Delete Contest" onClick={this.onDeleteContest.bind(this)} />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Modal>
                <div className="detail-container margin-top-20">
                    <div className="grid-item">
                        <div className="text-grey">Check for completeness before you save.</div>
                    </div>
                    <div className="grid-item">
                        <Card>
                            <div>
                                <div className="card-single-grid-container">
                                    <Tabs
                                        tab1Label={
                                            <div className="disp-flex">
                                                <img className="lockImg" src={Lock} alt="" />
                                                <div>Summary</div>
                                            </div>
                                        }
                                        tab2Label="Teams"
                                        tab3Label="Users"
                                        tab4Label="Winners"
                                        panelA={this.renderTabA.bind(this)}
                                        panelB={this.renderTabB.bind(this)}
                                        panelC={this.renderTabC.bind(this)}
                                        panelD={this.renderTabD.bind(this)}
                                        disableTab1={false}
                                        disableTab2={!matchStatus}
                                        disableTab3={!matchStatus}
                                        disableTab4={!matchStatus}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="grid-item">
                        <div className="equalcontainer-grids">
                            <div>
                                <div className="basic-headers">Last Updated</div>
                                <div className="basiic-data">{timeFormat(contest.editContest.updatedAt)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        squad: state.squad,
        player: state.player,
        contest: state.contest,
        contestType: state.contestType,
        match: state.match,
        user: state.user,
        dynamicPrizePools: state.dynamicPrizePools,
        client: state.client,
    };
};

export default connect(mapStateToProps, {
    getContestType,
    getContestTypeList,
    logout,
    getMatch,
    editMatch,
    getContest,
    createContest,
    updateContest,
    deleteContest,
    getAppUserList,
    getDynamicPrizePools,
})(AddContest);
