import {is} from 'date-fns/locale';
import {isUserAllowedAccess} from './HelperFunctions';

export const PERMISSION_CONST = {
    //users tab section permissions
    /*user*/
    USER_READONLY: 'USER_READONLY',
    USER_WRITE: 'USER_WRITE',
    /*userKyc*/
    USER_KYC_READONLY: 'USER_KYC_READONLY',
    USER_KYC_WRITE: 'USER_KYC_WRITE',
    /*rewardUser*/
    REWARD_USER: 'REWARD_USER',
    /*withdrawal*/
    WITHDRAWAL_READONLY: 'WITHDRAWAL_READONLY',
    WITHDRAWAL_WRITE: 'WITHDRAWAL_WRITE',
    /*referral*/
    REFERRAL_READONLY: 'REFERRAL_READONLY',
    REFERRAL_WRITE: 'REFERRAL_WRITE',
    /*discount*/
    DISCOUNT_READONLY: 'DISCOUNT_READONLY',
    DISCOUNT_WRITE: 'DISCOUNT_WRITE',
    /*notification*/
    NOTIFICATION_WRITE: 'NOTIFICATION_WRITE',
    /*TDS*/
    TDS_READONLY: 'TDS_READONLY',
    TDS_WRITE: 'TDS_WRITE',
    TDS_ADVANCE_WRITE: 'TDS_ADVANCE_WRITE',
    /*banners*/
    BANNER_READONLY: 'BANNER_READONLY',
    BANNER_WRITE: 'BANNER_WRITE',

    //Fantasy section apis permissions

    /*add tournament*/
    FC_TO_READONLY: 'FC_TO_READONLY',
    FC_TO_WRITE: 'FC_TO_WRITE',

    /*matches and verifyMatches*/
    MATCH_READONLY: 'MATCH_READONLY',
    MATCH_WRITE: 'MATCH_WRITE',
    MATCH_ADVANCE_WRITE: 'MATCH_ADVANCE_WRITE',
    UPLOAD_MATCH_RESULT: 'UPLOAD_MATCH_RESULT',
    VERIFY_MATCH_WRITE: 'VERIFY_MATCH_WRITE',
    VERIFY_MATCH_READONLY: 'VERIFY_MATCH_READONLY',
    /*players*/
    PLAYER_READONLY: 'PLAYER_READONLY',
    PLAYER_WRITE: 'PLAYER_WRITE',
    /*squad*/
    SQUAD_READONLY: 'SQUAD_READONLY',
    SQUAD_WRITE: 'SQUAD_WRITE',
    /*contest and contest Type*/
    CONTEST_READONLY: 'CONTEST_READONLY',
    CONTEST_WRITE: 'CONTEST_WRITE',

    /*fantasy scoring*/
    FANTASY_SCORING_READONLY: 'FANTASY_SCORING_READONLY',
    FANTASY_SCORING_WRITE: 'FANTASY_SCORING_WRITE',
    /*boosters*/
    BOOSTER_READONLY: 'BOOSTER_READONLY',
    BOOSTER_WRITE: 'BOOSTER_WRITE',

    /*teams*/
    TEAM_READONLY: 'TEAM_READONLY',

    //inshorts section
    ARTICLE_WRITE: 'ARTICLE_WRITE',

    //tournaments section permissions
    /*game*/
    TOGAME_READONLY: 'TOGAME_READONLY',
    TOGAME_WRITE: 'TOGAME_WRITE',

    /*Match Mode*/
    TOMATCH_MODE_READONLY: 'TOMATCH_MODE_READONLY',
    TOMATCH_MODE_WRITE: 'TOMATCH_MODE_WRITE',

    //TO matches
    TO_MATCH_READONLY: 'TO_MATCH_READONLY',
    TO_MATCH_WRITE: 'TO_MATCH_WRITE',

    /*matches and other section*/
    TOORGANIZER_READONLY: 'TOORGANIZER_READONLY',
    TOORGANIZER_WRITE: 'TOORGANIZER_WRITE',

    DYNAMIC_PRIZEPOOL_READONLY: 'DYNAMIC_PRIZEPOOL_READONLY',
    DYNAMIC_PRIZEPOOL_WRITE: 'DYNAMIC_PRIZEPOOL_WRITE',

    BACKFILL: 'BACKFILL',

    // primary navbar
    NAVBAR_CALENDER_DROP_DOWN: 'NAVBAR_CALENDER_DROP_DOWN',

    // matches
    MATCHES_CREATE_MATCHES_BUTTON: 'MATCHES_CREATE_MATCHES_BUTTON',
    MATCHES_MAP_NAME: 'MATCHES_MAP_NAME',
    MATCHES_MATCH_TYPE: ' MATCHES_MATCH_TYPE',
    MATCHES_CREATED_FROM: 'MATCHES_CREATED_FROM',
    // add edit match
    ADD_MATCH_COMMON_MATCH_ID: 'ADD_MATCH_COMMON_MATCH_ID',
    ADD_MATCH_GLACIER_CLASS_ID: 'ADD_MATCH_GLACIER_CLASS_ID',
    ADD_MATCH_SEARCH_BOOSTER_CONFIG: 'ADD_MATCH_SEARCH_BOOSTER_CONFIG',
    ADD_MATCH_SEARCH_MULTIPLIER_CONFIG: 'ADD_MATCH_SEARCH_MULTIPLIER_CONFIG',
    ADD_MATCH_GLACIER_ID: 'ADD_MATCH_GLACIER_ID',
    ADD_MATCH_SUBSTITUTION: 'ADD_MATCH_SUBSTITUTION',
    ADD_MATCH_STREAM_LINK: 'ADD_MATCH_STREAM_LINK',
    ADD_MATCH_FANTASY_TYPE: 'ADD_MATCH_FANTASY_TYPE',
    ADD_MATCH_ENABLE_VICE_CAPTIAN: 'ADD_MATCH_ENABLE_VICE_CAPTIAN',
    ADD_MATCH_EXPERT_ANALYSIS: 'ADD_MATCH_EXPERT_ANALYSIS',
    ADD_MATCH_MAP_NAME: 'ADD_MATCH_MAP_NAME',
    ADD_MATCH_RANK_FOR_SORTING: 'ADD_MATCH_RANK_FOR_SORTING',
    ADD_MATCH_GLACIER_SERIES: 'ADD_MATCH_GLACIER_SERIES',
    ADD_MATCH_UPLOAD_IMAGE: 'ADD_MATCH_UPLOAD_IMAGE',
    ADD_MATCH_MAKE_EASTER_MATCH: 'ADD_MATCH_MAKE_EASTER_MATCH',
    ADD_MATCH_MARK_COMING_SOON: 'ADD_MATCH_MARK_COMING_SOON',
    ADD_MATCH_MARK_DRAFT_TO_LIVE: 'ADD_MATCH_MARK_DRAFT_TO_LIVE',
    ADD_MATCH_ADD_SQUAD_BUTTON: 'ADD_MATCH_ADD_SQUAD_BUTTON',
    ADD_MATCH_ADD_SQUAD_AND_PLAYER_BUTTON: 'ADD_MATCH_ADD_SQUAD_AND_PLAYER_BUTTON',
    ADD_MATCH_SUBSTITUTE_PLAYER: 'ADD_MATCH_SUBSTITUTE_PLAYER',
    ADD_MATCH_ADD_PLAYER: 'ADD_MATCH_ADD_PLAYER',

    CONTEST_SLOT_FILL_RATE: 'CONTEST_SLOT_FILL_RATE',
    CONTEST_DISCOUNT_CODE: 'CONTEST_DISCOUNT_CODE',
    CONTEST_USER_TYPE: 'CONTEST_USER_TYPE',
    CONTEST_ADD_INFLUENCER: 'CONTEST_ADD_INFLUENCER',
    CONTEST_CONTEST_SPECIFIC_DISCOUNT: 'CONTEST_CONTEST_SPECIFIC_DISCOUNT',

    ADD_CONTEST_TYPE_ENTRY_TYPE_CASH_ONLY: 'ADD_CONTEST_TYPE_ENTRY_TYPE_CASH_ONLY',
    ADD_CONTEST_TYPE_USER_TYPE_ALL: 'ADD_CONTEST_TYPE_USER_TYPE_ALL',
    ADD_CONTEST_TYPE_SWITCH_TYPE_CONTEST: 'ADD_CONTEST_TYPE_SWITCH_TYPE_CONTEST',
    ADD_CONTEST_TYPE_TOTAL_SWITCH_ALLOWED: 'ADD_CONTEST_TYPE_TOTAL_SWITCH_ALLOWED',
    ADD_CONTEST_TYPE_SWITCH_FREE_TYPES: 'ADD_CONTEST_TYPE_SWITCH_FREE_TYPES',

    TEAM_FANTASY_POINT: 'TEAM_FANTASY_POINT',

    USER_WITHDRAWAL: 'USER_WITHDRAWAL',
    USER_CASH_BALANCE: 'USER_CASH_BALANCE',
    USER_TOTAL_BALANCE: 'USER_TOTAL_BALANCE',
    USER_FC_COIN: 'USER_FC_COIN',
    USER_KYC: 'USER_KYC',
    USER_REF_CODE: 'USER_REF_CODE',
    USER_VIDEO_LINK: 'USER_VIDEO_LINK',
    USER_USER_MODAL_PHONE_NUMBER: 'USER_USER_MODAL_PHONE_NUMBER',
    USER_USER_MODAL_VIDEO_LINK: 'USER_USER_MODAL_VIDEO_LINK',
    USER_MATCH_DETAILS_MAP_NAME: 'USER_MATCH_DETAILS_MAP_NAME',
    USER_ENTRY_FEES_PAID_VIA: 'USER_ENTRY_FEES_PAID_VIA',
    USER_ENTRY_FEES_INITIAL: 'USER_ENTRY_FEES_INITIAL',
    USER_WINNINGS_FC_ACCOUNT: 'USER_WINNINGS_FC_ACCOUNT',
    USER_WINNINGS_TAX: 'USER_WINNINGS_TAX',
    USER_WINNINGS_FINAL_WINNING: 'USER_WINNINGS_FINAL_WINNING',
    USER_TRANSACTION: 'USER_TRANSACTION',
    USER_TICKET: 'USER_TICKET',

    PLAYERS_FC_RATING: 'PLAYERS_FC_RATING',
    EDIT_PLAYER_FC_RATING: 'EDIT_PLAYER_FC_RATING',

    VARIFY_MATCH_MAP_NAME: 'VARIFY_MATCH_MAP_NAME',
    VERIFY_MATCH_CREATED_FROM: 'VERIFY_MATCH_CREATED_FROM',
    VERIFY_MATCH_MATCH_TYPE: 'VERIFY_MATCH_MATCH_TYPE',
    VERIFY_MATCH_UPLOAD_RESULTS: 'VERIFY_MATCH_UPLOAD_RESULTS',

    ADMIN: 'ADMIN',

    COMMON_MATCHES_READ_ONLY: 'COMMON_MATCHES_READ_ONLY',
    SWITCH_CONFIG_READ_ONLY: 'SWITCH_CONFIG_READ_ONLY',
    OTHER_UPLOAD_IMAGE_READ_ONLY: 'OTHER_UPLOAD_IMAGE_READ_ONLY',
    OTHER_SKELETON_READ_ONLY: 'OTHER_SKELETON_READ_ONLY',
    UPCOMING_MATCHES: 'UPCOMING_MATCHES',
};

export const handleAllPermissions = (userData = {}) => {
    const superAdmin = isUserAllowedAccess(userData);
    const admin = isUserAllowedAccess(userData, PERMISSION_CONST.ADMIN);
    const userReadOnly = isUserAllowedAccess(userData, PERMISSION_CONST.USER_READONLY);
    const userWrite = isUserAllowedAccess(userData, PERMISSION_CONST.USER_WRITE);
    const userKycReadOnly = isUserAllowedAccess(userData, PERMISSION_CONST.USER_KYC_READONLY);
    const userKycWrite = isUserAllowedAccess(userData, PERMISSION_CONST.USER_KYC_WRITE);
    const withdrawalReadOnly = isUserAllowedAccess(userData, PERMISSION_CONST.WITHDRAWAL_READONLY);
    const withdrawalWrite = isUserAllowedAccess(userData, PERMISSION_CONST.WITHDRAWAL_WRITE);
    const referralReadOnly = isUserAllowedAccess(userData, PERMISSION_CONST.REFERRAL_READONLY);
    const referralWrite = isUserAllowedAccess(userData, PERMISSION_CONST.REFERRAL_WRITE);
    const discountReadOnly = isUserAllowedAccess(userData, PERMISSION_CONST.DISCOUNT_READONLY);
    const discountWrite = isUserAllowedAccess(userData, PERMISSION_CONST.DISCOUNT_WRITE);
    const notificationWrite = isUserAllowedAccess(userData, PERMISSION_CONST.NOTIFICATION_WRITE);
    const tdsReadOnly = isUserAllowedAccess(userData, PERMISSION_CONST.TDS_READONLY);
    const tdsWrite = isUserAllowedAccess(userData, PERMISSION_CONST.TDS_WRITE);
    const tdsAdvanceWrite = isUserAllowedAccess(userData, PERMISSION_CONST.TDS_ADVANCE_WRITE);
    const rewardUserPermission = isUserAllowedAccess(userData, PERMISSION_CONST.REWARD_USER);
    const bannerReadOnly = isUserAllowedAccess(userData, PERMISSION_CONST.BANNER_READONLY);
    const bannerWrite = isUserAllowedAccess(userData, PERMISSION_CONST.BANNER_WRITE);

    const verifyMatchReadOnly = isUserAllowedAccess(userData, PERMISSION_CONST.VERIFY_MATCH_READONLY);
    const uploadMatchResultPermission = isUserAllowedAccess(userData, PERMISSION_CONST.UPLOAD_MATCH_RESULT);
    const verifyMatchWrite = isUserAllowedAccess(userData, PERMISSION_CONST.VERIFY_MATCH_WRITE);
    const playerReadOnly = isUserAllowedAccess(userData, PERMISSION_CONST.PLAYER_READONLY);
    const playerWrite = isUserAllowedAccess(userData, PERMISSION_CONST.PLAYER_WRITE);
    const squadReadOnly = isUserAllowedAccess(userData, PERMISSION_CONST.SQUAD_READONLY);
    const squadWrite = isUserAllowedAccess(userData, PERMISSION_CONST.SQUAD_WRITE);
    const matchReadOnly = true;
    const matchWrite = isUserAllowedAccess(userData, PERMISSION_CONST.MATCH_WRITE);
    const matchAdvanceWrite = isUserAllowedAccess(userData, PERMISSION_CONST.MATCH_ADVANCE_WRITE);
    const fcTournamentReadOnly = isUserAllowedAccess(userData, PERMISSION_CONST.FC_TO_READONLY);
    const fcTournamentWrite = isUserAllowedAccess(userData, PERMISSION_CONST.FC_TO_WRITE);
    const contestReadOnly = isUserAllowedAccess(userData, PERMISSION_CONST.CONTEST_READONLY);
    const contestWrite = isUserAllowedAccess(userData, PERMISSION_CONST.CONTEST_WRITE);
    const teamReadOnly = isUserAllowedAccess(userData, PERMISSION_CONST.TEAM_READONLY);
    const fantasyScoringReadOnly = isUserAllowedAccess(userData, PERMISSION_CONST.FANTASY_SCORING_READONLY);
    const fantasyScoringWrite = isUserAllowedAccess(userData, PERMISSION_CONST.FANTASY_SCORING_WRITE);
    const boosterReadOnly = isUserAllowedAccess(userData, PERMISSION_CONST.BOOSTER_READONLY);
    const boosterWrite = isUserAllowedAccess(userData, PERMISSION_CONST.BOOSTER_WRITE);
    const dynamicPrizePoolReadOnly = isUserAllowedAccess(userData, PERMISSION_CONST.DYNAMIC_PRIZEPOOL_READONLY);
    const dynamicPrizePoolWrite = isUserAllowedAccess(userData, PERMISSION_CONST.DYNAMIC_PRIZEPOOL_WRITE);

    const articleWrite = isUserAllowedAccess(userData, PERMISSION_CONST.ARTICLE_WRITE);

    const toGameReadOnly = isUserAllowedAccess(userData, PERMISSION_CONST.TOGAME_READONLY);
    const toGameWrite = isUserAllowedAccess(userData, PERMISSION_CONST.TOGAME_WRITE);
    const toMatchModeReadOnly = isUserAllowedAccess(userData, PERMISSION_CONST.TOMATCH_MODE_READONLY);
    const toMatchModeWrite = isUserAllowedAccess(userData, PERMISSION_CONST.TOMATCH_MODE_WRITE);
    const toMatchReadOnly = isUserAllowedAccess(userData, PERMISSION_CONST.TO_MATCH_READONLY);
    const toMatchWrite = isUserAllowedAccess(userData, PERMISSION_CONST.TO_MATCH_WRITE);
    const toOrganizerReadOnly = isUserAllowedAccess(userData, PERMISSION_CONST.TOORGANIZER_READONLY);
    const toOrganizerWrite = isUserAllowedAccess(userData, PERMISSION_CONST.TOORGANIZER_WRITE);

    const navbarCalenderDropdown = isUserAllowedAccess(userData, PERMISSION_CONST.NAVBAR_CALENDER_DROP_DOWN);

    const matchesCreateMatchesButton = isUserAllowedAccess(userData, PERMISSION_CONST.MATCHES_CREATE_MATCHES_BUTTON);
    const matchesMapName = isUserAllowedAccess(userData, PERMISSION_CONST.MATCHES_MAP_NAME);
    const matchesMatchType = isUserAllowedAccess(userData, PERMISSION_CONST.MATCHES_MATCH_TYPE);
    const matchesCreatedFrom = isUserAllowedAccess(userData, PERMISSION_CONST.MATCHES_CREATED_FROM);

    const addMatchCommonMatchId = isUserAllowedAccess(userData, PERMISSION_CONST.ADD_MATCH_COMMON_MATCH_ID);
    const addMatchGlacierClassId = isUserAllowedAccess(userData, PERMISSION_CONST.ADD_MATCH_GLACIER_CLASS_ID);
    const addMatchSearchBoosterConfig = isUserAllowedAccess(userData, PERMISSION_CONST.ADD_MATCH_SEARCH_BOOSTER_CONFIG);
    const addMatchSearchMultiplierConfig = isUserAllowedAccess(
        userData,
        PERMISSION_CONST.ADD_MATCH_SEARCH_MULTIPLIER_CONFIG,
    );
    const addMatchGlacierId = isUserAllowedAccess(userData, PERMISSION_CONST.ADD_MATCH_GLACIER_ID);
    const addMatchSubstitution = isUserAllowedAccess(userData, PERMISSION_CONST.ADD_MATCH_SUBSTITUTION);
    const addMatchStreamLink = isUserAllowedAccess(userData, PERMISSION_CONST.ADD_MATCH_STREAM_LINK);
    const addMatchFantasyType = isUserAllowedAccess(userData, PERMISSION_CONST.ADD_MATCH_FANTASY_TYPE);
    const addMatchEnableViceCaptian = isUserAllowedAccess(userData, PERMISSION_CONST.ADD_MATCH_ENABLE_VICE_CAPTIAN);
    const addMatchExpertAnalysis = isUserAllowedAccess(userData, PERMISSION_CONST.ADD_MATCH_EXPERT_ANALYSIS);
    const addMatchMapName = isUserAllowedAccess(userData, PERMISSION_CONST.ADD_MATCH_MAP_NAME);
    const addMatchRankForSorting = isUserAllowedAccess(userData, PERMISSION_CONST.ADD_MATCH_RANK_FOR_SORTING);
    const addMatchGlacierSeries = isUserAllowedAccess(userData, PERMISSION_CONST.ADD_MATCH_GLACIER_SERIES);
    const addMatchUploadImage = isUserAllowedAccess(userData, PERMISSION_CONST.ADD_MATCH_UPLOAD_IMAGE);
    const addMatchMakeEasterMatch = isUserAllowedAccess(userData, PERMISSION_CONST.ADD_MATCH_MAKE_EASTER_MATCH);
    const addMatchMarkComingSoon = isUserAllowedAccess(userData, PERMISSION_CONST.ADD_MATCH_MARK_COMING_SOON);
    const addMatchMarkDraftToLive = isUserAllowedAccess(userData, PERMISSION_CONST.ADD_MATCH_MARK_DRAFT_TO_LIVE);
    const addMatchAddSquadBtn = isUserAllowedAccess(userData, PERMISSION_CONST.ADD_MATCH_ADD_SQUAD_BUTTON);
    const addMatchAddSquadAndPlayerBtn = isUserAllowedAccess(
        userData,
        PERMISSION_CONST.ADD_MATCH_ADD_SQUAD_AND_PLAYER_BUTTON,
    );
    const addMatchSubstitutePlayer = isUserAllowedAccess(userData, PERMISSION_CONST.ADD_MATCH_SUBSTITUTE_PLAYER);
    const addMatchAddPlayer = isUserAllowedAccess(userData, PERMISSION_CONST.ADD_MATCH_ADD_PLAYER);

    const contestSlotFillRate = isUserAllowedAccess(userData, PERMISSION_CONST.CONTEST_SLOT_FILL_RATE);
    const contestDiscountCode = isUserAllowedAccess(userData, PERMISSION_CONST.CONTEST_DISCOUNT_CODE);
    const contestUserType = isUserAllowedAccess(userData, PERMISSION_CONST.CONTEST_USER_TYPE);
    const contestAddInfluencer = isUserAllowedAccess(userData, PERMISSION_CONST.CONTEST_ADD_INFLUENCER);
    const contestContestSpecificDiscount = isUserAllowedAccess(
        userData,
        PERMISSION_CONST.CONTEST_CONTEST_SPECIFIC_DISCOUNT,
    );

    const addContestTypeEntryTypeOptionCashOnly = isUserAllowedAccess(
        userData,
        PERMISSION_CONST.ADD_CONTEST_TYPE_ENTRY_TYPE_CASH_ONLY,
    );
    const addContestTypeUserTypeOptionAll = isUserAllowedAccess(
        userData,
        PERMISSION_CONST.ADD_CONTEST_TYPE_USER_TYPE_ALL,
    );
    const addContestTypeSwitchTypeContest = isUserAllowedAccess(
        userData,
        PERMISSION_CONST.ADD_CONTEST_TYPE_SWITCH_TYPE_CONTEST,
    );
    const addContestTypeTotalSwitchAllowed = isUserAllowedAccess(
        userData,
        PERMISSION_CONST.ADD_CONTEST_TYPE_TOTAL_SWITCH_ALLOWED,
    );
    const addContestTypeSwitchFreeTypes = isUserAllowedAccess(
        userData,
        PERMISSION_CONST.ADD_CONTEST_TYPE_SWITCH_FREE_TYPES,
    );

    const teamFantasyPoint = isUserAllowedAccess(userData, PERMISSION_CONST.TEAM_FANTASY_POINT);

    const userWithdrawal = isUserAllowedAccess(userData, PERMISSION_CONST.USER_WITHDRAWAL);
    const userCashBalance = isUserAllowedAccess(userData, PERMISSION_CONST.USER_CASH_BALANCE);
    const userTotalBalance = isUserAllowedAccess(userData, PERMISSION_CONST.USER_TOTAL_BALANCE);
    const userFcCoin = isUserAllowedAccess(userData, PERMISSION_CONST.USER_FC_COIN);
    const userKyc = isUserAllowedAccess(userData, PERMISSION_CONST.USER_KYC);
    const userRefCode = isUserAllowedAccess(userData, PERMISSION_CONST.USER_REF_CODE);
    const userVideoLink = isUserAllowedAccess(userData, PERMISSION_CONST.USER_VIDEO_LINK);
    const userUserModalPhoneNumber = isUserAllowedAccess(userData, PERMISSION_CONST.USER_USER_MODAL_PHONE_NUMBER);
    const userUserModalVideoLink = isUserAllowedAccess(userData, PERMISSION_CONST.USER_USER_MODAL_VIDEO_LINK);
    const userMatchDetailsMapName = isUserAllowedAccess(userData, PERMISSION_CONST.USER_MATCH_DETAILS_MAP_NAME);
    const userEntryFeesPaidVia = isUserAllowedAccess(userData, PERMISSION_CONST.USER_ENTRY_FEES_PAID_VIA);
    const userEntryFeesInitial = isUserAllowedAccess(userData, PERMISSION_CONST.USER_ENTRY_FEES_INITIAL);
    const userWinningsFcAccount = isUserAllowedAccess(userData, PERMISSION_CONST.USER_WINNINGS_FC_ACCOUNT);
    const userWinningsTax = isUserAllowedAccess(userData, PERMISSION_CONST.USER_WINNINGS_TAX);
    const userWinningsFinalWinning = isUserAllowedAccess(userData, PERMISSION_CONST.USER_WINNINGS_FINAL_WINNING);
    const userTransaction = isUserAllowedAccess(userData, PERMISSION_CONST.USER_TRANSACTION);
    const userTicket = isUserAllowedAccess(userData, PERMISSION_CONST.USER_TICKET);

    const playersFcRating = isUserAllowedAccess(userData, PERMISSION_CONST.PLAYERS_FC_RATING);
    const editPlayerFcRating = isUserAllowedAccess(userData, PERMISSION_CONST.EDIT_PLAYER_FC_RATING);

    const varifyMatchMapName = isUserAllowedAccess(userData, PERMISSION_CONST.VARIFY_MATCH_MAP_NAME);
    const verifyMatchCreatedFrom = isUserAllowedAccess(userData, PERMISSION_CONST.VERIFY_MATCH_CREATED_FROM);
    const verifyMatchMatchType = isUserAllowedAccess(userData, PERMISSION_CONST.VERIFY_MATCH_MATCH_TYPE);
    const verifyMatchUploadResults = isUserAllowedAccess(userData, PERMISSION_CONST.VERIFY_MATCH_UPLOAD_RESULTS);

    const commonMatchesReadOnly = isUserAllowedAccess(userData, PERMISSION_CONST.COMMON_MATCHES_READ_ONLY);

    const switchConfigReadOnly = isUserAllowedAccess(userData, PERMISSION_CONST.SWITCH_CONFIG_READ_ONLY);
    // const switchConfigWrite = isUserAllowedAccess(userData, PERMISSION_CONST.SWITCH_CONFIG_WRITE);

    const otherUploadImageReadOnly = isUserAllowedAccess(userData, PERMISSION_CONST.OTHER_UPLOAD_IMAGE_READ_ONLY);
    const otherSkeletonReadOnly = isUserAllowedAccess(userData, PERMISSION_CONST.OTHER_SKELETON_READ_ONLY);

    const upcomingMatches = isUserAllowedAccess(userData, PERMISSION_CONST.UPCOMING_MATCHES);

    const backfill = isUserAllowedAccess(userData, PERMISSION_CONST.BACKFILL);
    return {
        superAdmin,
        admin,
        userReadOnly,
        userWrite,
        userKycReadOnly,
        userKycWrite,
        withdrawalReadOnly,
        withdrawalWrite,
        referralReadOnly,
        referralWrite,
        discountReadOnly,
        discountWrite,
        notificationWrite,
        tdsReadOnly,
        tdsWrite,
        tdsAdvanceWrite,
        rewardUserPermission,
        bannerReadOnly,
        bannerWrite,
        verifyMatchReadOnly,
        uploadMatchResultPermission,
        verifyMatchWrite,
        playerReadOnly,
        playerWrite,
        squadReadOnly,
        squadWrite,
        matchReadOnly,
        matchWrite,
        matchAdvanceWrite,
        fcTournamentReadOnly,
        fcTournamentWrite,
        contestReadOnly,
        contestWrite,
        teamReadOnly,
        fantasyScoringReadOnly,
        fantasyScoringWrite,
        boosterReadOnly,
        boosterWrite,
        articleWrite,
        toGameReadOnly,
        toGameWrite,
        toMatchModeReadOnly,
        toMatchModeWrite,
        toMatchReadOnly,
        toMatchWrite,
        toOrganizerReadOnly,
        toOrganizerWrite,
        dynamicPrizePoolReadOnly,
        dynamicPrizePoolWrite,
        navbarCalenderDropdown,
        matchesCreateMatchesButton,
        matchesMapName,
        matchesMatchType,
        matchesCreatedFrom,
        addMatchCommonMatchId,
        addMatchGlacierClassId,
        addMatchSearchBoosterConfig,
        addMatchSearchMultiplierConfig,
        addMatchGlacierId,
        addMatchSubstitution,
        addMatchStreamLink,
        addMatchFantasyType,
        addMatchEnableViceCaptian,
        addMatchExpertAnalysis,
        addMatchMapName,
        addMatchRankForSorting,
        addMatchGlacierSeries,
        addMatchUploadImage,
        addMatchMakeEasterMatch,
        addMatchMarkComingSoon,
        addMatchMarkDraftToLive,
        addMatchAddSquadBtn,
        addMatchSubstitutePlayer,
        addMatchAddPlayer,
        addMatchAddSquadAndPlayerBtn,
        contestSlotFillRate,
        contestDiscountCode,
        contestUserType,
        contestAddInfluencer,
        contestContestSpecificDiscount,
        addContestTypeEntryTypeOptionCashOnly,
        addContestTypeUserTypeOptionAll,
        addContestTypeSwitchTypeContest,
        addContestTypeTotalSwitchAllowed,
        addContestTypeSwitchFreeTypes,
        teamFantasyPoint,
        userWithdrawal,
        userCashBalance,
        userTotalBalance,
        userFcCoin,
        userKyc,
        userRefCode,
        userVideoLink,
        userUserModalPhoneNumber,
        userUserModalVideoLink,
        userMatchDetailsMapName,
        userEntryFeesPaidVia,
        userEntryFeesInitial,
        userWinningsFcAccount,
        userWinningsTax,
        userWinningsFinalWinning,
        userTransaction, 
        userTicket,
        playersFcRating,
        editPlayerFcRating,
        varifyMatchMapName,
        verifyMatchCreatedFrom,
        verifyMatchMatchType,
        verifyMatchUploadResults,
        commonMatchesReadOnly,
        switchConfigReadOnly,
        // switchConfigWrite,
        otherUploadImageReadOnly,
        otherSkeletonReadOnly,
        upcomingMatches,
        backfill,
    };
};
