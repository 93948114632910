import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import {connect} from 'react-redux';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Card from '../../../Components/Card/Card';
import Input from '../../../Components/Input/Input';
import DropDown from '../../../Components/Dropdown/Dropdown';
import Button from '../../../Components/Button/Button';
import {addAdmin, fetchAdminUser, updateAdminUser} from './AxioCalls';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import {logout} from '../Login/LoginAction';
import {isValidEmail, isValidString} from '../../../Utilities/Helpers';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import {Checkbox, FormControlLabel} from '@material-ui/core';
import {PERMISSION_CONST} from '../../../Utilities/Permission';
import {CLASSIC_ALLOWED_PERMISSIONS,TIME_ZONE_OPTIONS} from '../../../Utilities/Constants';

const {
    USER_READONLY,
    USER_WRITE,
    ARTICLE_WRITE,
    BANNER_READONLY,
    BANNER_WRITE,
    BOOSTER_READONLY,
    BOOSTER_WRITE,
    CONTEST_READONLY,
    DISCOUNT_READONLY,
    CONTEST_WRITE,
    DISCOUNT_WRITE,
    FANTASY_SCORING_READONLY,
    FANTASY_SCORING_WRITE,
    FC_TO_READONLY,
    FC_TO_WRITE,
    MATCH_ADVANCE_WRITE,
    MATCH_READONLY,
    MATCH_WRITE,
    NOTIFICATION_WRITE,
    PLAYER_READONLY,
    PLAYER_WRITE,
    REFERRAL_READONLY,
    REFERRAL_WRITE,
    REWARD_USER,
    SQUAD_READONLY,
    SQUAD_WRITE,
    TDS_READONLY,
    TDS_WRITE,
    TDS_ADVANCE_WRITE,
    TEAM_READONLY,
    TO_MATCH_READONLY,
    TO_MATCH_WRITE,
    TOGAME_READONLY,
    TOGAME_WRITE,
    TOMATCH_MODE_READONLY,
    TOMATCH_MODE_WRITE,
    TOORGANIZER_READONLY,
    TOORGANIZER_WRITE,
    UPLOAD_MATCH_RESULT,
    USER_KYC_READONLY,
    USER_KYC_WRITE,
    VERIFY_MATCH_READONLY,
    VERIFY_MATCH_WRITE,
    WITHDRAWAL_READONLY,
    WITHDRAWAL_WRITE,
    BACKFILL,
    DYNAMIC_PRIZEPOOL_READONLY,
    DYNAMIC_PRIZEPOOL_WRITE,
    NAVBAR_CALENDER_DROP_DOWN,
    MATCHES_CREATE_MATCHES_BUTTON,
    MATCHES_MAP_NAME,
    MATCHES_MATCH_TYPE,
    MATCHES_CREATED_FROM,
    ADD_MATCH_COMMON_MATCH_ID,
    ADD_MATCH_GLACIER_CLASS_ID,
    ADD_MATCH_SEARCH_BOOSTER_CONFIG,
    ADD_MATCH_SEARCH_MULTIPLIER_CONFIG,
    ADD_MATCH_GLACIER_ID,
    ADD_MATCH_SUBSTITUTION,
    ADD_MATCH_STREAM_LINK,
    ADD_MATCH_EXPERT_ANALYSIS,
    ADD_MATCH_FANTASY_TYPE,
    ADD_MATCH_ENABLE_VICE_CAPTIAN,
    ADD_MATCH_MAP_NAME,
    ADD_MATCH_RANK_FOR_SORTING,
    ADD_MATCH_GLACIER_SERIES,
    ADD_MATCH_UPLOAD_IMAGE,
    ADD_MATCH_MAKE_EASTER_MATCH,
    ADD_MATCH_MARK_COMING_SOON,
    ADD_MATCH_MARK_DRAFT_TO_LIVE,
    ADD_MATCH_ADD_SQUAD_BUTTON,
    ADD_MATCH_ADD_SQUAD_AND_PLAYER_BUTTON,
    ADD_MATCH_ADD_PLAYER,
    ADD_MATCH_SUBSTITUTE_PLAYER,
    CONTEST_SLOT_FILL_RATE,
    CONTEST_DISCOUNT_CODE,
    CONTEST_USER_TYPE,
    CONTEST_ADD_INFLUENCER,
    CONTEST_CONTEST_SPECIFIC_DISCOUNT,
    ADD_CONTEST_TYPE_ENTRY_TYPE_CASH_ONLY,
    ADD_CONTEST_TYPE_USER_TYPE_ALL,
    ADD_CONTEST_TYPE_SWITCH_TYPE_CONTEST,
    ADD_CONTEST_TYPE_TOTAL_SWITCH_ALLOWED,
    ADD_CONTEST_TYPE_SWITCH_FREE_TYPES,
    TEAM_FANTASY_POINT,
    USER_WITHDRAWAL,
    USER_CASH_BALANCE,
    USER_TOTAL_BALANCE,
    USER_FC_COIN,
    USER_KYC,
    USER_REF_CODE,
    USER_VIDEO_LINK,
    USER_USER_MODAL_PHONE_NUMBER,
    USER_USER_MODAL_VIDEO_LINK,
    USER_MATCH_DETAILS_MAP_NAME,
    USER_ENTRY_FEES_PAID_VIA,
    USER_ENTRY_FEES_INITIAL,
    USER_WINNINGS_FC_ACCOUNT,
    USER_WINNINGS_TAX,
    USER_WINNINGS_FINAL_WINNING,
    USER_TRANSACTION,
    USER_TICKET,
    PLAYERS_FC_RATING,
    EDIT_PLAYER_FC_RATING,
    VARIFY_MATCH_MAP_NAME,
    VERIFY_MATCH_CREATED_FROM,
    VERIFY_MATCH_MATCH_TYPE,
    VERIFY_MATCH_UPLOAD_RESULTS,
    COMMON_MATCHES_READ_ONLY,
    SWITCH_CONFIG_READ_ONLY,
    // SWITCH_CONFIG_WRITE,
    OTHER_UPLOAD_IMAGE_READ_ONLY,
    OTHER_SKELETON_READ_ONLY,
    UPCOMING_MATCHES,
} = PERMISSION_CONST;

class AddDiscountCode extends React.Component {
    constructor(props) {
        super(props);
        let location = this.props.history.location.pathname.includes('edit');
        this.state = {
            menuItems: [
                {value: 'ACTIVE', text: 'ACTIVE'},
                {value: 'INACTIVE', text: 'INACTIVE'},
            ],
            fullName: null,
            email: null,
            accessLevel: new Set([]),
            pageSize: 10,
            page: 1,
            edit: location,
            loading: false,
            timezone: null,
            isClassicEnabled: null,
            isSwitchAllowed: null,
            isMultiMatchAllowed: null,
        };
    }

    /**
     * function to save entered data in state
     * @param {string} field field names from the form
     * @param {*} value value in the field
     */
    handleInputChange(field, value) {
        this.setState({[field]: value, errorMessage: ''});
    }

    componentDidMount() {
        const {login, history, match, client} = this.props;
        if (!login?.userDetails?.data) {
            history.push('/');
            this.props.logout();
            return;
        }

        if (!!this.state.edit) {
            this.props.fetchAdminUser(
                {id: match?.params?.id},
                login?.userDetails?.data?.accessToken,
                client.selectedClient,
            );
        }
        const isNewClassicEnabled = (client.selectedClientGameList??[]).some((game) =>
            game.activeFantasyType.includes('NORMAL'),
        );

        // const isNewSwitchAllowed = client.selectedClientGameList.some((game) =>
        //     game.activeFantasyType.includes('SWITCH'),
        // );

        // const isNewMultiMatchAllowed = client.selectedClientGameList.some((game) =>
        //     game.allowedMatchStructure.includes('MULTI_MATCH'),
        // );
        this.setState(
            {
                isClassicEnabled: isNewClassicEnabled,
                // isSwitchAllowed: isNewSwitchAllowed,
                // isMultiMatchAllowed: isNewMultiMatchAllowed,
            }
        );
    }

    /**
     *checks inputs and makes an api call on click of send invite button
     */
    onSubmit() {
        const {fullName, email, status, accessLevel, edit, timezone} = this.state;
        const {login, match, client} = this.props;

        if (!isValidEmail(email) || email === null || email === '') {
            this.setState({errorMessage: 'please enter valid email'});
        } else if (fullName === undefined || fullName === null || fullName === '' || !isValidString(fullName)) {
            this.setState({errorMessage: 'please enter valid Name'});
        } else if (status === null || status === undefined) {
            this.setState({errorMessage: 'please select a status'});
        } else if (timezone === null || timezone === undefined) {
            this.setState({errorMessage: 'please select default timezone'});
        } else {
            let admin = {email, fullName, status, timezone, accessLevel: [...(accessLevel || [])]};
            if (!!edit) {
                admin.adminUserId = match?.params?.id;
                this.props.updateAdminUser(admin, login.userDetails.data.accessToken, client.selectedClient);
            } else {
                this.props.addAdmin(admin, login.userDetails.data.accessToken, client.selectedClient);
            }
        }
    }

    componentDidUpdate(prevProps) {
        const {admin, history} = this.props;
        if (
            (admin.addAdminRequest && !prevProps.admin.addAdminRequest) ||
            (admin.isFetchingAdminUser && !prevProps.admin.isFetchingAdminUser) ||
            (admin.isUpdatingAdminUser && !prevProps.admin.isUpdatingAdminUser)
        ) {
            this.setState({loading: true});
        }
        if (admin.isUpdatingAdminUserSuccess && !prevProps.admin.isUpdatingAdminUserSuccess) {
            this.setState({errorMessage: admin?.errorMessage?.msg || 'Successfully Updated', loading: false});
        }

        if (admin.addAdminSuccess && !prevProps.admin.addAdminSuccess) {
            this.setState({loading: false});
            history.push('/admins');
        }

        if (admin.isFetchingAdminUserSuccess && !prevProps.admin.isFetchingAdminUserSuccess) {
            const {email, fullName, status, accessLevel = [], timezone} = admin?.adminUserDetail?.data || {};
            this.setState({
                email,
                fullName,
                status,
                timezone,
                accessLevel: new Set(accessLevel || []),
                loading: false,
            });
        }

        if (
            (admin.isUpdatingAdminUserFail && !prevProps.admin.isUpdatingAdminUserFail) ||
            (admin.isFetchingAdminUserFail && !prevProps.admin.isFetchingAdminUserFail) ||
            (admin.addAdminFail && !prevProps.admin.addAdminFail)
        ) {
            this.setState({errorMessage: admin?.errorMessage?.msg, loading: false});
        }
    }

    handleChangePermissions = (permission = '', additional = []) => {
        let {accessLevel} = this.state;
        if (accessLevel.has(permission)) accessLevel.delete(permission);
        else {
            accessLevel = new Set([...accessLevel, permission, ...additional]);
        }
        this.setState({accessLevel});
    };

    handleClickAllReadyOnlyPermissions = () => {
        const {accessLevel} = this.state;
        const readOnlyPer = Object.values(PERMISSION_CONST).filter((per) => (per || '').includes('_READONLY'));
        this.setState({accessLevel: new Set([...accessLevel, ...readOnlyPer])});
    };

    handleStandardAccessPermission = () => {
        const { isClassicEnabled, isSwitchAllowed, isMultiMatchAllowed} = this.state;
        const updatedAccessLevel = new Set([]);

        if (isClassicEnabled) {
            CLASSIC_ALLOWED_PERMISSIONS.forEach((permission) => updatedAccessLevel.add(permission));
        }
        // if (isSwitchAllowed) {
        //     updatedAccessLevel.add('SWITCH_CONFIG_READ_ONLY');
        //     updatedAccessLevel.add('SWITCH_CONFIG_WRITE');
        // }
        // if (isMultiMatchAllowed) {
        //     updatedAccessLevel.add('MATCHES_CREATE_MULTI_MATCHES_BUTTON');
        // }

        this.setState({accessLevel: updatedAccessLevel});
        this.setState({errorMessage: 'Standard Access Selected'})
    };

    handleRemoveAllPermissions = () => {
        this.setState({accessLevel: new Set([])});
    };

    render() {
        const {menuItems, fullName, status, email, edit, errorMessage, loading, accessLevel, timezone} = this.state;
        const {
            userReadOnly,
            userWrite,
            userKycReadOnly,
            userKycWrite,
            withdrawalReadOnly,
            withdrawalWrite,
            referralReadOnly,
            referralWrite,
            discountReadOnly,
            discountWrite,
            notificationWrite,
            tdsReadOnly,
            tdsWrite,
            tdsAdvanceWrite,
            rewardUserPermission,
            bannerReadOnly,
            bannerWrite,
            verifyMatchReadOnly,
            uploadMatchResultPermission,
            verifyMatchWrite,
            playerReadOnly,
            playerWrite,
            squadReadOnly,
            squadWrite,
            matchReadOnly,
            matchWrite,
            matchAdvanceWrite,
            fcTournamentReadOnly,
            fcTournamentWrite,
            contestReadOnly,
            contestWrite,
            teamReadOnly,
            fantasyScoringReadOnly,
            fantasyScoringWrite,
            boosterReadOnly,
            boosterWrite,
            articleWrite,
            toGameReadOnly,
            toGameWrite,
            toMatchModeReadOnly,
            toMatchModeWrite,
            toMatchReadOnly,
            toMatchWrite,
            toOrganizerReadOnly,
            toOrganizerWrite,
            dynamicPrizePoolReadOnly,
            dynamicPrizePoolWrite,
            navbarCalenderDropdown,
            matchesCreateMatchesButton,
            matchesMapName,
            matchesMatchType,
            matchesCreatedFrom,
            addMatchCommonMatchId,
            addMatchGlacierClassId,
            addMatchSearchBoosterConfig,
            addMatchSearchMultiplierConfig,
            addMatchGlacierId,
            addMatchSubstitution,
            addMatchStreamLink,
            addMatchFantasyType,
            addMatchEnableViceCaptian,
            addMatchExpertAnalysis,
            addMatchMapName,
            addMatchRankForSorting,
            addMatchGlacierSeries,
            addMatchUploadImage,
            addMatchMakeEasterMatch,
            addMatchMarkComingSoon,
            addMatchMarkDraftToLive,
            addMatchAddSquadBtn,
            addMatchAddSquadAndPlayerBtn,
            addMatchSubstitutePlayer,
            addMatchAddPlayer,
            contestSlotFillRate,
            contestDiscountCode,
            contestUserType,
            contestAddInfluencer,
            contestContestSpecificDiscount,
            addContestTypeEntryTypeOptionCashOnly,
            addContestTypeUserTypeOptionAll,
            addContestTypeSwitchTypeContest,
            addContestTypeTotalSwitchAllowed,
            addContestTypeSwitchFreeTypes,
            teamFantasyPoint,
            userWithdrawal,
            userCashBalance,
            userTotalBalance,
            userFcCoin,
            userKyc,
            userRefCode,
            userVideoLink,
            userUserModalPhoneNumber,
            userUserModalVideoLink,
            userMatchDetailsMapName,
            userEntryFeesPaidVia,
            userEntryFeesInitial,
            userWinningsFcAccount,
            userWinningsTax,
            userWinningsFinalWinning,
            userTransaction,
            userTicket,
            playersFcRating,
            editPlayerFcRating,
            varifyMatchMapName,
            verifyMatchCreatedFrom,
            verifyMatchMatchType,
            verifyMatchUploadResults,
            commonMatchesReadOnly,
            switchConfigReadOnly,
            // switchConfigWrite,
            otherUploadImageReadOnly,
            otherSkeletonReadOnly,
            upcomingMatches,
            backfill,
        } = this.props.login.permissions || {};
        const {history} = this.props;
        let title = edit ? 'Edit Admin' : 'Add  Admin';
        return (
            <DetailLayout location="addAdmin" history={history} centerTitle={title}>
                {!!errorMessage && (
                    <Snackbar
                        open={!!errorMessage}
                        message={errorMessage}
                        onClose={() => this.setState({errorMessage: ''})}
                    />
                )}
                {!!loading && <LoadingModal open={!!loading} />}
                <div className="detail-container margin-top-20">
                    <div className="grid-item" />
                    <div className="grid-item">
                        <Card>
                            <div>
                                <div className="card-grid-container">
                                    {/* row 1 */}
                                    <div className="grid-item  margin-top-20">
                                        <Input
                                            label="Full Name"
                                            type="text"
                                            name="fullName"
                                            value={fullName || ''}
                                            placeholder="Enter Full Name"
                                            onChange={this.handleInputChange.bind(this, 'fullName')}
                                        />
                                    </div>
                                    <div className="grid-item margin-top-20 align-right">
                                        <Button
                                            buttonText={edit ? 'Save' : 'Send Invite'}
                                            onClick={this.onSubmit.bind(this)}
                                        />
                                    </div>
                                    {/* row 3 */}
                                    <div className="grid-item  margin-top-20">
                                        <Input
                                            label="Email Address"
                                            type="email"
                                            name="email"
                                            value={email || ''}
                                            onChange={this.handleInputChange.bind(this, 'email')}
                                            placeholder="Enter Email Address"
                                            disabled={!!edit}
                                        />
                                    </div>
                                    <div className="grid-item  margin-top-20">
                                        <DropDown
                                            menuItems={menuItems}
                                            label="Status"
                                            value={status || ''}
                                            placeholder="select"
                                            onChange={this.handleInputChange.bind(this, 'status')}
                                        />
                                    </div>
                                    <div className="grid-item  margin-top-20">
                                        <DropDown
                                            menuItems={TIME_ZONE_OPTIONS}
                                            label="Time Zone"
                                            value={timezone || ''}
                                            placeholder="select timezone"
                                            onChange={this.handleInputChange.bind(this, 'timezone')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="grid-item" />
                </div>
                <div>
                    <Card>
                        <h1 className="text-center mt-0 mb-15">Permissions</h1>
                        <div className="mb-15">
                            <Button
                                className="mr-10"
                                buttonText="Give Standard Access"
                                onClick={this.handleStandardAccessPermission}
                            />
                            <Button buttonText="Remove All permissions" onClick={this.handleRemoveAllPermissions} />
                        </div>

                        {(!!userReadOnly || !!userWrite) && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li>
                                    <h3 className="my-0">Users</h3>
                                </li>
                                {!!userReadOnly && (
                                    <li>
                                        <FormControlLabel
                                            label="Show Users read only"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(USER_READONLY)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(USER_READONLY)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!userWrite && (
                                    <li>
                                        <FormControlLabel
                                            label="Users action permission"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(USER_WRITE)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(USER_WRITE, [USER_READONLY])
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {(!!userWithdrawal ||
                            !!userCashBalance ||
                            !!userTotalBalance ||
                            !!userFcCoin ||
                            !!userKyc ||
                            !!userRefCode ||
                            !!userVideoLink ||
                            !!userTransaction ||
                            !!userTicket) && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li style={{paddingLeft: '1rem', color: '#A5a5a5'}}>Users column</li>
                                {!!userWithdrawal && (
                                    <li>
                                        <FormControlLabel
                                            label="Withdrawal"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(USER_WITHDRAWAL)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(USER_WITHDRAWAL)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!userCashBalance && (
                                    <li>
                                        <FormControlLabel
                                            label="Cash balance"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(USER_CASH_BALANCE)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(USER_CASH_BALANCE)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!userTotalBalance && (
                                    <li>
                                        <FormControlLabel
                                            label="Total balance"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(USER_TOTAL_BALANCE)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(USER_TOTAL_BALANCE)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                <li></li>
                                {!!userFcCoin && (
                                    <li>
                                        <FormControlLabel
                                            label="Fc coin"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(USER_FC_COIN)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(USER_FC_COIN)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!userKyc && (
                                    <li>
                                        <FormControlLabel
                                            label="KYC"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(USER_KYC)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(USER_KYC)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!userRefCode && (
                                    <li>
                                        <FormControlLabel
                                            label="Ref code"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(USER_REF_CODE)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(USER_REF_CODE)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                <li></li>
                                {!!userVideoLink && (
                                    <li>
                                        <FormControlLabel
                                            label="Video link"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(USER_VIDEO_LINK)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(USER_VIDEO_LINK)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                 {!!userTransaction && (
                                    <li>
                                        <FormControlLabel
                                            label="User Transaction"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(USER_TRANSACTION)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(USER_TRANSACTION)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!userTicket && (
                                    <li>
                                        <FormControlLabel
                                            label="User Ticket"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(USER_TICKET)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(USER_TICKET)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {(!!userUserModalPhoneNumber || !!userUserModalVideoLink) && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li style={{paddingLeft: '1rem', color: '#A5a5a5'}}>User id</li>
                                {!!userUserModalPhoneNumber && (
                                    <li>
                                        <FormControlLabel
                                            label="phone number"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(USER_USER_MODAL_PHONE_NUMBER)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(USER_USER_MODAL_PHONE_NUMBER)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!userUserModalVideoLink && (
                                    <li>
                                        <FormControlLabel
                                            label="video link"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(USER_USER_MODAL_VIDEO_LINK)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(USER_USER_MODAL_VIDEO_LINK)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {!!userMatchDetailsMapName && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li style={{paddingLeft: '1rem', color: '#A5a5a5'}}>Matches</li>
                                {!!userMatchDetailsMapName && (
                                    <li>
                                        <FormControlLabel
                                            label="Map name"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(USER_MATCH_DETAILS_MAP_NAME)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(USER_MATCH_DETAILS_MAP_NAME)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {(!!userEntryFeesPaidVia || !!userEntryFeesInitial) && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li style={{paddingLeft: '1rem', color: '#A5a5a5'}}>Total entry fees</li>
                                {!!userEntryFeesPaidVia && (
                                    <li>
                                        <FormControlLabel
                                            label="Paid via"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(USER_ENTRY_FEES_PAID_VIA)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(USER_ENTRY_FEES_PAID_VIA)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!userEntryFeesInitial && (
                                    <li>
                                        <FormControlLabel
                                            label="Initail"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(USER_ENTRY_FEES_INITIAL)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(USER_ENTRY_FEES_INITIAL)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {(!!userWinningsFcAccount || !!userWinningsTax || !!userWinningsFinalWinning) && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li style={{paddingLeft: '1rem', color: '#A5a5a5'}}>Winnings</li>
                                {!!userWinningsFcAccount && (
                                    <li>
                                        <FormControlLabel
                                            label="Fc account"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(USER_WINNINGS_FC_ACCOUNT)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(USER_WINNINGS_FC_ACCOUNT)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!userWinningsTax && (
                                    <li>
                                        <FormControlLabel
                                            label="Tax"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(USER_WINNINGS_TAX)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(USER_WINNINGS_TAX)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!userWinningsFinalWinning && (
                                    <li>
                                        <FormControlLabel
                                            label="Final winning"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(USER_WINNINGS_FINAL_WINNING)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(USER_WINNINGS_FINAL_WINNING)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {(!!userReadOnly || !!userWrite) && <div className="hrLine"></div>}

                        {(!!userKycReadOnly || !!userKycWrite) && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li>
                                    <h3 className="my-0">User KYC</h3>
                                </li>
                                {!!userKycReadOnly && (
                                    <li>
                                        <FormControlLabel
                                            label="Show User KYC read only"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(USER_KYC_READONLY)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(USER_KYC_READONLY)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!userKycWrite && (
                                    <li>
                                        <FormControlLabel
                                            label="User KYC action permission"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(USER_KYC_WRITE)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(USER_KYC_WRITE, [
                                                            USER_KYC_READONLY,
                                                        ])
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {(!!userKycReadOnly || !!userKycWrite) && <div className="hrLine"></div>}

                        {(!!withdrawalReadOnly || !!withdrawalWrite) && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li>
                                    <h3 className="my-0">Withdrawals</h3>
                                </li>
                                {!!withdrawalReadOnly && (
                                    <li>
                                        <FormControlLabel
                                            label="Show Withdrawals read only"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(WITHDRAWAL_READONLY)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(WITHDRAWAL_READONLY)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!withdrawalWrite && (
                                    <li>
                                        <FormControlLabel
                                            label="Withdrawals action permission"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(WITHDRAWAL_WRITE)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(WITHDRAWAL_WRITE, [
                                                            WITHDRAWAL_READONLY,
                                                        ])
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {(!!withdrawalReadOnly || !!withdrawalWrite) && <div className="hrLine"></div>}

                        {(!!referralReadOnly || !!referralWrite) && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li>
                                    <h3 className="my-0">Referral Codes</h3>
                                </li>
                                {!!referralReadOnly && (
                                    <li>
                                        <FormControlLabel
                                            label="Show Referral Codes read only"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(REFERRAL_READONLY)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(REFERRAL_READONLY)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!referralWrite && (
                                    <li>
                                        <FormControlLabel
                                            label="Referral Codes action permission"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(REFERRAL_WRITE)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(REFERRAL_WRITE, [
                                                            REFERRAL_READONLY,
                                                        ])
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {(!!referralReadOnly || !!referralWrite) && <div className="hrLine"></div>}

                        {(!!discountReadOnly || !!discountWrite) && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li>
                                    <h3 className="my-0">Discount Codes</h3>
                                </li>
                                {!!discountReadOnly && (
                                    <li>
                                        <FormControlLabel
                                            label="Show Discount Codes read only"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(DISCOUNT_READONLY)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(DISCOUNT_READONLY)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!discountWrite && (
                                    <li>
                                        <FormControlLabel
                                            label="Discount Codes action permission"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(DISCOUNT_WRITE)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(DISCOUNT_WRITE, [
                                                            DISCOUNT_READONLY,
                                                        ])
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {(!!discountReadOnly || !!discountWrite) && <div className="hrLine"></div>}

                        {!!notificationWrite && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li>
                                    <h3 className="my-0">Notifications</h3>
                                </li>
                                {!!notificationWrite && (
                                    <li>
                                        <FormControlLabel
                                            label="Notifications action permission"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(NOTIFICATION_WRITE)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(NOTIFICATION_WRITE)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {!!notificationWrite && <div className="hrLine"></div>}

                        {(!!tdsReadOnly || !!tdsWrite || !!tdsAdvanceWrite) && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li>
                                    <h3 className="my-0">TDS Certificate</h3>
                                </li>
                                {!!tdsReadOnly && (
                                    <li>
                                        <FormControlLabel
                                            label="Show TDS Certificate read only"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(TDS_READONLY)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(TDS_READONLY)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!tdsWrite && (
                                    <li>
                                        <FormControlLabel
                                            label="TDS Certificate action permission"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(TDS_WRITE)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(TDS_WRITE, [TDS_READONLY])
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!tdsAdvanceWrite && (
                                    <li>
                                        <FormControlLabel
                                            label="Advance TDS Certificate action permission for after upload"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(TDS_ADVANCE_WRITE)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(TDS_ADVANCE_WRITE, [TDS_READONLY])
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {(!!tdsReadOnly || !!tdsWrite || !!tdsAdvanceWrite) && <div className="hrLine"></div>}

                        {!!rewardUserPermission && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li>
                                    <h3 className="my-0">Reward User</h3>
                                </li>
                                {!!rewardUserPermission && (
                                    <li>
                                        <FormControlLabel
                                            label="Reward User action permission"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(REWARD_USER)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(REWARD_USER)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {!!rewardUserPermission && <div className="hrLine"></div>}

                        {(!!bannerReadOnly || !!bannerWrite) && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li>
                                    <h3 className="my-0">Banners</h3>
                                </li>
                                {!!bannerReadOnly && (
                                    <li>
                                        <FormControlLabel
                                            label="Show Banners read only"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(BANNER_READONLY)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(BANNER_READONLY)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!bannerWrite && (
                                    <li>
                                        <FormControlLabel
                                            label="Banners action permission"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(BANNER_WRITE)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(BANNER_WRITE, [BANNER_READONLY])
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {(!!bannerReadOnly || !!bannerWrite) && <div className="hrLine"></div>}

                        {(!!verifyMatchReadOnly || !!uploadMatchResultPermission || !!verifyMatchWrite) && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li>
                                    <h3 className="my-0">Verify Match</h3>
                                </li>
                                {!!verifyMatchReadOnly && (
                                    <li>
                                        <FormControlLabel
                                            label="Show Verify Match read only"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(VERIFY_MATCH_READONLY)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(VERIFY_MATCH_READONLY)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!uploadMatchResultPermission && (
                                    <li>
                                        <FormControlLabel
                                            label="Upload result and end permissions"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(UPLOAD_MATCH_RESULT)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(UPLOAD_MATCH_RESULT, [
                                                            VERIFY_MATCH_READONLY,
                                                        ])
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!verifyMatchWrite && (
                                    <li>
                                        <FormControlLabel
                                            label="After upload Result permission, like: Forfeited, Complete match"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(VERIFY_MATCH_WRITE)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(VERIFY_MATCH_WRITE, [
                                                            VERIFY_MATCH_READONLY,
                                                        ])
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {!!varifyMatchMapName && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li style={{paddingLeft: '1rem', color: '#A5a5a5'}}>Varify match column</li>
                                {!!varifyMatchMapName && (
                                    <li>
                                        <FormControlLabel
                                            label="Map name"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(VARIFY_MATCH_MAP_NAME)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(VARIFY_MATCH_MAP_NAME)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!verifyMatchMatchType && (
                                    <li>
                                        <FormControlLabel
                                            label="Match Type"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(VERIFY_MATCH_MATCH_TYPE)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(VERIFY_MATCH_MATCH_TYPE)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!verifyMatchCreatedFrom && (
                                    <li>
                                        <FormControlLabel
                                            label="Created Form"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(VERIFY_MATCH_CREATED_FROM)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(VERIFY_MATCH_CREATED_FROM)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {!!verifyMatchUploadResults && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li style={{paddingLeft: '1rem', color: '#A5a5a5'}}>Match id</li>
                                {!!verifyMatchUploadResults && (
                                    <li>
                                        <FormControlLabel
                                            label="Upload result"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(VERIFY_MATCH_UPLOAD_RESULTS)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(VERIFY_MATCH_UPLOAD_RESULTS)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {(!!verifyMatchReadOnly || !!uploadMatchResultPermission || !!verifyMatchWrite) && (
                            <div className="hrLine"></div>
                        )}

                        {(!!playerReadOnly || !!playerWrite) && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li>
                                    <h3 className="my-0">Players</h3>
                                </li>
                                {!!playerReadOnly && (
                                    <li>
                                        <FormControlLabel
                                            label="Show Players read only"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(PLAYER_READONLY)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(PLAYER_READONLY)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!playerWrite && (
                                    <li>
                                        <FormControlLabel
                                            label="Players action permission"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(PLAYER_WRITE)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(PLAYER_WRITE, [PLAYER_READONLY])
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {!!playersFcRating && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li style={{paddingLeft: '1rem', color: '#A5a5a5'}}>Players column</li>
                                {!!playersFcRating && (
                                    <li>
                                        <FormControlLabel
                                            label="Fc rating"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(PLAYERS_FC_RATING)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(PLAYERS_FC_RATING)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {!!editPlayerFcRating && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li style={{paddingLeft: '1rem', color: '#A5a5a5'}}>Player id</li>
                                {!!editPlayerFcRating && (
                                    <li>
                                        <FormControlLabel
                                            label="Fc rating"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(EDIT_PLAYER_FC_RATING)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(EDIT_PLAYER_FC_RATING)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {(!!playerReadOnly || !!playerWrite) && <div className="hrLine"></div>}

                        {(!!squadReadOnly || !!squadWrite) && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li>
                                    <h3 className="my-0">Squads</h3>
                                </li>
                                {!!squadReadOnly && (
                                    <li>
                                        <FormControlLabel
                                            label="Show Squads read only"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(SQUAD_READONLY)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(SQUAD_READONLY)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!squadWrite && (
                                    <li>
                                        <FormControlLabel
                                            label="Squads action permission"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(SQUAD_WRITE)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(SQUAD_WRITE, [SQUAD_READONLY])
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {(!!squadReadOnly || !!squadWrite) && <div className="hrLine"></div>}

                        {!!navbarCalenderDropdown && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li>
                                    <h3 className="my-0">Navbar</h3>
                                </li>
                                {!!navbarCalenderDropdown && (
                                    <li>
                                        <FormControlLabel
                                            label="Calender"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(NAVBAR_CALENDER_DROP_DOWN)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(NAVBAR_CALENDER_DROP_DOWN)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {!!navbarCalenderDropdown && <div className="hrLine"></div>}

                        {(!!matchReadOnly || !!matchWrite || !!matchAdvanceWrite) && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li>
                                    <h3 className="my-0">Matches</h3>
                                </li>
                                {!!matchReadOnly && (
                                    <li>
                                        <FormControlLabel
                                            label="Show Matches read only"
                                            control={
                                                <Checkbox
                                                    checked={true}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(MATCH_READONLY)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!matchWrite && (
                                    <li>
                                        <FormControlLabel
                                            label="Matches action permission"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(MATCH_WRITE)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(MATCH_WRITE, [MATCH_READONLY])
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!matchAdvanceWrite && (
                                    <li>
                                        <FormControlLabel
                                            label="Advance Match actions permission, like: FR, MOTD, Cancel, Reschedule, etc.."
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(MATCH_ADVANCE_WRITE)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(MATCH_ADVANCE_WRITE, [
                                                            MATCH_READONLY,
                                                        ])
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {(!!matchesCreateMatchesButton ||
                            !!matchesMapName ||
                            !!matchesMatchType ||
                            !!matchesCreatedFrom) && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li style={{paddingLeft: '1rem', color: '#A5a5a5'}}>Matches column</li>
                                {!!matchesCreateMatchesButton && (
                                    <li>
                                        <FormControlLabel
                                            label="Create match button"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(MATCHES_CREATE_MATCHES_BUTTON)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(MATCHES_CREATE_MATCHES_BUTTON)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!matchesMapName && (
                                    <li>
                                        <FormControlLabel
                                            label="Map name"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(MATCHES_MAP_NAME)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(MATCHES_MAP_NAME)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!matchesMatchType && (
                                    <li>
                                        <FormControlLabel
                                            label="Match type"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(MATCHES_MATCH_TYPE)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(MATCHES_MATCH_TYPE)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                <li></li>
                                {!!matchesCreatedFrom && (
                                    <li>
                                        <FormControlLabel
                                            label="Created From"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(MATCHES_CREATED_FROM)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(MATCHES_CREATED_FROM)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {(!!addMatchCommonMatchId ||
                            !!addMatchGlacierClassId ||
                            !!addMatchSearchBoosterConfig ||
                            !!addMatchSearchMultiplierConfig ||
                            !!addMatchGlacierId ||
                            !!addMatchSubstitution ||
                            !!addMatchStreamLink ||
                            !!addMatchExpertAnalysis ||
                            !!addMatchMapName ||
                            !!addMatchRankForSorting ||
                            !!addMatchGlacierSeries ||
                            !!addMatchUploadImage ||
                            !!addMatchFantasyType ||
                            !!addMatchEnableViceCaptian) && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li style={{paddingLeft: '1rem', color: '#A5a5a5'}}>Match id - summary tab</li>
                                {!!addMatchCommonMatchId && (
                                    <li>
                                        <FormControlLabel
                                            label="Common match id"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(ADD_MATCH_COMMON_MATCH_ID)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(ADD_MATCH_COMMON_MATCH_ID)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!addMatchGlacierClassId && (
                                    <li>
                                        <FormControlLabel
                                            label="Glacier class id"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(ADD_MATCH_GLACIER_CLASS_ID)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(ADD_MATCH_GLACIER_CLASS_ID)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!addMatchSearchBoosterConfig && (
                                    <li>
                                        <FormControlLabel
                                            label="Search booster config"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(ADD_MATCH_SEARCH_BOOSTER_CONFIG)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(ADD_MATCH_SEARCH_BOOSTER_CONFIG)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                <li></li>
                                {!!addMatchSearchMultiplierConfig && (
                                    <li>
                                        <FormControlLabel
                                            label="Search multiplier config"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(ADD_MATCH_SEARCH_MULTIPLIER_CONFIG)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(ADD_MATCH_SEARCH_MULTIPLIER_CONFIG)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!addMatchGlacierId && (
                                    <li>
                                        <FormControlLabel
                                            label="Glacier Id"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(ADD_MATCH_GLACIER_ID)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(ADD_MATCH_GLACIER_ID)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!addMatchSubstitution && (
                                    <li>
                                        <FormControlLabel
                                            label="Substitution"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(ADD_MATCH_SUBSTITUTION)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(ADD_MATCH_SUBSTITUTION)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                <li></li>
                                {!!addMatchStreamLink && (
                                    <li>
                                        <FormControlLabel
                                            label="Stream link"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(ADD_MATCH_STREAM_LINK)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(ADD_MATCH_STREAM_LINK)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!addMatchExpertAnalysis && (
                                    <li>
                                        <FormControlLabel
                                            label="Expert analysis"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(ADD_MATCH_EXPERT_ANALYSIS)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(ADD_MATCH_EXPERT_ANALYSIS)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!addMatchMapName && (
                                    <li>
                                        <FormControlLabel
                                            label="Map name"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(ADD_MATCH_MAP_NAME)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(ADD_MATCH_MAP_NAME)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                <li></li>
                                {!!addMatchRankForSorting && (
                                    <li>
                                        <FormControlLabel
                                            label="Rank for sorting"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(ADD_MATCH_RANK_FOR_SORTING)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(ADD_MATCH_RANK_FOR_SORTING)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!addMatchGlacierSeries && (
                                    <li>
                                        <FormControlLabel
                                            label="Glacier series"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(ADD_MATCH_GLACIER_SERIES)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(ADD_MATCH_GLACIER_SERIES)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!addMatchUploadImage && (
                                    <li>
                                        <FormControlLabel
                                            label="Upload image"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(ADD_MATCH_UPLOAD_IMAGE)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(ADD_MATCH_UPLOAD_IMAGE)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                <li></li>
                                {!!addMatchFantasyType && (
                                    <li>
                                        <FormControlLabel
                                            label="Fantasy Type"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(ADD_MATCH_FANTASY_TYPE)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(ADD_MATCH_FANTASY_TYPE)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!addMatchEnableViceCaptian && (
                                    <li>
                                        <FormControlLabel
                                            label="Enable Vice Captain"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(ADD_MATCH_ENABLE_VICE_CAPTIAN)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(ADD_MATCH_ENABLE_VICE_CAPTIAN)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {(!!addMatchMakeEasterMatch || !!addMatchMarkComingSoon || !!addMatchMarkDraftToLive) && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li style={{paddingLeft: '1rem', color: '#A5a5a5'}}>Match id - edit match</li>
                                {!!addMatchMakeEasterMatch && (
                                    <li>
                                        <FormControlLabel
                                            label="Make easter match"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(ADD_MATCH_MAKE_EASTER_MATCH)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(ADD_MATCH_MAKE_EASTER_MATCH)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!addMatchMarkComingSoon && (
                                    <li>
                                        <FormControlLabel
                                            label="Mark coming soon"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(ADD_MATCH_MARK_COMING_SOON)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(ADD_MATCH_MARK_COMING_SOON)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!addMatchMarkDraftToLive && (
                                    <li>
                                        <FormControlLabel
                                            label="Mark draft to live"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(ADD_MATCH_MARK_DRAFT_TO_LIVE)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(ADD_MATCH_MARK_DRAFT_TO_LIVE)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {(!!addMatchAddSquadBtn ||
                            !!addMatchAddSquadAndPlayerBtn ||
                            !!addMatchSubstitutePlayer ||
                            !!addMatchAddPlayer) && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li style={{paddingLeft: '1rem', color: '#A5a5a5'}}>Match id - squad and player tab</li>
                                {!!addMatchAddSquadBtn && (
                                    <li>
                                        <FormControlLabel
                                            label="Add & remove squad"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(ADD_MATCH_ADD_SQUAD_BUTTON)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(ADD_MATCH_ADD_SQUAD_BUTTON)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!addMatchAddSquadAndPlayerBtn && (
                                    <li>
                                        <FormControlLabel
                                            label="Add squad and player"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(ADD_MATCH_ADD_SQUAD_AND_PLAYER_BUTTON)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(
                                                            ADD_MATCH_ADD_SQUAD_AND_PLAYER_BUTTON,
                                                        )
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!addMatchSubstitutePlayer && (
                                    <li>
                                        <FormControlLabel
                                            label="Substitute Player"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(ADD_MATCH_SUBSTITUTE_PLAYER)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(ADD_MATCH_SUBSTITUTE_PLAYER)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                <li></li>
                                {!!addMatchAddPlayer && (
                                    <li>
                                        <FormControlLabel
                                            label="Add Player"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(ADD_MATCH_ADD_PLAYER)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(ADD_MATCH_ADD_PLAYER)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {(!!matchReadOnly || !!matchWrite || !!matchAdvanceWrite) && <div className="hrLine"></div>}

                        {(!!fcTournamentReadOnly || !!fcTournamentWrite) && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li>
                                    <h3 className="my-0">Fantasy Tournament</h3>
                                </li>
                                {!!fcTournamentReadOnly && (
                                    <li>
                                        <FormControlLabel
                                            label="Fantasy Tournament read only"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(FC_TO_READONLY)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(FC_TO_READONLY)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!fcTournamentWrite && (
                                    <li>
                                        <FormControlLabel
                                            label="Fantasy Tournament action permission"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(FC_TO_WRITE)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(FC_TO_WRITE, [FC_TO_READONLY])
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {(!!fcTournamentReadOnly || !!fcTournamentWrite) && <div className="hrLine"></div>}

                        {(!!contestReadOnly || !!contestWrite) && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li>
                                    <h3 className="my-0">Contests / Type</h3>
                                </li>
                                {!!contestReadOnly && (
                                    <li>
                                        <FormControlLabel
                                            label="Show Contests read only"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(CONTEST_READONLY)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(CONTEST_READONLY)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!contestWrite && (
                                    <li>
                                        <FormControlLabel
                                            label="Contests action permission"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(CONTEST_WRITE)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(CONTEST_WRITE, [CONTEST_READONLY])
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {(!!contestSlotFillRate ||
                            !!contestDiscountCode ||
                            !!contestUserType ||
                            !!contestAddInfluencer ||
                            !!contestContestSpecificDiscount) && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li style={{paddingLeft: '1rem', color: '#A5a5a5'}}>Contest column</li>
                                {!!contestSlotFillRate && (
                                    <li>
                                        <FormControlLabel
                                            label="Slot fill rate"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(CONTEST_SLOT_FILL_RATE)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(CONTEST_SLOT_FILL_RATE)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!contestDiscountCode && (
                                    <li>
                                        <FormControlLabel
                                            label="Discount code"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(CONTEST_DISCOUNT_CODE)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(CONTEST_DISCOUNT_CODE)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!contestUserType && (
                                    <li>
                                        <FormControlLabel
                                            label="User type"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(CONTEST_USER_TYPE)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(CONTEST_USER_TYPE)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                <li></li>
                                {!!contestAddInfluencer && (
                                    <li>
                                        <FormControlLabel
                                            label="Add influencer"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(CONTEST_ADD_INFLUENCER)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(CONTEST_ADD_INFLUENCER)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!contestContestSpecificDiscount && (
                                    <li>
                                        <FormControlLabel
                                            label="Contest specific discount"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(CONTEST_CONTEST_SPECIFIC_DISCOUNT)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(CONTEST_CONTEST_SPECIFIC_DISCOUNT)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {(!!addContestTypeEntryTypeOptionCashOnly ||
                            !!addContestTypeUserTypeOptionAll ||
                            !!addContestTypeSwitchTypeContest ||
                            !!addContestTypeTotalSwitchAllowed ||
                            !!addContestTypeSwitchFreeTypes) && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li style={{paddingLeft: '1rem', color: '#A5a5a5'}}>Contest type Id</li>
                                {!!addContestTypeEntryTypeOptionCashOnly && (
                                    <li>
                                        <FormControlLabel
                                            label="Type entry option cash only"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(ADD_CONTEST_TYPE_ENTRY_TYPE_CASH_ONLY)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(
                                                            ADD_CONTEST_TYPE_ENTRY_TYPE_CASH_ONLY,
                                                        )
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!addContestTypeUserTypeOptionAll && (
                                    <li>
                                        <FormControlLabel
                                            label="User type option all"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(ADD_CONTEST_TYPE_USER_TYPE_ALL)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(ADD_CONTEST_TYPE_USER_TYPE_ALL)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!addContestTypeSwitchTypeContest && (
                                    <li>
                                        <FormControlLabel
                                            label="Switch type option contest"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(ADD_CONTEST_TYPE_SWITCH_TYPE_CONTEST)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(
                                                            ADD_CONTEST_TYPE_SWITCH_TYPE_CONTEST,
                                                        )
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                <li></li>
                                {!!addContestTypeTotalSwitchAllowed && (
                                    <li>
                                        <FormControlLabel
                                            label="Total switch allowed"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(ADD_CONTEST_TYPE_TOTAL_SWITCH_ALLOWED)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(
                                                            ADD_CONTEST_TYPE_TOTAL_SWITCH_ALLOWED,
                                                        )
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!addContestTypeSwitchFreeTypes && (
                                    <li>
                                        <FormControlLabel
                                            label="Switch free types"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(ADD_CONTEST_TYPE_SWITCH_FREE_TYPES)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(ADD_CONTEST_TYPE_SWITCH_FREE_TYPES)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {(!!contestReadOnly || !!contestWrite) && <div className="hrLine"></div>}

                        {!!teamReadOnly && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li>
                                    <h3 className="my-0">Teams</h3>
                                </li>
                                {!!teamReadOnly && (
                                    <li>
                                        <FormControlLabel
                                            label="Show Teams read only"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(TEAM_READONLY)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(TEAM_READONLY)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {!!teamFantasyPoint && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li style={{paddingLeft: '1rem', color: '#A5a5a5'}}>Team column</li>
                                {!!teamFantasyPoint && (
                                    <li>
                                        <FormControlLabel
                                            label="Fantasy point"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(TEAM_FANTASY_POINT)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(TEAM_FANTASY_POINT)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {!!teamReadOnly && <div className="hrLine"></div>}

                        {(!!fantasyScoringReadOnly || !!fantasyScoringWrite) && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li>
                                    <h3 className="my-0">Fantasy scoring</h3>
                                </li>
                                {!!fantasyScoringReadOnly && (
                                    <li>
                                        <FormControlLabel
                                            label="Show Fantasy scoring read only"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(FANTASY_SCORING_READONLY)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(FANTASY_SCORING_READONLY)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!fantasyScoringWrite && (
                                    <li>
                                        <FormControlLabel
                                            label="Fantasy scoring action permission"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(FANTASY_SCORING_WRITE)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(FANTASY_SCORING_WRITE, [
                                                            FANTASY_SCORING_READONLY,
                                                        ])
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {(!!fantasyScoringReadOnly || !!fantasyScoringWrite) && <div className="hrLine"></div>}

                        {(!!boosterReadOnly || !!boosterWrite) && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li>
                                    <h3 className="my-0">Booster Config</h3>
                                </li>
                                {!!boosterReadOnly && (
                                    <li>
                                        <FormControlLabel
                                            label="Show Booster Config read only"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(BOOSTER_READONLY)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(BOOSTER_READONLY)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!boosterWrite && (
                                    <li>
                                        <FormControlLabel
                                            label="Booster Config action permission"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(BOOSTER_WRITE)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(BOOSTER_WRITE, [BOOSTER_READONLY])
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {(!!boosterReadOnly || !!boosterWrite) && <div className="hrLine"></div>}

                        {(!!dynamicPrizePoolReadOnly || !!dynamicPrizePoolWrite) && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li>
                                    <h3 className="my-0">Dynamic Prize Pool</h3>
                                </li>
                                {!!dynamicPrizePoolReadOnly && (
                                    <li>
                                        <FormControlLabel
                                            label="Show Dynamic Prize Pool read only"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(DYNAMIC_PRIZEPOOL_READONLY)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(DYNAMIC_PRIZEPOOL_READONLY)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!dynamicPrizePoolWrite && (
                                    <li>
                                        <FormControlLabel
                                            label="Dynamic Prize Pool action permission"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(DYNAMIC_PRIZEPOOL_WRITE)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(DYNAMIC_PRIZEPOOL_WRITE, [
                                                            DYNAMIC_PRIZEPOOL_READONLY,
                                                        ])
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {(!!dynamicPrizePoolReadOnly || !!dynamicPrizePoolWrite) && <div className="hrLine"></div>}

                        {!!articleWrite && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li>
                                    <h3 className="my-0">Inshorts</h3>
                                </li>
                                {!!articleWrite && (
                                    <li>
                                        <FormControlLabel
                                            label="Articles action permission"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(ARTICLE_WRITE)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(ARTICLE_WRITE)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {!!articleWrite && <div className="hrLine"></div>}

                        {(!!toGameReadOnly || !!toGameWrite) && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li>
                                    <h3 className="my-0">Tournament Games</h3>
                                </li>
                                {!!toGameReadOnly && (
                                    <li>
                                        <FormControlLabel
                                            label="Show Tournament Games read only"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(TOGAME_READONLY)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(TOGAME_READONLY)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!toGameWrite && (
                                    <li>
                                        <FormControlLabel
                                            label="Tournament Games action permission"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(TOGAME_WRITE)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(TOGAME_WRITE, [TOGAME_READONLY])
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {(!!toGameReadOnly || !!toGameWrite) && <div className="hrLine"></div>}

                        {(!!toMatchModeReadOnly || !!toMatchModeWrite) && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li>
                                    <h3 className="my-0">Tournament Match Mode</h3>
                                </li>
                                {!!toMatchModeReadOnly && (
                                    <li>
                                        <FormControlLabel
                                            label="Show Tournament Match Mode read only"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(TOMATCH_MODE_READONLY)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(TOMATCH_MODE_READONLY)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!toMatchModeWrite && (
                                    <li>
                                        <FormControlLabel
                                            label="Tournament Match Mode action permission"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(TOMATCH_MODE_WRITE)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(TOMATCH_MODE_WRITE, [
                                                            TOMATCH_MODE_READONLY,
                                                        ])
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {(!!toMatchModeReadOnly || !!toMatchModeWrite) && <div className="hrLine"></div>}

                        {(!!toMatchReadOnly || !!toMatchWrite) && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li>
                                    <h3 className="my-0">Tournament Match</h3>
                                </li>
                                {!!toMatchReadOnly && (
                                    <li>
                                        <FormControlLabel
                                            label="Show Tournament Match read only"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(TO_MATCH_READONLY)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(TO_MATCH_READONLY)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!toMatchWrite && (
                                    <li>
                                        <FormControlLabel
                                            label="Tournament Match action permission"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(TO_MATCH_WRITE)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(TO_MATCH_WRITE, [
                                                            TO_MATCH_READONLY,
                                                        ])
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {(!!toMatchReadOnly || !!toMatchWrite) && <div className="hrLine"></div>}

                        {(!!toOrganizerReadOnly || !!toOrganizerWrite) && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li>
                                    <h3 className="my-0">Tournament Organizers</h3>
                                </li>
                                {!!toOrganizerReadOnly && (
                                    <li>
                                        <FormControlLabel
                                            label="Show Tournament Organizers read only"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(TOORGANIZER_READONLY)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(TOORGANIZER_READONLY)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!toOrganizerWrite && (
                                    <li>
                                        <FormControlLabel
                                            label="Tournament Organizers action permission"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(TOORGANIZER_WRITE)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(TOORGANIZER_WRITE, [
                                                            TOORGANIZER_READONLY,
                                                        ])
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {(!!toOrganizerReadOnly || !!toOrganizerWrite) && <div className="hrLine"></div>}

                        {!!commonMatchesReadOnly && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li>
                                    <h3 className="my-0">Common Matches</h3>
                                </li>
                                {!!commonMatchesReadOnly && (
                                    <li>
                                        <FormControlLabel
                                            label="common matches read only"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(COMMON_MATCHES_READ_ONLY)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(COMMON_MATCHES_READ_ONLY)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {!!commonMatchesReadOnly && <div className="hrLine"></div>}

                        {!!switchConfigReadOnly && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li>
                                    <h3 className="my-0">Switch Config</h3>
                                </li>
                                {!!switchConfigReadOnly && (
                                    <li>
                                        <FormControlLabel
                                            label="Switch config read only"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(SWITCH_CONFIG_READ_ONLY)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(SWITCH_CONFIG_READ_ONLY)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {/* {!!switchConfigWrite && (
                                    <li>
                                        <FormControlLabel
                                            label="Switch config action permission"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(SWITCH_CONFIG_WRITE)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(SWITCH_CONFIG_WRITE)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )} */}
                            </ul>
                        )}
                        {!!switchConfigReadOnly && <div className="hrLine"></div>}

                        {!!otherUploadImageReadOnly && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li>
                                    <h3 className="my-0">Other - Upload Image</h3>
                                </li>
                                {!!otherUploadImageReadOnly && (
                                    <li>
                                        <FormControlLabel
                                            label="show upload image"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(OTHER_UPLOAD_IMAGE_READ_ONLY)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(OTHER_UPLOAD_IMAGE_READ_ONLY)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                                {!!otherSkeletonReadOnly && (
                                    <li>
                                        <FormControlLabel
                                            label="show skeleton"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(OTHER_SKELETON_READ_ONLY)}
                                                    color="primary"
                                                    onChange={(e) =>
                                                        this.handleChangePermissions(OTHER_SKELETON_READ_ONLY)
                                                    }
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {!!otherUploadImageReadOnly && <div className="hrLine"></div>}

                        {!!upcomingMatches && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li>
                                    <h3 className="my-0">Upcoming matches</h3>
                                </li>
                                {!!upcomingMatches && (
                                    <li>
                                        <FormControlLabel
                                            label="show upcoming matches"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(UPCOMING_MATCHES)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(UPCOMING_MATCHES)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                        {!!upcomingMatches && <div className="hrLine"></div>}

                        {!!backfill && (
                            <ul className="my-0 list-style-none grid-4-container">
                                <li>
                                    <h3 className="my-0">Other Permissions</h3>
                                </li>
                                {!!backfill && (
                                    <li>
                                        <FormControlLabel
                                            label="BackFill API"
                                            control={
                                                <Checkbox
                                                    checked={accessLevel.has(BACKFILL)}
                                                    color="primary"
                                                    onChange={(e) => this.handleChangePermissions(BACKFILL)}
                                                />
                                            }
                                        />
                                    </li>
                                )}
                            </ul>
                        )}
                    </Card>
                </div>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        admin: state.admin,
        client: state.client,
    };
};

export default connect(mapStateToProps, {addAdmin, fetchAdminUser, updateAdminUser, logout})(AddDiscountCode);
