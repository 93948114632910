import {env} from '../env';

//test server
// export const baseUrl = "http://35.154.250.135:1337";

// client Url
export const baseUrl = env.REACT_APP_API_HOST;
export const GLACIER_URL = env.REACT_APP_GLACIER_API;
export const GLACIER_AUTH = env.REACT_APP_GLACIER_AUTH;
export const GOOGLE_CLIENT_ID = env.REACT_APP_GOOGLE_CLIENT_ID;

export const URL = baseUrl + '/admin';
export const appUrl = baseUrl + '/app';

export const TOURL = baseUrl + '/admin/tournament';
export const TOappUrl = baseUrl + '/tournament';

export const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
export const EXCEL_EXTENSION = '.xlsx';

export const PHONE_PATTERN = '[6-9][0-9]{9}';
export const URL_PATTERN = 'https?://.+';
export const NUMBER_PATTERN = '[0-9]*';

export const YELLOW = '#FFC954';

export const BLACK = '#000000';
export const WHITE = '#FFFFFF';
export const GREY = '#30374b';
export const NAVBAR_GREY = '#1F2435';
export const TEXT_GREY = '#AEB1BB';
export const BORDER_GREY = '#5F6477';
export const RED = '#FA8A85';

export const ENTER = 'ENTER';
export const HEADER_SOURCE = 'CC';
export const NEXT = 'NEXT';

export const CONFIRMATION = 'CONFIRMATION';
export const CONFIRMED = 'CONFIRMED';
export const ADD = 'ADD';
export const REMOVE = 'REMOVE';

export const REQUEST_METHODS = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
};

export const ROLES = {
    SUPER_ADMIN: 'SUPER_ADMIN',
    ADMIN: 'ADMIN',
    ORGANIZER: 'ORGANIZER',
};

export const NAVBAR_TABS = {
    ADMIN_USER: 'ADMIN_USER',
    USER: 'USER',
    FANTASY: 'FANTASY',
    INSHORTS: 'INSHORTS',
    TOURNAMENT: 'TOURNAMENT',
    TRANSACTION: 'TRANSACTION',
    CALENDAR: 'CALENDAR',
    OTHER: 'OTHER',
};

export const TIME_ZONE_OPTIONS = [
    {value: 'Asia/Kolkata', text: '(GMT+5:30) Asia/Kolkata'},
    {value: 'Asia/Bangkok', text: '(GMT+7) Asia/Bangkok'},
    {value: 'America/Nome', text: '(GMT-8) America/Nome'},
];

export const MATCH_STATUS = [
    {value: 'DRAFT', text: 'DRAFT'},
    {value: 'UPCOMING', text: 'UPCOMING'},
    {value: 'LOCKED', text: 'LOCKED'},
    {value: 'LIVE NOW', text: 'LIVE NOW'},
    {value: 'RESULTS_AWAITED', text: 'RESULTS_AWAITED'},
    {value: 'ENDED', text: 'ENDED'},
    {value: 'COMPLETED', text: 'COMPLETED'},
    {value: 'COMING_SOON', text: 'COMING_SOON'},
    {value: 'CANCELLED', text: 'CANCELLED'},
    {value: 'FORFEITED', text: 'FORFEITED'},
];

export const MATCH_TYPES_OPTIONS = [
    {value: 'PUBLIC', text: 'PUBLIC'},
    {value: 'LINKONLY', text: 'LINKONLY'},
];

export const MATCH_STATUS_CONST = {
    DRAFT: 'DRAFT',
    UPCOMING: 'UPCOMING',
    LOCKED: 'LOCKED',
    LIVE_NOW: 'LIVE NOW',
    ENDING: 'ENDING',
    ENDED: 'ENDED',
    WINNING_INITIATED: 'WINNING_INITIATED',
    COMPLETED: 'COMPLETED',
    COMING_SOON: 'COMING_SOON',
    CANCELLED: 'CANCELLED',
    FORFEITED: 'FORFEITED',
};

export const MATCH_TYPE_CONST = {
    PUBLIC: 'PUBLIC',
    LINKONLY: 'LINKONLY',
};

export const TOURNAMENT_CONST = {
    DRAFT: 'DRAFT',
    UPCOMING: 'UPCOMING',
    LIVE: 'LIVE',
    COMPLETED: 'COMPLETED',
};

export const APP_USER_TYPE = {
    ESTAR: 'ESTAR',
};
export const COMMON_STATUS = [
    {value: 'ACTIVE', text: 'ACTIVE'},
    {value: 'INACTIVE', text: 'INACTIVE'},
];

export const COMMON_STATUS_CONST = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
};

export const BANNER_TYPE_OPTIONS = [
    {value: 'estar', text: 'Estar'},
    {value: 'discord', text: 'Discord'},
    {value: 'squadFantasy', text: 'Squad Fantasy'},
    {value: 'dailyStreak', text: 'Daily Streak'},
    {value: 'apk', text: 'APK'},
    {value: 'inviteAndEarn', text: 'Invite & Earn'},
    {value: 'custom', text: 'Custom'},
];

export const BANNER_TARGET_AUDIENCE_OPTIONS = [
    {value: 'ALL', text: 'For both Google APK and FC APK users'},
    {value: 'G_APK_USER', text: 'For Google APK users'},
    {value: 'FC_APK_USER', text: 'For FC APK users'},
];

export const BANNER_TYPE = {
    estar: 'estar',
    discord: 'discord',
    squadFantasy: 'squadFantasy',
    dailyStreak: 'dailyStreak',
    apk: 'apk',
    inviteAndEarn: 'inviteAndEarn',
    custom: 'custom',
};

export const BANNER_SIZE_OPTIONS = [
    {value: '1125x216', text: '1125x216'},
    {value: '1125x456', text: '1125x456'},
];

export const KYC_STATUS_OPTIONS_FANCLASH = [
    {value: 'PROCESSING', text: 'PROCESSING'},
    {value: 'SUBMITTED', text: 'SUBMITTED'},
    {value: 'VERIFIED', text: 'VERIFIED'},
    {value: 'REJECTED', text: 'REJECTED'},
];

export const KYC_STATUS_OPTIONS_JADE = [
    {value: 'SUBMITTED', text: 'SUBMITTED'},
    {value: 'VERIFIED', text: 'VERIFIED'},
    {value: 'REJECTED', text: 'REJECTED'},
];

export const KYC_STATUS = {
    PROCESSING: 'PROCESSING',
    SUBMITTED: 'SUBMITTED',
    VERIFIED: 'VERIFIED',
    REJECTED: 'REJECTED',
    REPROCESS: 'REPROCESS',
    PROCESS_AGAIN: 'PROCESS_AGAIN',
};

export const KYC_REJECTION_OPTIONS = [
    {value: 'Image is blur/unclear', text: 'Image is blur/unclear'},
    {value: 'PAN name is different from NSDL name', text: 'PAN name is different from NSDL name'},
    {value: 'PAN name not matching with UPI Name', text: 'PAN name not matching with UPI Name'},
    {
        value: 'Partial image is uploaded (Half a photo is uploaded, back side of PAN is uploaded)',
        text: 'Partial image is uploaded (Half a photo is uploaded, back side of PAN is uploaded)',
    },
    {
        value: 'Tampered image is uploaded (Tampered image includes putting a hand over the PAN, any sort of writing or tampering with Name, Father’s Name, DOB, Signature, Photo or Logo)',
        text: 'Tampered image is uploaded (Tampered image includes putting a hand over the PAN, any sort of writing or tampering with Name, Father’s Name, DOB, Signature, Photo or Logo)',
    },
];

export const KYC_BANK_OPTIONS = [
    {
        value: 'Account name does not match with the name on the PAN',
        text: 'Account name does not match with the name on the PAN',
    },
];

export const KYC_UPI_OPTIONS = [
    {value: 'UPI id does not match with the name on the PAN', text: 'UPI id does not match with the name on the PAN'},
];

export const REFERRAL_CAMPAIGNS = {
    CAMPAIGN_CATEGORY: {
        INFLUENCER: 'INFLUENCER',
        AFFILIATES: 'AFFILIATES',
        ADS: 'ADS',
        P2P: 'P2P',
        OTHERS: 'OTHERS',
    },
    INFLUENCER: {
        VOD: 'VOD',
        DEDICATED_VIDEO: 'DEDICATED VIDEO',
        STREAMS: 'STREAMS',
        UDT: 'UDT',
    },
    AFFILIATES: {
        MICRO_INF: 'MICRO_INF',
        PERF_INF: 'PERF_INF',
    },
    ADS: {
        FBADS: 'FBADS',
        GADS: 'GADS',
    },
    BUDGET: {
        FIXED: 'FIXED',
        VARIABLE: 'VARIABLE',
    },
    STATUS: {
        ACTIVE: 'ACTIVE',
        INACTIVE: 'INACTIVE',
        BLOCKED: 'BLOCKED',
    },
};

export const REFERRAL_CAMPAIGN_OPTIONS = {
    CAMPAIGN_CATEGORY: [
        {value: 'INFLUENCER', text: 'INFLUENCER'},
        {value: 'AFFILIATES', text: 'AFFILIATES'},
        {value: 'ADS', text: 'ADS'},
        {value: 'P2P', text: 'P2P'},
        {value: 'OTHERS', text: 'OTHERS'},
    ],
    INFLUENCER: [
        {value: 'VOD', text: 'VOD'},
        {value: 'DEDICATED VIDEO', text: 'DEDICATED VIDEO'},
        {value: 'STREAMS', text: 'STREAMS'},
        {value: 'UDT', text: 'UDT'},
    ],
    AFFILIATES: [
        {value: 'MICRO_INF', text: 'MICRO_INF'},
        {value: 'PERF_INF', text: 'PERF_INF'},
    ],
    ADS: [
        {value: 'FBADS', text: 'FBADS'},
        {value: 'GADS', text: 'GADS'},
    ],
    BUDGET: [
        {value: 'FIXED', text: 'FIXED'},
        {value: 'VARIABLE', text: 'VARIABLE'},
    ],
    STATUS: [
        {value: 'ACTIVE', text: 'ACTIVE'},
        {value: 'INACTIVE', text: 'INACTIVE'},
        {value: 'BLOCKED', text: 'BLOCKED'},
    ],
};

export const PLATFORMS = {
    ANDROID: 'ANDROID',
    IOS: 'IOS',
    PWA: 'PWA',
};

export const CONTEST_STATUS = [
    {value: 'AVAILABLE', text: 'AVAILABLE'},
    {value: 'LOCKED', text: 'LOCKED'},
    {value: 'LIVE NOW', text: 'LIVE NOW'},
    {value: 'CONFIRMED', text: 'CONFIRMED'},
    {value: 'COMPLETED', text: 'COMPLETED'},
    {value: 'CANCELLED', text: 'CANCELLED'},
];

export const PRIZE_POOL_TYPE_OPTIONS = [
    {value: 'FIXED', text: 'FIXED'},
    {value: 'DYNAMIC', text: 'DYNAMIC'},
];

export const PRIZE_POOL_TYPE = {
    FIXED: 'FIXED',
    DYNAMIC: 'DYNAMIC',
};

export const KYC_ACTON_ON = {
    BANK_ACCOUNT: 'BANK_ACCOUNT',
    VPA: 'VPA',
};

export const DISCOUNT_APPLICABILITY_TYPE = {
    ALL: 'ALL',
    CONTEST_SPECIFIC: 'CONTEST_SPECIFIC',
};

export const DISCOUNT_APPLICABILITY_OPTIONS = [
    {value: 'ALL', text: 'ALL'},
    {value: 'CONTEST_SPECIFIC', text: 'CONTEST_SPECIFIC'},
];

export const BOOSTER_PENALTY_REWARD_OPTIONS = [
    // {value: "MULTIPLY", text: "MULTIPLY"},
    {value: 'ABSOLUTE', text: 'ABSOLUTE'},
];

export const BOOSTER_CONDITIONS_OPTIONS = [
    {value: 'max', text: 'max'},
    {value: 'min', text: 'min'},
    {value: 'gt', text: 'gt'},
    {value: 'eq', text: 'eq'},
    {value: 'gte', text: 'gte'},
    {value: 'lt', text: 'lt'},
    {value: 'lte', text: 'lte'},
];

export const TDS_CONSTANTS = {
    STATUS: {
        UPLOADED: 'UPLOADED',
        VERIFIED: 'VERIFIED',
        REJECTED: 'REJECTED',
        NOT_UPLOADED: 'NOT_UPLOADED',
    },
    STATUS_OPTIONS: [
        {value: 'UPLOADED', text: 'UPLOADED'},
        {value: 'VERIFIED', text: 'VERIFIED'},
        {value: 'REJECTED', text: 'REJECTED'},
        {value: 'NOT_UPLOADED', text: 'NOT_UPLOADED'},
    ],
    QUARTER_OPTIONS: [
        {value: 'Q1', text: 'Q1'},
        {value: 'Q2', text: 'Q2'},
        {value: 'Q3', text: 'Q3'},
        {value: 'Q4', text: 'Q4'},
    ],
};

export const SCREEN_NAMES = {
    DISCOUNT_CODE: 'DISCOUNT_CODE',
    MATCHES: 'MATCHES',
    COMMON_MATCHES: 'COMMON_MATCHES',
    VERIFY_MATCHES: 'VERIFY_MATCHES',
    CONTESTS: 'CONTESTS',
    CONTESTS_TYPE: 'CONTESTS_TYPE',
    USERS: 'USERS',
    REFERRAL_CODES: 'REFERRAL_CODES',
    ADMINS: 'ADMINS',
};

export const MATCH_SUBSTITUTES_OPTIONS = [{value: 'AUTO', text: 'AUTO'}];

export const MATCH_PLAYER_AFTER_LINEUP_ANNOUNCED_OPTIONS = [
    {value: 'PLAYING', text: 'Playing'},
    {value: 'NOT_PLAYING', text: 'Not Playing'},
    {value: 'SUBSTITUTE', text: 'Substitute'},
    {value: 'PLAYER_ON_BENCH', text: 'Player on Bench'},
];

export const MATCH_PLAYER_BEFORE_LINEUP_ANNOUNCED_OPTIONS = [
    {value: 'UNAVAILABLE', text: 'Unavailable'},
    {value: 'PLAYED_LAST_MATCH', text: 'Played Last Match'},
    {value: 'NO_INFO', text: 'No Info'},
];

export const USER_TYPES_OPTIONS = [
    {value: 'BLACKLISTED', text: 'BLACKLISTED'},
    {value: 'WHITELISTED', text: 'WHITELISTED'},
    {value: 'ALL', text: 'ALL'},
];

export const GAME_UNIQUE_CODE = {
    brawlStars: 'brawlStars',
    pubgpc: 'pubgpc',
    lol: 'lol',
    rainbow6: 'rainbow6',
    dota: 'dota',
    csgo: 'csgo',
    pubgNS: 'pubg-NS',
    codpc: 'codpc',
    codm: 'codm',
    pubgm: 'pubgm',
    ff: 'ff',
    bgmi: 'bgmi',
    val: 'val',
    pokemonUnite: 'pokemonUnite',
    cricket: 'wwc',
};

export const TOURNAMENT_TIERS = [
    {text: 'Tier 1', value: 1},
    {text: 'Tier 2', value: 2},
    {text: 'Tier 3', value: 3},
    {text: 'Tier 4', value: 4},
    {text: 'Tier 5', value: 5},
    {text: 'Tier 6', value: 6},
    {text: 'Tier 7', value: 7},
    {text: 'Tier 8', value: 8},
    {text: 'Tier 9', value: 9},
    {text: 'Tier 10', value: 10},
];

export const TOURNAMENT_STATUS_OP = [
    {text: 'LIVE', value: 'LIVE'},
    {text: 'UPCOMING', value: 'UPCOMING'},
    {text: 'COMPLETED', value: 'COMPLETED'},
];

export const SERIES_LIFECYCLE_OP = [
    {text: 'UNKNOWN', value: 'unknown'},
    {text: 'UPCOMING', value: 'upcoming'},
    {text: 'LIVE', value: 'live'},
    {text: 'OVER', value: 'over'},
    {text: 'OVER_DRAW', value: 'over-draw'},
    {text: 'OVER_FORFEITED', value: 'over-forfeited'},
    {text: 'POSTPONED', value: 'postponed'},
    {text: 'DELETED', value: 'deleted'},
];

export const SORT_ORDER_OP = [
    {value: 'start/asc', text: 'Start-ASC'},
    {value: 'start/desc', text: 'Start-DESC'},
];

export const MATCH_INNING_OPTIONS = [
    {value: 1, label: '1'},
    {value: 2, label: '2'},
];

export const MATCH_BALL_OPTIONS = [
    {value: 1, label: '1'},
    {value: 2, label: '2'},
    {value: 3, label: '3'},
    {value: 4, label: '4'},
    {value: 5, label: '5'},
    {value: 6, label: '6'},
];

export const MATCH_OVER_OPTIONS = [
    {value: 0, label: '0'},
    {value: 1, label: '1'},
    {value: 2, label: '2'},
    {value: 3, label: '3'},
    {value: 4, label: '4'},
    {value: 5, label: '5'},
    {value: 6, label: '6'},
    {value: 7, label: '7'},
    {value: 8, label: '8'},
    {value: 9, label: '9'},
    {value: 10, label: '10'},
    {value: 11, label: '11'},
    {value: 12, label: '12'},
    {value: 13, label: '13'},
    {value: 14, label: '14'},
    {value: 15, label: '15'},
    {value: 16, label: '16'},
    {value: 17, label: '17'},
    {value: 18, label: '18'},
    {value: 19, label: '19'},
];

export const SWITCH_STATUS_OPTIONS = [
    {value: 'freeze', label: 'FREEZE'},
    {value: 'unfreeze', label: 'UNFREEZE'},
];

export const FANTASY_TYPES_OPTIONS = [
    {value: 'NORMAL', text: 'NORMAL'},
    {value: 'SWITCH', text: 'SWITCH'},
];

export const MAX_SUBSTITUION_ALLOWED_OPTIONS = [
    {value: 1, label: 'ONE'},
    {value: 2, label: 'TWO'},
    {value: 3, label: 'THREE'},
    {value: 4, label: 'FOUR'},
    {value: 5, label: 'FIVE'},
    {value: 6, label: 'SIX'},
    {value: 7, label: 'SEVEN'},
    {value: 8, label: 'EIGHT'},
    {value: 9, label: 'NINE'},
    {value: 10, label: 'TEN'},
];

export const SUBSTITUTION_FEE_TYPES_OPTIONS = [{value: 'CONTEST_LEVEL', text: 'CONTEST LEVEL'}];

export const SUBSTITUTION_FEE_OPTIONS = [
    {value: 0, text: 'FREE'},
    {value: 5, text: 'FIVE'},
    {value: 10, text: 'TEN'},
    {value: 15, text: 'FIFTEEN'},
    {value: 20, text: 'TWENTY'},
];

export const CRICKET_FORMAT_OPTIONS = [
    {value: 'oneday', text: 'ONE DAY'},
    {value: 't20', text: 'T 20'},
];

export const LINEUP_STATUS_OPTIONS = [
    { value: 'normal', text: 'normal' },
    { value: 'multi match', text: 'multi match' },
    { value: 'NO_INFO', text: 'NO_INFO' },
]

export const FANTASY_TYPES = {
    SWITCH: 'SWITCH',
    NORMAL: 'NORMAL',
}

export const TRANSACTION_TYPE_OPTIONS = [
    {value: 'WINNING', text: 'WINNING'},
    {value: 'PURCHASE', text: 'PURCHASE'},
    {value: 'REFUND', text: 'REFUND'},
];

export const TICKET_STATUS =[
    {value: 'FAILED', text: 'FAILED'},
    {value: 'SETTLED', text: 'SETTLED'},
    {value: 'PENDING', text: 'PENDING'},
];

export const TICKET_STATUS_DATE_WISE =[
    {value: 'FAILED', text: 'FAILED'},
    {value: 'SETTLED', text: 'SETTLED'},
    {value: 'ALL', text: 'ALL'},
];

export const TICKET_CONTEST_STATUS =[
    {value: 'CANCELLED', text: 'CANCELLED'},
    {value: 'COMPLETED', text: 'COMPLETED'},
]

export const TRANSACTION_STATUS_OPTIONS = [
    { value: 'INITIATED', text: 'INITIATED' },
    { value: 'CAPTURED', text: 'CAPTURED' },
    { value: 'FAILED', text: 'FAILED' },
    { value: 'CANCELLED', text: 'CANCELLED' },
    { value: 'REFUNDED', text: 'REFUNDED' },
    { value: 'COMPLETED', text: 'COMPLETED' },
];
export const CLASSIC_ALLOWED_PERMISSIONS =[
    "USER_READONLY",
    "VERIFY_MATCH_READONLY",
    "PLAYER_READONLY",
    "SQUAD_READONLY",
    "MATCH_READONLY",
    "CONTEST_READONLY",
    "TEAM_READONLY",
    "DYNAMIC_PRIZEPOOL_READONLY",

    "USER_WRITE",
    "UPLOAD_MATCH_RESULT",
    "MATCH_WRITE",
    "CONTEST_WRITE",
    'DYNAMIC_PRIZEPOOL_WRITE',

    "USER_TRANSACTION",
    "VERIFY_MATCH_WRITE",
    "VERIFY_MATCH_UPLOAD_RESULTS",
    "MATCH_ADVANCE_WRITE",
    "ADD_CONTEST_TYPE_ENTRY_TYPE_CASH_ONLY",
    "USER_TICKET",
    "ADD_CONTEST_TYPE_USER_TYPE_ALL",
    "ADD_MATCH_ENABLE_VICE_CAPTIAN",
    "ADD_MATCH_ENABLE_CAPTAIN"
]